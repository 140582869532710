import React, { Component } from 'react'
import StyledDoneModal from '../../../styledComponents/sections/home/doneModal'

class MapNodeModal extends Component {
  onHandleClose = (e) => {
    this.props.toggleModal(false, null)
  }

  render () {
    const { language, visible, doneAllFlags } = this.props

    return (
      (visible)
        ? (
          <StyledDoneModal
            language={language}
            visible={visible}
            handleClose={this.onHandleClose}
            doneAllFlags={doneAllFlags}
          />
          )
        : ''
    )
  }
}

export default MapNodeModal
