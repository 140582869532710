import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Card } from 'antd'

import { messageTypes } from '../../../actions/messages'

class Settings extends Component {
  render () {
    const { language } = this.props

    return (
      <Row className='setting-cards' gutter={8}>
        <Col md={12} sm={24}>
          <Link to='/settings/users'>
            <Card title={messageTypes[language].menu_users} bordered />
          </Link>
        </Col>
        <Col md={12} sm={24}>
          <Link to='/settings/sessions'>
            <Card title={messageTypes[language].menu_sessions} bordered />
          </Link>
        </Col>
        <Col md={12} sm={24}>
          <Link to='/settings/stacks'>
            <Card title={messageTypes[language].menu_stacks} bordered />
          </Link>
        </Col>
        <Col md={12} sm={24}>
          <Link to='/settings/scenarios'>
            <Card title={messageTypes[language].menu_scenarios} bordered />
          </Link>
        </Col>
        <Col md={12} sm={24}>
          <Link to='/settings/machines'>
            <Card title={messageTypes[language].menu_machines} bordered />
          </Link>
        </Col>
        <Col md={12} sm={24}>
          <Link to='/settings/tests'>
            <Card title={messageTypes[language].menu_quizzes} bordered />
          </Link>
        </Col>
        <Col md={12} sm={24}>
          <Link to='/settings/logs'>
            <Card title={messageTypes[language].menu_logs} bordered />
          </Link>
        </Col>
        <Col md={12} sm={24}>
          <Link to='/settings/vpns'>
            <Card title={messageTypes[language].menu_vpns} bordered />
          </Link>
        </Col>
        <Col md={12} sm={24}>
          <Link to='/settings/vpn_descriptions'>
            <Card title={messageTypes[language].menu_vpn_descriptions} bordered />
          </Link>
        </Col>
        <Col md={12} sm={24}>
          <Link to='/settings/map_objects'>
            <Card title={messageTypes[language].menu_map_objects} bordered />
          </Link>
        </Col>
        <Col md={12} sm={24}>
          <Link to='/settings/stack_descriptions'>
            <Card title={messageTypes[language].menu_stack_descriptions} bordered />
          </Link>
        </Col>
        <Col md={12} sm={24}>
          <Link to='/settings/maps'>
            <Card title={messageTypes[language].menu_maps} bordered />
          </Link>
        </Col>
      </Row>
    )
  }
}

export default Settings
