import React, { Component } from 'react'
import { Radio, Form } from 'antd'
import { messageTypes } from '../../../../actions/messages'
const FormItem = Form.Item

class StyledFilterButtons extends Component {
  render () {
    const { language, verbosity, verbosityLvls, handleVerbosity } = this.props
    return (
      <div className='filter-buttons'>
        <Form layout='inline'>
          <FormItem label={messageTypes[language].verbosity_level}>
            <Radio.Group size='default' value={verbosity} onChange={handleVerbosity}>
              {
                verbosityLvls.map((val, i) => <Radio.Button key={i} value={val}>{val}</Radio.Button>)
              }
            </Radio.Group>
          </FormItem>
        </Form>
      </div>
    )
  }
}

export default StyledFilterButtons
