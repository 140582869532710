import React, { Component } from 'react'
import { connect } from 'react-redux'
import Body from './body'
import Footer from './footer'
import Header from './header'
import { Layout } from 'antd'
import { setLanguage, setUiPathname } from '../../store/actions'
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";

class main extends Component {
  componentDidMount () {
    this.props.setUiPathname(window.location.pathname)
  }

  componentDidUpdate () {
    this.props.setUiPathname(window.location.pathname)
  }

  render () {
    const { language, children, uiPathname } = this.props

    return (
      <Layout>
        <Header
          reduxLanguage={language}
        />
        {language ? <Body uiPathname={uiPathname}>{children}</Body> : ''}
        <Footer />
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language,
  uiPathname: state.hdStore.uiPathname
})

const mapDispatchToProps = {
  setLanguage,
  setUiPathname
}

const MainContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(main)

export default MainContainer
