import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { Form, Input, Button } from 'antd'
import { messageTypes } from '../../../actions/messages'
import { resetPasswordCheckToken, resetPasswordSetPassword } from '../../../actions/'
import { openNotificationPopup, getCookie } from '../../../actions/helpers'
import { LockOutlined } from '@ant-design/icons'

class ResetPasswordSetPasswordForm extends React.Component {
  state = {
    redirect: false,
    tokenValid: null
  }

  checkToken = (token) => {
    const { language } = this.props

    resetPasswordCheckToken(token, getCookie('_token')).then((response) => {
      const json = response

      if (json.status === 'ok') {
        this.setState({ tokenValid: true })
      } else if (json.status === 'err-invalid-token') {
        this.setState({ tokenValid: false })
        openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown', undefined, undefined, 30)
      } else if (json.status === 'err') {
        this.setState({ tokenValid: false })
        openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown', undefined, undefined, 5)
      }
    })
  }

  handleSetPassword = (values) => {
    const { token } = this.props
    const { email, password, password_repeat: passwordRepeat } = values

    const data = {
      email,
      password,
      password_repeat: passwordRepeat
    }
    resetPasswordSetPassword(token, data).then((json) => {
      if (json.status === 'ok') {
        openNotificationPopup(messageTypes[this.props.language].success, json.response[this.props.language], 'smile', undefined, undefined, 5)
        this.setState({ redirect: true })
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[this.props.language].oops, json.response[this.props.language], 'frown', undefined, undefined, 5)
      }
    })
  }

  componentDidUpdate (prevProps) {
    const { token } = this.props
    const { tokenValid } = this.state

    if (prevProps !== this.props && token !== null && tokenValid === null) {
      this.checkToken(token)
    }
  }

  render () {
    const { language } = this.props
    const { redirect, tokenValid } = this.state

    if (redirect) {
      return <Redirect exact to='/login' />
    }

    const disableForm = tokenValid !== true

    return (
      <div>
        <h1>{messageTypes[language].set_new_password}</h1>
        <Form onFinish={this.handleSetPassword} className='login-form'>
          <Form.Item
            name='password'
            rules={[{ required: true, message: messageTypes[language].login_password_err }]}
          >
            <Input size='large' prefix={<LockOutlined />} type='password' placeholder={messageTypes[language].login_password} disabled={disableForm} />
          </Form.Item>
          <Form.Item
            name='password_repeat'
            rules={[{ required: true, message: messageTypes[language].login_password_repeat_err }]}
          >
            <Input size='large' prefix={<LockOutlined />} type='password' placeholder={messageTypes[language].login_password_repeat} disabled={disableForm} />
          </Form.Item>
          <Form.Item>
            <Button size='large' type='primary' htmlType='submit' className='login-form-button' disabled={disableForm}>
              {messageTypes[language].reset_password_action}
            </Button>
          </Form.Item>
          <Link to='/'>{messageTypes[language].back_to_login}</Link>
        </Form>
      </div>
    )
  }
}

export default ResetPasswordSetPasswordForm
