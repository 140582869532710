import React from 'react'
import { messageTypes } from '../../../actions/messages'

import {
  Form, Input, Button, Divider
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

class StyledUserForm extends React.Component {
  formRef = React.createRef()

  handleSubmit = (values) => {
    if (!values.new_password) {
      delete values.new_password
    }
    if (!values.password_repeat) {
      delete values.password_repeat
    }
    this.props.updateRecord(values)
  }

  setDefaultValues = () => {
    const { data } = this.props
    if (data) {
      this.formRef.current.setFieldsValue({
        email: data.email,
        password: '',
        new_password: '',
        password_repeat: ''
      })
    }
  }

  componentDidMount () {
    this.setDefaultValues()
  }

  componentDidUpdate (prevProps) {
    const { data, visible } = this.props
    if (
      data._id !== prevProps.data._id ||
      visible !== prevProps.visible
    ) {
      this.setDefaultValues()
    }
  }

  render () {
    const { language, data, loading } = this.props

    return (
      <Form onFinish={this.handleSubmit} layout='horizontal' ref={this.formRef} initialValues={{ name: data && data.name }}>
        <Form.Item
          name='name'
          label={messageTypes[language].login_name}
          rules={[{ required: true, message: messageTypes[language].login_name_err }]}
          {...formItemLayout}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name='email'
          label={messageTypes[language].login_email}
          rules={[{ required: true, message: messageTypes[language].login_email_err }]}
          {...formItemLayout}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='password'
          label={messageTypes[language].login_current_password}
          rules={[{ required: true, message: messageTypes[language].login_password_err }]}
          {...formItemLayout}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name='new_password'
          label={messageTypes[language].login_new_password}
          {...formItemLayout}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name='password_repeat'
          label={messageTypes[language].login_password_repeat}
          {...formItemLayout}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item className='form-footer'>
          <Divider />
          <Button htmlType='submit'>{loading ? <LoadingOutlined /> : ''}{messageTypes[language].submit}</Button>
          <Button onClick={() => { this.props.onCancel(data._id) }} className='cancel'>{messageTypes[language].cancel}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StyledUserForm
