import React, { Component } from 'react'
import FontAwesomeIcon from 'react-fontawesome'
import { messageTypes } from '../../../actions/messages'
import { Tooltip } from 'antd'

class StyledRevertButton extends Component {
  render () {
    const { revert, stack, stackActionStatus, language } = this.props
    const revertDisabled = !revert || stack?.status !== 'poweredon' || stackActionStatus !== undefined

    return (
      <Tooltip placement='top' title={revertDisabled ? (stackActionStatus !== undefined ? messageTypes[language].revert_is_disabled_stack_turned_off : messageTypes[language].revert_is_disabled) : ''}>
        <div
          onClick={() => revertDisabled ? null : this.props.handleClick(this.props.id)}
          disabled={revertDisabled}
          className={revertDisabled ? 'vm-action-disabled' : ''}
        >
          <FontAwesomeIcon
            name='undo'
            spin={this.props.status === 'reverting'}
          />
          {messageTypes[language].revert}
        </div>
      </Tooltip>
    )
  }
}

export default StyledRevertButton
