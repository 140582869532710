import React from 'react'
import { Modal } from 'antd'
import { messageTypes } from '../../../../actions/messages'
import { takeElementWithIdFromArr, getCookie, openNotificationPopup } from '../../../../actions/helpers'
import StyledChallengeForm from '../../../../styledComponents/sections/settings/challenges/form'
import { stackTypesOverlordGet, adminGetContentPacksForStackType, adminCopyChallengeResourceFileToLang } from '../../../../actions/'

class ChallengeModalSettings extends React.Component {
  state = {
    singleData: {},
    contentPackVersions: [],
    overlordStackTypes: []
  }

  handleCancel = () => {
    const { setDataId, showAddRecordModal } = this.props
    setDataId(null)
    showAddRecordModal(false)
    this.setState({
      contentPackVersions: []
    })
  }

  getContentPackVersions = (stackType) => {
    const { language } = this.props

    adminGetContentPacksForStackType(stackType, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            contentPackVersions: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  adminCopyChallengeResourceFileToLang = (id, lang, dstLang) => {
    const { language } = this.props

    adminCopyChallengeResourceFileToLang(id, lang, dstLang, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          openNotificationPopup(messageTypes[language].success, json.response[language], 'smile')
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  componentDidUpdate (prevProps, prevState) {
    const { dataId, data } = this.props

    if (prevProps.dataId !== dataId) {
      const singleData = takeElementWithIdFromArr(dataId, data)
      this.setState({ singleData })
      if (singleData) {
        this.getContentPackVersions(singleData.stack_type)
      }
    }
  }

  componentDidMount () {
    this._isMounted = true
    this.getStackTypesFromOverlord()
  }

  componentWillUnmount () {
    this._isMounted = true
  }

  getStackTypesFromOverlord = () => {
    const { language } = this.props
    stackTypesOverlordGet(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            overlordStackTypes: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }

        this.setState({
          loading: false
        })
      }
    })
  }

  render () {
    const {
      language, dataId, isNewRecord, loading,
      updateRecord, addRecord, challengeCategories, addFile, resources, removeSingleFile, removeResource
    } = this.props
    const { singleData } = this.state

    return (
      <div>
        <Modal
          maskClosable={false}
          width={1000}
          visible={!!(dataId || isNewRecord)}
          title={
            (isNewRecord && messageTypes[language].new_challenge) ||
            (
              singleData &&
              singleData.name &&
              singleData.name[language])
          }
          onCancel={this.handleCancel}
          footer={null}
        >
          <StyledChallengeForm
            challengeCategories={challengeCategories}
            isNewRecord={isNewRecord}
            data={singleData || {}}
            language={language}
            onCancel={this.handleCancel}
            updateRecord={updateRecord}
            addRecord={addRecord}
            addFile={addFile}
            removeSingleFile={removeSingleFile}
            removeResource={removeResource}
            loading={loading}
            resources={resources}
            contentPackVersions={this.state.contentPackVersions}
            getContentPackVersions={this.getContentPackVersions}
            overlordStackTypes={this.state.overlordStackTypes}
            adminCopyChallengeResourceFileToLang={this.adminCopyChallengeResourceFileToLang}
          />
        </Modal>
      </div>
    )
  }
}

export default ChallengeModalSettings
