import React from 'react'
import { messageTypes } from '../../../../actions/messages'
import {
  Form, Select, Button, Divider
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import settings from '../../../../config/'

const { Option } = Select

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

class StyledVpnsForm extends React.Component {
  formRef = React.createRef()
  state = {
    stackNetworks: []
  }

  handleSubmit = (values) => {
    const { data, isNewRecord, addRecord, updateRecord } = this.props
    const obj = {}
    obj.network_name = values.network_name
    obj.stack = values.stack
    obj.roles = values.roles

    if (isNewRecord) { addRecord(obj) } else { updateRecord(data._id, obj) }
  }

  setDefaultValues = () => {
    const { data, isNewRecord } = this.props

    if (data && Object.keys(data).length > 0 && !isNewRecord) {
      const stack = this.props.stacks.find(x => x._id === data?.stack)
      const stackType = this.props.overlordStackTypes.find(x => x.path === stack?.type)
      const stackNetworks = stackType?.networks || []

      this.setState({
        stackNetworks
      }, () => {
        const fieldsVal = {
          stack: data.stack,
          network_name: data.network_name,
          roles: data.roles
        }
        this.formRef.current.setFieldsValue(fieldsVal)
      })
    } else {
      this.setState({
      }, () => {
        this.formRef.current.setFieldsValue({
          stack: '',
          network_name: '',
          roles: []
        })
      })
    }
  }

  componentDidMount () {
    this.setDefaultValues()
  }

  componentDidUpdate (prevProps, prevState) {
    const { data, isNewRecord } = this.props
    if (data._id !== prevProps.data._id || isNewRecord !== prevProps.isNewRecord) {
      this.setDefaultValues()
    }
  }

  handleStackTypeChange = (stackTypeUsed) => {
    const stack = this.props.stacks.find(x => x._id === stackTypeUsed)
    const stackType = this.props.overlordStackTypes.find(x => x.path === stack.type)
    const stackNetworks = stackType?.networks || []

    this.setState({ stackNetworks }, () => {
      this.formRef.current.setFieldsValue({
        network_name: ''
      })
    })
  }

  render () {
    const { language, loading, stacks } = this.props

    return (
      <Form onFinish={this.handleSubmit} layout='horizontal' ref={this.formRef}>

        <Form.Item
          name='stack'
          label={messageTypes[language].stack}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <Select onChange={this.handleStackTypeChange}>
            {
              stacks && stacks.length
                ? stacks.map((element, n) =>
                  <Option key={n} value={element._id}>{element.name} ({element.type})</Option>
                )
                : ''
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='network_name'
          label={messageTypes[language].network_name}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <Select>
            {
              this.state.stackNetworks.length
                ? this.state.stackNetworks.map((element, n) =>
                  <Option key={n} value={element}>{element}</Option>
                )
                : ''
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='roles'
          label={messageTypes[language].view_for_roles}
          {...formItemLayout}
        >
          <Select mode='multiple'>
            {
              settings.roles && settings.roles.length
                ? settings.roles.map((element, n) =>
                  <Option key={n} value={element.role}>{messageTypes[language]['role_' + element.name]}</Option>
                )
                : ''
            }
          </Select>
        </Form.Item>

        <Form.Item className='form-footer'>
          <Divider />
          <Button htmlType='submit'>{loading ? <LoadingOutlined /> : ''} {messageTypes[language].submit}</Button>
          <Button onClick={() => { this.props.onCancel() }} className='cancel'>{messageTypes[language].cancel}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StyledVpnsForm
