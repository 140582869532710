import React, { Component } from 'react'
import StyledFilterButtons from '../../../../styledComponents/sections/settings/logging/filterButtons'

class FilterButtons extends Component {
  constructor (props) {
    super(props)
    this.verbosity_lvls = [1, 2, 3]
  }

  render () {
    return (
      <StyledFilterButtons
        verbosityLvls={this.verbosity_lvls}
        {...this.props}
      />
    )
  }
}

export default FilterButtons
