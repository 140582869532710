import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { Form, Input, Button } from 'antd'
import { messageTypes } from '../../../actions/messages'
import { resetPassword } from '../../../actions/'
import { openNotificationPopup } from '../../../actions/helpers'

class ResetPasswordRequestForm extends React.Component {
  state = {
    redirect: false
  }

  handleResetPassword = (values) => {
    const { language } = this.props
    const { email } = values
    const data = {
      email,
      language
    }

    resetPassword(data).then((json) => {
      if (json.status === 'ok') {
        openNotificationPopup(messageTypes[this.props.language].success, json.response[this.props.language], 'smile', undefined, undefined, 5)
        this.setState({ redirect: true })
      } else if (json.status === 'err-no-internet-connection') {
        openNotificationPopup(messageTypes[this.props.language].oops, json.response[this.props.language], 'frown', undefined, undefined, 10)
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[this.props.language].oops, json.response[this.props.language], 'frown', undefined, undefined, 5)
      }
    })
  }

  render () {
    const { language } = this.props
    const { redirect } = this.state
    if (redirect) {
      return <Redirect exact to='/login' />
    }
    return (
      <div>
        <h1>{messageTypes[language].reset_password}</h1>
        <Form onFinish={this.handleResetPassword} className='login-form'>
          <Form.Item
            name='email'
            rules={[{ required: true, message: messageTypes[language].login_email_err }]}
          >
            <Input size='large' prefix={<i className='fa fa-at' aria-hidden='true' />} placeholder={messageTypes[language].enter_email_used_during_registration} />
          </Form.Item>
          <Form.Item>
            <Button size='large' type='primary' htmlType='submit' className='login-form-button'>
              {messageTypes[language].reset_password_action}
            </Button>
          </Form.Item>
          <Link to='/'>{messageTypes[language].back_to_login}</Link>
        </Form>
      </div>
    )
  }
}

export default ResetPasswordRequestForm
