import React, { Component } from 'react'
// import ResourcesVisualiser from '../../common/resourcesVisualiser';
import TagsDisplayer from './tagsDisplayer'
import { messageTypes } from '../../../actions/messages'
import PerfectScrollbar from 'react-perfect-scrollbar'

class StyledCardBody extends Component {
  componentDidMount () {
    this._scrollBarRef && this._scrollBarRef.updateScroll()
  }

  render () {
    const { handleClick, element, language } = this.props
    const { tags, desc, desc_long: descLong } = element // resources
    const brDesc = desc[language].split('\n')

    return (
      <div className={descLong ? 'hoverable hoverable-scenarios' : ''} onClick={() => { descLong && handleClick(element) }}>
        <PerfectScrollbar style={{ textAlign: 'left' }} ref={(ref) => { this._scrollBarRef = ref }} className={'scroll-scenarios' + (tags && tags.length > 0 ? ' with-tags' : '')}>
          {element.blocked_by && <p className='item-blocked'>{messageTypes[language].reserved_to} {element.blocked_to} {messageTypes[language].reserved_by} {element.blocked_by?.name}</p>}
          {
            brDesc.map((val, i) => (
              <div key={i}>{val} <br /></div>
            ))
          }
        </PerfectScrollbar>
        {/* <ResourcesVisualiser resources={resources} /> */}
        <TagsDisplayer tags={tags} />
      </div>
    )
  }
}

export default StyledCardBody
