import React, { Component } from 'react'
import { openNotificationPopup, getCookie } from '../../../actions/helpers'
import { messageTypes } from '../../../actions/messages'
import StyledFilesDownloader from '../../../styledComponents/common/filesDownloader'
import StyledMapObjectModal from '../../../styledComponents/sections/home/mapObjectModal'
import { getMapObjectResources, mapObjectFileDownload } from '../../../actions/'

const FileDownload = require('js-file-download')

class MapObjectModal extends Component {
  state = {
    resources: []
  }

  onHandleClose = (e) => {
    this.props.toggleModal(false, null)
  }

  getElementId = (element) => {
    return element._id
  }

  prepareFilename = (element, stack, filename) => {
    return filename
  }

  setMachineCardTitle = () => {
    return null
  }

  componentDidUpdate (prevProps, prevState) {
    const { element } = this.props

    if (element?.map_object_hd_id && element?._id !== prevProps.element?._id) {
      this.getMapObjectResources(element)
    }
  }

  downloadFile = (element, filename, language, file) => {
    mapObjectFileDownload(element.map_object_hd_id, filename, language, getCookie('_token')).then((response) => {
      // download file if get was successfull
      if (response.status === 200) {
        FileDownload(response.data, file.label[language] + '.' + file.file_type)
      } else {
        openNotificationPopup(messageTypes[language].oops, messageTypes.universal.error, 'frown')
      }
    })
  }

  getMapObjectResources (element) {
    getMapObjectResources(element.map_object_hd_id, getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        this.setState({ resources: json.response })
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[this.props.language].oops, json.response[this.props.language], 'frown')
      }
    })
  }

  getFileDownloadElement = (element, resources) => {
    const { language } = this.props

    return (resources && resources.length > 0
      ? (
        <StyledFilesDownloader
          key='files_downloader'
          language={language}
          element={element}
          downloadFile={this.downloadFile}
          useFileId
          files={resources}
        />)
      : '')
  }

  render () {
    const { element, machineElement, language, visible, getAllMachines, setAllowStateStatus, allowStateStatus, stack, clearAllowMachineStateStatus } = this.props

    return (
      (element)
        ? (
          <StyledMapObjectModal
            element={element}
            stack={stack}
            machineElement={machineElement}
            machineCardTitle={this.setMachineCardTitle(element)}
            language={language}
            visible={visible}
            downloadFile={this.downloadFile}
            handleClose={this.onHandleClose}
            getAllMachines={getAllMachines}
            setAllowStateStatus={setAllowStateStatus}
            clearAllowMachineStateStatus={clearAllowMachineStateStatus}
            allowStateStatus={allowStateStatus}
            handleConsole={this.toggleConsole}
            getFileDownloadElement={this.getFileDownloadElement}
            resources={this.state.resources}
            refreshData={this.props.refreshData}
          />
          )
        : ''
    )
  }
}

export default MapObjectModal
