import React, { Component } from 'react'
import { connect } from 'react-redux'
import ConsoleEmulatorNewWindow from './consoleEmulatorNewWindow'
import { machineGet } from '../../../actions'
import { openNotificationPopup, getCookie } from '../../../actions/helpers'
import { messageTypes } from '../../../actions/messages'
import NoMatch from '../../../components/sections/404'

class MachinesConsole extends Component {
  constructor () {
    super()
    this.state = {
      machineId: null,
      machineFound: null
    }
  }

  getMachine = (machineId) => {
    const { language } = this.props

    machineGet(machineId, getCookie('_token')).then((response) => {
      const json = response.data

      // handle if vm is unknown
      if (response.http_status === 404) {
        this.setState({ machineFound: false })
      }

      if (json.status === 'ok') {
        const machine = json.response
        this.setState({ machineFound: true })
        // set window title
        document.title = machine.name[language]
      } else if (json.status === 'err') {
        openNotificationPopup(
          messageTypes[language].oops,
          messageTypes[language].machine_console_err,
          'frown',
          json.response[language],
          language
        )
      }
    })
  }

  componentDidMount () {
    const { vmware_id: vmwareId } = this.props.match.params
    this.setState({ machineId: vmwareId })

    this.getMachine(vmwareId)
  }

  render () {
    const { machineId, machineFound } = this.state

    return (
      (machineFound === false
        ? <NoMatch />
        : (
          <ConsoleEmulatorNewWindow
            language={this.props.language}
            machineFound={machineFound}
            machineId={machineId}
          />
          )
      )
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language
})

const MachinesContainer = connect(
  mapStateToProps
)(MachinesConsole)

export default MachinesContainer
