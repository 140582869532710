import settings from '../../../config/'
import MapObjectModal from './mapObjectModal'
import { openNotificationPopup, getCookie } from '../../../actions/helpers'
import { messageTypes } from '../../../actions/messages'
import { getMachineResources, machineFileDownload } from '../../../actions/'

const FileDownload = require('js-file-download')

class MachineModal extends MapObjectModal {
  constructor () {
    super()
    this.state = {
      machineId: null,
      consoleOpen: false,
      machinesRefreshInterval: null,
      resources: []
    }
  }

  getElementId = (element) => {
    return element.machine_name
  }

  downloadFile = (element, filename, language, file) => {
    machineFileDownload(element.machine_hd_id, filename, language, getCookie('_token')).then((response) => {
      // download file if get was successfull
      if (response.status === 200) {
        FileDownload(response.data, file.label[language] + '.' + file.file_type)
      } else {
        openNotificationPopup(messageTypes[language].oops, messageTypes.universal.error, 'frown')
      }
    })
  }

  getMachineResources (element) {
    getMachineResources(element.machine_hd_id, getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        this.setState({ resources: json.response })
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[this.props.language].oops, json.response[this.props.language], 'frown')
      }
    })
  }

  componentDidMount () {
    // set interval for refreshing if ajax refresh is enabled
    if (settings.ajax_refresh_rate) {
      const machinesRefreshInterval = setInterval(() => {
        this.props.getAllMachines()
      }, settings.ajax_refresh_rate * 1000)
      this.setState({ machinesRefreshInterval })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { element, machineElement } = this.props

    if (element?.machine_hd_id && machineElement?.id !== prevProps.machineElement?.id) {
      this.getMachineResources(element)
    }
  }

  componentWillUnmount () {
    if (settings.ajax_refresh_rate) {
      clearInterval(this.state.machinesRefreshInterval)
    }
  }
}

export default MachineModal
