import React, { Component } from 'react'
import { Button } from 'antd'
import FontAwesomeIcon from 'react-fontawesome'
import { messageTypes } from '../../actions/messages'

class RefreshButton extends Component {
  state = {
    visible: false
  }

  componentDidMount () {
    this.setState({ visible: true })
  }

  render () {
    const { refreshAction, language, loading } = this.props
    return (
      <div className={`refresh-button ${this.state.visible && 'fade-in'}`}>
        <Button onClick={() => { refreshAction() }} disabled={loading}>
          <FontAwesomeIcon name='refresh' />{messageTypes[language].refresh}
        </Button>
      </div>
    )
  }
}

export default RefreshButton
