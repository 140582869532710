import React, { Component } from 'react'
import { Progress } from 'antd'

class StyledProgressBar extends Component {
  returnColor (percent) {
    return percent < 50 ? 'green' : percent < 80 ? 'yellow' : 'red'
  }

  returnName (unit, max) {
    const gigaVal = parseFloat((max / 1000).toFixed(2))
    return <span>({gigaVal} G{unit})</span>
  }

  returnBigName (unit, usage, max) {
    let _usage
    let _max
    let _unit
    if (usage > 10000) {
      _usage = (usage / 1000).toFixed(2)
      _max = (max / 1000).toFixed(2)
      _unit = 'G' + unit
    } else {
      _usage = (usage).toFixed(0)
      _max = (max).toFixed(0)
      _unit = 'M' + unit
    }

    return <h4 className='progress-footer'>{_usage} / {_max} {_unit}</h4>
  }

  render () {
    const { name, type, unit, usage, max } = this.props
    const percent = parseFloat((100 * usage / max).toFixed(2))

    return (
      <div>
        <h5
          style={type === 'circle' ? { fontSize: '1rem' } : {}}
          className='progress-title'
        >
          {name}
        </h5>
        <Progress
          percent={percent}
          type={type || 'line'}
          width={type === 'line' ? 60 : 90}
          showInfo={type === 'circle' && true}
          strokeColor={this.returnColor(percent)}
          status='active'
        />
        {
          type === 'circle' ? this.returnBigName(unit, usage, max) : ''
        }
      </div>
    )
  }
  // { type === "circle" ? '' : this.returnName(unit, max) }
}

export default StyledProgressBar
