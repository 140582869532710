import React, { Component } from 'react'
import StyledResetPasswordSetPassword from '../../../styledComponents/sections/reset-password/setPassword'
import { connect } from 'react-redux'

class ResetPasswordSetPassword extends Component {
  constructor () {
    super()
    this.state = {
      token: null
    }
  }

  componentDidMount () {
    const { token } = this.props.match.params
    this.setState({ token })
  }

  render () {
    const { token } = this.state

    return (
      <StyledResetPasswordSetPassword
        language={this.props.language}
        token={token}
      />
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language
})

const ResetPasswordSetPasswordContainer = connect(
  mapStateToProps
)(ResetPasswordSetPassword)

export default ResetPasswordSetPasswordContainer
