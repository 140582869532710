import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { Form, Input, Button } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { messageTypes } from '../../../actions/messages'
import { signup } from '../../../actions/'
import { openNotificationPopup } from '../../../actions/helpers' // setCookie

class NormalRegisterForm extends React.Component {
  state = {
    redirect: false
  }

  handleRegister = (values) => {
    const { name, email, password, password_repeat: passwordRepeat } = values
    const data = {
      name,
      email,
      password,
      password_repeat: passwordRepeat
    }
    signup(data).then((json) => {
      if (json.status === 'ok') {
        openNotificationPopup(messageTypes[this.props.language].success, json.response[this.props.language], 'smile')
        this.setState({ redirect: true })
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[this.props.language].oops, json.response[this.props.language], 'frown')
      }
      // setCookie("token", json.auth.token, json.auth.expires)
      // openNotificationPopup( messageTypes[this.props.language]['success'], json.response[this.props.language], "smile");
    })
  }

  render () {
    const { language } = this.props
    const { redirect } = this.state
    if (redirect) {
      return <Redirect exact to='/login' />
    }
    return (
      <div>
        <h1>{messageTypes[language].registration}</h1>
        <Form onFinish={this.handleRegister} className='login-form'>
          <Form.Item
            name='name'
            rules={[{ required: true, message: messageTypes[language].login_name_err }]}
          >
            <Input size='large' prefix={<UserOutlined />} placeholder={messageTypes[language].login_name} ref={input => input && input.focus()} />
          </Form.Item>
          <Form.Item
            name='email'
            rules={[{ required: true, message: messageTypes[language].login_email_err }]}
          >
            <Input size='large' prefix={<i className='fa fa-at' aria-hidden='true' />} placeholder={messageTypes[language].login_email} />
          </Form.Item>
          <Form.Item
            name='password'
            rules={[{ required: true, message: messageTypes[language].login_password_err }]}
          >
            <Input size='large' prefix={<LockOutlined />} type='password' placeholder={messageTypes[language].login_password} />
          </Form.Item>
          <Form.Item
            name='password_repeat'
            rules={[{ required: true, message: messageTypes[language].login_password_repeat_err }]}
          >
            <Input size='large' prefix={<LockOutlined />} type='password' placeholder={messageTypes[language].login_password_repeat} />
          </Form.Item>
          <Form.Item>
            <Button size='large' type='primary' htmlType='submit' className='login-form-button'>
              {messageTypes[language].register}
            </Button>
          </Form.Item>
          <Link to='/'>{messageTypes[language].back_to_login}</Link>
        </Form>
      </div>
    )
  }
}

export default NormalRegisterForm
