const settings = {
  api_url: '/api',
  socket_url: '/',      // socket url; any sub dir may not work! eg /api will not work
  languages: ['pl', 'en'],              // All available languages
  normalAdminModules: ['challenges', 'flags', 'challenge_categories', 'maps', 'users', 'sessions', 'logs', 'stacks'],

  //custom_icon_set: {
  //  outside_task: {
  //    shape: 'image',
  //    image: '/images/customization/icons/action_ikonka_blue2.svg',
  //    size: 33
  //  },
  //  outside_task_blue: {
  //    shape: 'image',
  //    image: '/images/customization/icons/action_ikonka_blue.svg',
  //    size: 33
  //  },
  //  outside_task_blue2: {
  //    shape: 'image',
  //    image: '/images/customization/icons/action_ikonka_blue2.svg',
  //    size: 33
  //  },

  //  osint: {
  //    shape: 'image',
  //    image: '/images/customization/icons/osint_ikonka_blue2.svg',
  //    size: 33
  //  },
  //  osint_blue: {
  //    shape: 'image',
  //    image: '/images/customization/icons/osint_ikonka_blue.svg',
  //    size: 33
  //  },
  //  osint_blue2: {
  //    shape: 'image',
  //    image: '/images/customization/icons/osint_ikonka_blue2.svg',
  //    size: 33
  //  },

  //  osint_2: {
  //    shape: 'image',
  //    image: '/images/customization/icons/osint_ikonka_2_blue2.svg',
  //    size: 33
  //  },
  //  osint_2_blue: {
  //    shape: 'image',
  //    image: '/images/customization/icons/osint_ikonka_2_blue.svg',
  //    size: 33
  //  },
  //  osint_2_blue2: {
  //    shape: 'image',
  //    image: '/images/customization/icons/osint_ikonka_2_blue2.svg',
  //    size: 33
  //  },

  //  steganography: {
  //    shape: 'image',
  //    image: '/images/customization/icons/steganography_ikonka_blue2.svg',
  //    size: 33
  //  },
  //  steganography_blue: {
  //    shape: 'image',
  //    image: '/images/customization/icons/steganography_ikonka_blue.svg',
  //    size: 33
  //  },
  //  steganography_blue2: {
  //    shape: 'image',
  //    image: '/images/customization/icons/steganography_ikonka_blue2.svg',
  //    size: 33
  //  },

  //  web: {
  //    shape: 'image',
  //    image: '/images/customization/icons/web_ikonka_blue2.svg',
  //    size: 33
  //  },
  //  web_blue: {
  //    shape: 'image',
  //    image: '/images/customization/icons/web_ikonka_blue.svg',
  //    size: 33
  //  },
  //  web_blue2: {
  //    shape: 'image',
  //    image: '/images/customization/icons/web_ikonka_blue2.svg',
  //    size: 33
  //  },

  //  web_2: {
  //    shape: 'image',
  //    image: '/images/customization/icons/web_ikonka_2_blue2.svg',
  //    size: 33
  //  },
  //  web_2_blue: {
  //    shape: 'image',
  //    image: '/images/customization/icons/web_ikonka_2_blue.svg',
  //    size: 33
  //  },
  //  web_2_blue2: {
  //    shape: 'image',
  //    image: '/images/customization/icons/web_ikonka_2_blue2.svg',
  //    size: 33
  //  },

  //  forensic: {
  //    shape: 'image',
  //    image: '/images/customization/icons/forensic_ikonka_blue2.svg',
  //    size: 33
  //  },
  //  forensic_blue: {
  //    shape: 'image',
  //    image: '/images/customization/icons/forensic_ikonka_blue.svg',
  //    size: 33
  //  },
  //  forensic_blue2: {
  //    shape: 'image',
  //    image: '/images/customization/icons/forensic_ikonka_blue2.svg',
  //    size: 33
  //  },

  //  cryptography: {
  //    shape: 'image',
  //    image: '/images/customization/icons/cryptography_ikonka_blue2.svg',
  //    size: 33
  //  },
  //  cryptography_blue: {
  //    shape: 'image',
  //    image: '/images/customization/icons/cryptography_ikonka_blue.svg',
  //    size: 33
  //  },
  //  cryptography_blue2: {
  //    shape: 'image',
  //    image: '/images/customization/icons/cryptography_ikonka_blue2.svg',
  //    size: 33
  //  },

  //  front_end: {
  //    shape: 'image',
  //    image: '/images/customization/icons/front-end_ikonka_blue2.svg',
  //    size: 33
  //  },
  //  front_end_blue: {
  //    shape: 'image',
  //    image: '/images/customization/icons/front-end_ikonka_blue.svg',
  //    size: 33
  //  },
  //  front_end_blue2: {
  //    shape: 'image',
  //    image: '/images/customization/icons/front-end_ikonka_blue2.svg',
  //    size: 33
  //  },

  //  programming: {
  //    shape: 'image',
  //    image: '/images/customization/icons/programming_ikonka_blue2.svg',
  //    size: 33
  //  },
  //  programming_blue: {
  //    shape: 'image',
  //    image: '/images/customization/icons/programming_ikonka_blue.svg',
  //    size: 33
  //  },
  //  programming_blue2: {
  //    shape: 'image',
  //    image: '/images/customization/icons/programming_ikonka_blue2.svg',
  //    size: 33
  //  },

  //  reverse_eng: {
  //    shape: 'image',
  //    image: '/images/customization/icons/riverse_eng_ikonka_blue2.svg',
  //    size: 33
  //  },
  //  reverse_eng_blue: {
  //    shape: 'image',
  //    image: '/images/customization/icons/riverse_eng_ikonka_blue.svg',
  //    size: 33
  //  },
  //  reverse_eng_blue2: {
  //    shape: 'image',
  //    image: '/images/customization/icons/riverse_eng_ikonka_blue2.svg',
  //    size: 33
  //  },

  //  special_quest: {
  //    shape: 'image',
  //    image: '/images/customization/icons/special_quest_ikonka_blue2.svg',
  //    size: 33
  //  },
  //  special_quest_blue: {
  //    shape: 'image',
  //    image: '/images/customization/icons/special_quest_ikonka_blue.svg',
  //    size: 33
  //  },
  //  special_quest_blue2: {
  //    shape: 'image',
  //    image: '/images/customization/icons/special_quest_ikonka_blue2.svg',
  //    size: 33
  //  },

  //  logo: {
  //    shape: 'image',
  //    // image: '/images/loader.svg',
  //    image: '/images/customization/logo.svg',
  //    size: 33
  //  },

  //  hidden: {
  //    shape: 'image',
  //    // image: '/images/loader.svg',
  //    image: '/images/customization/icons/ukryte_ikonka_blue2.svg',
  //    size: 33
  //  },
  //},

  //custom_attribute_set: {
  //  stars1: {
  //    shape: 'image',
  //    image: '/images/customization/icons/1stars.svg',
  //    size: 33
  //  },
  //  stars2: {
  //    shape: 'image',
  //    image: '/images/customization/icons/2stars.svg',
  //    size: 33
  //  },
  //  stars3: {
  //    shape: 'image',
  //    image: '/images/customization/icons/3stars.svg',
  //    size: 33
  //  },
  //},

  //custom_icon_set_groups_blacklist: [
  //  'outside_task_blue',
  //  'outside_task_blue2',
  //  'osint_2_blue',
  //  'osint_2_blue2',
  //  'osint_blue',
  //  'osint_blue2',
  //  'steganography_blue',
  //  'steganography_blue2',
  //  'web_2_blue',
  //  'web_2_blue2',
  //  'web_blue',
  //  'web_blue2',
  //  'forensic_blue',
  //  'forensic_blue2',
  //  'cryptography_blue',
  //  'cryptography_blue2',
  //  'front_end_blue',
  //  'front_end_blue2',
  //  'programming_blue',
  //  'programming_blue2',
  //  'reverse_eng_blue',
  //  'reverse_eng_blue2',
  //  'special_quest_blue',
  //  'special_quest_blue2',
  //  'hidden',
  //],

  //ctf_custom_icons_done: 'blue',
  //ctf_custom_icons_undone: 'blue2',

  //custom_map_colors: ['#ff0000', '#000000', '#EAE8FF', '#ffd700', '#0057b7'],

  disable_flags_in_ranking: false,

  //app_title: 'Akademia STM - Dni Otwarte',
	//app_footer: 'DOWÓDZTWO KOMPONENTU WOJSK OBRONY CYBERPRZESTRZENI\nLABORATORIUM CYBERBEZPIECZEŃSTWA ©2023',
  	//app_login_text: 'FORENSICS WINTER CAMP - CHALLENGE 3.7E7',
  	//app_logo2: 'ctf-logo.png',
  	//app_login_title: 'FORENSICS WINTER CAMP',
  	//app_login_subtitle: 'CHALLENGE 3.7E7',
  	//app_login_footer: 'DOWÓDZTWO KOMPONENTU WOJSK OBRONY CYBERPRZESTRZENI\nLABORATORIUM CYBERBEZPIECZEŃSTWA ©2023',
  	//app_logo: 'dkwoc_logo_pl.png',
  	//app_logo_menu: 'dkwoc_logo_pl.png',
  	//app_loader: 'logo.svg',
  	//app_favicon: 'dkwoc_logo_pl.png',
  	//app_ranking_logo: 'logo.svg',
  	//app_map_bg: 'bg10.png',
  	//app_logo_stack_title: 'ctf-logo.png',
  	//map_default_font_size: 18,                 // Size of font on map; default value: 14


  default_lang: 'pl',                   // Default app language
  installation_type: 'cloud',           // installation type: [cloud, box]
  recruitment: false,                   // true if this is install for recruitment purposes
  ajax_refresh_rate: 0,                 // Wyrażony w sekundach, odstęp czasu pomiędzy pobieraniem danych widoku maszyn i scenariuszy
  registration: false,                  // Is registration form enabled
  password_reset: false,                // Is password reset form enabled
  instruction: false,                   // Show instruction button in top nav
  // stack_switch_state: 'poweroff',    // Action to make on previous stack when switching stack, if not set no prompt will show
  // stack_switch_default_change_state: false, // Make stack switch prompt state change checkbox checked for default; if false or not set will be not checked
  scenarios_blocking_enabled: false,    // Are scenario reservations enabled?
  overlord_enabled: true,
  stack_types: [                        // Stack types available for this install
    'box', 'cloud', 'recruitment', 'ctf_lab', 'ctf', 'ctf_jeopardy', 'stm_academy_web', 'stm_academy_network'
  ],
  ca_certificates: [
    'hackingdept', 'hackgroup'
  ],
  demo_types: [
    'full', 'partial'
  ],
  roles: [                              // Roles available for set for users
    {'role': 1, 'name': 'admin'},
    {'role': 2, 'name': 'user'},
    {'role': 3, 'name': 'user_recruitment'},
    {'role': 4, 'name': 'user_ctf_ro'}
  ],
  networks: [                           // VPN Networks available for this install
    // {'network_name': 'hackgroup-dmz', 'title': 'HackGroup DMZ'},
    {'network_name': 'hackbank-lan', 'title': 'HackBank LAN'},
    // {'network_name': 'hackgroup-malwarelab', 'title': 'HackGroup MalwareLab'},
    // {'network_name': 'hackstock-lan', 'title': 'HackStock LAN'},
    // {'network_name': 'ctf-lab-network', 'title': 'CTF Lab Network'},
    {'network_name': 'attackers-network', 'title': 'Attacker\'s Network'},
    {'network_name': 'management-network', 'title': 'HD Management Network'},
    {'network_name': 'webnet', 'title': 'WebNetwork'},
    {'network_name': 'external-network', 'title': 'External Network'}

  ]
}

const views = {
  sa:"fNOvREpuGbsjgG5EXYfFA7J0Ebb2G0Ua",
  ad:"RkGXhJ3L0XrqTd9JSGVtvdRZJ99A6isu",
  us:"iZeephooGaiquo3Quiesh0ooLoh5xeij",
  re:"be8oe6cae9Kaequ5Gahsiexoobeequey",
  ucr:"IFoiTAfaitANxvGy58Pb5J1iwoXmqfEH"
}

export default settings;
export { views }
