import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card } from 'antd'
import { setScenarioElement, setScenarioModalVisible } from '../../../store/actions'
import { openNotificationPopup, getCookie } from '../../../actions/helpers'
import StyledCardBody from '../../../styledComponents/sections/scenarios/cardBody'
import StyledStarsDisplay from '../../../styledComponents/sections/scenarios/starsDisplay'
import StyledTagsDisplay from '../../../styledComponents/common/tagsDisplay'
import StyledFilesDownloader from '../../../styledComponents/common/filesDownloader'
import { scenarioFileDownload } from '../../../actions/'
import { messageTypes } from '../../../actions/messages'

const FileDownload = require('js-file-download')

class ScenariosCard extends Component {
  constructor (props) {
    super(props)

    this.state = {
      resources: []
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  modalPopup = (scenarioElement) => {
    this.props.setScenarioElement(scenarioElement)
    this.props.setScenarioModalVisible(true)
  }

  downloadScenarioFile (element, filename, language, file) {
    scenarioFileDownload(element.scenario_hd_id, filename, language, getCookie('_token')).then((response) => {
      // download file if get was successfull
      if (response.status === 200) {
        FileDownload(response.data, file.label[language] + '.' + file.file_type)
      } else {
        openNotificationPopup(messageTypes[language].oops, messageTypes.universal.error, 'frown')
      }
    })
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { element } = this.props
    const { difficulty, s_type: sType } = element
    const { language } = this.props

    const name = element.name[language] || messageTypes[language].scenario_no_name

    return (
      <Card
        title={
          <div>
            {sType === 'presentation'
              ? (name.length <= 10 ? <StyledTagsDisplay tags={[messageTypes[language]['scenario_type_' + sType]]} /> : '')
              : <StyledStarsDisplay difficulty={difficulty} />}
            <span className='header-title'>{name}</span>
          </div>
        }
        actions={[
          <StyledFilesDownloader
            key='fdownload'
            language={language}
            element={element}
            downloadFile={this.downloadScenarioFile}
            files={element.files}
            useFileId
          />
        ]}
      >
        <StyledCardBody
          handleClick={this.modalPopup}
          language={language}
          element={element}
        />
      </Card>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language,
  scenarioElement: state.hdStore.scenarioElement,
  scenarioModalVisible: state.hdStore.scenarioModalVisible
})

const mapDispatchToProps = {
  setScenarioElement,
  setScenarioModalVisible
}

const ScenariosCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScenariosCard)

export default ScenariosCardContainer
