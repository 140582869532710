import React from 'react'
import { Statistic } from 'antd'
import { messageTypes } from '../../actions/messages'

const { Countdown } = Statistic

class CtfHeaderClock extends React.Component {
  render () {
    const { className, language, ctfEnded, ctfStarted, showBeforeStartClock } = this.props

    return (
      <div className={'ctf-clocks' + (((className && (ctfEnded || ctfStarted || showBeforeStartClock)) && ' ' + className) || '')}>
        {this.props.showBeforeStartClock &&
          <>
            <span>{messageTypes[language].ctf_start_in}</span>
            <Countdown value={this.props.ctfStartDate} onFinish={this.props.handleBeforeStartClockFinished} />
          </>}
        {this.props.ctfEnded && <h1 className='ctf-finished'>{messageTypes[language].ctf_ended}</h1>}
        {this.props.ctfStarted && <h1 className='ctf-started'>{messageTypes[language].ctf_started}</h1>}
      </div>
    )
  }
}

export default CtfHeaderClock
