import React, { Component } from 'react'
import { connect } from 'react-redux'
import StyledMapsTable from '../../../../styledComponents/sections/settings/maps/mapsTable'
import {
  adminGetMaps, adminGetContentPacks
} from '../../../../actions/'
import { getCookie, openNotificationPopup } from '../../../../actions/helpers'
import { messageTypes } from '../../../../actions/messages'

class Maps extends Component {
  state = {
    data: []
  }

  componentDidMount () {
    this._isMounted = true

    this.getMaps()
    this.getContentPacks()
  }

  getContentPacks = () => {
    const { language } = this.props

    adminGetContentPacks(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            contentPacks: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  getMaps = (stackType) => {
    adminGetMaps(getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        if (this._isMounted) {
          this.setState({
            data: json.response
          })
        }
      } else if (json.status === 'err') {
        openNotificationPopup(
          messageTypes[this.props.language].oops,
          json.response[this.props.language],
          'frown'
        )
      }
    })
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  returnWithKey (data, lang) {
    const retArr = []
    data.forEach((val, i) => {
      const obj = {}
      obj.key = i
      obj.type = val.name
      obj.installed_versions = val.installed_versions

      retArr.push(obj)
    })
    return retArr
  }

  render () {
    const { data } = this.state
    const { language } = this.props

    return (
      <StyledMapsTable
        language={language}
        contentPacks={this.state.contentPacks}
        data={this.returnWithKey(data, language)}
      />
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language
})

const MapsContainer = connect(
  mapStateToProps
)(Maps)

export default MapsContainer
