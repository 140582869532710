import React from 'react'
import { Layout } from 'antd'
import Navbar from '../../components/common/navbar'
import Main from '../../components/common/main'
import HDComponent from '../../components/common/hdComponent'

class StyledMainLayout extends HDComponent {
  render () {
    const { language, children } = this.props

    return (
      <Layout>
        <Navbar />
        <Main language={this.determineLanguage(language)}>
          {children}
        </Main>
      </Layout>
    )
  }
}

export default StyledMainLayout
