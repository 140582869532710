import React, { Component } from 'react'
import { connect } from 'react-redux'
import StyledNavbar from '../../styledComponents/common/navbar'
import { setSearchPassphrase } from '../../store/actions'

class Navbar extends Component {
  render () {
    const { auth, language, stack } = this.props
    return (
      <StyledNavbar
        auth={auth}
        language={language}
        stack={stack}
      />
    )
  }
}

const mapStateToProps = state => ({
  auth: state.hdStore.auth,
  language: state.hdStore.language,
  searchPassphrase: state.hdStore.searchPassphrase,
  stack: state.hdStore.stack
})

const mapDispatchToProps = {
  setSearchPassphrase
}

const NavbarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar)

export default NavbarContainer
