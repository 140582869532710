import React, { Component } from 'react'
import { Modal } from 'antd'
import { messageTypes } from '../../actions/messages'
import ReactMarkdown from 'react-markdown'

class InstructionModal extends Component {
  handleCancel = () => {
    this.props.handleInstructionVisibility(false)
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { language, visible } = this.props

    const content = `## Instrukcja korzystania z HackingDept Cloud Demo

Po zalogowaniu się do Dashboardu, użytkownik w pierwszej kolejności powinien wybrać interesujące go środowisko (za pomocą przycisku "Zmień środowisko").

W wersji Demo dostępne są dwa środowiska:

- HD Demo Cloud - zawierające scenariusze typowo Red Teamingowe, skupiające się na atakowaniu firmy HackBank oraz Blue Teamingowe, tj. Analiza Malware.
- HD Demo CTF Lab - zawierające zestawy krótkich zadań, które mogą być wykorzystane m.in. przy rekrutacji.

Uwaga! Środowiska różnią się dostępnymi scenariuszami oraz maszynami.

Kolejnym krokiem jest wybranie przez użytkownika interesującego go scenariusza z zakładki "Scenariusze" oraz pobranie materiałów z nim związanych.
W materiałach zawsze znajduje się dokument PDF stanowiący instrukcję tłumaczącą krok po kroku, jak należy wykonać dany scenariusz.

Scenariusze są wyróżnione trzema różnymi kolorami:

- Czerwone scenariusze to "Scenariusze Prezentacyjne", które mogą zostać wykorzystane do przeprowadzenia szkoleń Security Awareness.
- Zielone scenariusze to "Scenariusze Szkoleniowe", które w sposób bardzo techniczny tłumaczą dane zagadnienie.
- Żółte scenariusze to "Scenariusze rekrutacyjne", które są zestawami krótkich, niezależnych od siebie problemów do rozwiązania.

W pełnej wersji HackingDept BOX istnieją również scenariusze typu Hardware. W celu zademonstrowania potencjalnych możliwości tego produktu, udostępniliśmy "Scenariusz Prezentacyjny II".

Wszystkie scenariusze wymagają wykorzystania maszyn wirtualnych dostępnych w środowisku. Aby uzyskać zdalny dostęp do maszyny należy:

1) Znaleźć daną maszynę na mapie na Stronie Głównej i nacisnąć jej ikonę lub w przejść do zakładki "Maszyny",
2) Wybrać opcję "Konsola", następnie "Okno" lub "Nowa karta",
3) Pojawi się zdalny dostęp do ekranu maszyny, z której można normalnie korzystać.

Dane logowania do wszystkich maszyn są podane w dwóch miejscach:

- Opisie maszyn (widocznym po kliknięciu ikony danej maszyny lub w zakładce "Maszyny"),
- Materiałach PDF dołączonych do scenariuszy.

Uwaga! Pełna wersja HackingDept Cloud oraz HackingDept BOX, daje możliwość bezpośredniego połączenia się z sieciami wewnętrznymi HD za pomocą VPN lub WiFi (tylko BOX) i wykorzystania własnego komputera do przeprowadzenia ataków.

W przypadku wersji Demo, dla większości scenariuszy głównym punktem startowym jest maszyna "Attacker's Kali", dostępna z Dashboardu.

Jeżeli materiały wspominają o konieczności połączenia się z daną siecią lub doinstalowaniem do naszej przeglądarki dodatkowych certyfikatów, należy je zignorować (Attacker's Kali jest już w pełni skonfigurowany).

Co zrobić, gdy coś nie działa?

Możliwe jest przywracanie maszyn do stabilnego punktu wyjściowego, wybierając w widoku maszyny opcję "Przywróć".
Jeżeli użytkownik chce przywrócić całe środowisko (cofnąć wszystkie wprowadzone przez siebie zmiany), w zakładce "Maszyny" istnieje przycisk "Przywróć środowisko".

Po wykonaniu scenariusza, możliwe jest zweryfikowanie swojej wiedzy, odpowiadając na pytania w zakładce "Testy".
Udzielenie poprawnych odpowiedzi sprawi, że użytkownik otrzyma punkty, które wyświetlą się w zakładce "Ranking".`

    return (
      <div>
        <Modal
          visible={visible}
          title={messageTypes[language].instruction}
          onCancel={this.handleCancel}
          footer={null}
          className='instruction-modal'
        >
          <ReactMarkdown>
            {content}
          </ReactMarkdown>
        </Modal>
      </div>
    )
  }
}

export default InstructionModal
