import React, { Component } from 'react'
import StyledMapNodeModal from '../../../styledComponents/sections/home/mapNodeModal'

class MapNodeModal extends Component {
  onHandleClose = (e) => {
    this.props.toggleModal(false, null)
  }

  render () {
    const { element, language, visible } = this.props

    return (
      (element)
        ? (
          <StyledMapNodeModal
            element={element}
            language={language}
            visible={visible}
            handleClose={this.onHandleClose}
          />
          )
        : ''
    )
  }
}

export default MapNodeModal
