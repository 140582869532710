import React, { Component } from 'react'
import { Layout, Menu } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { pathNames, settingPathNames, messageTypes } from '../../actions/messages'
import { renderIcon } from '../../actions/helpers'
import config, { views } from '../../config/'
import CtfNavbarClock from '../../components/common/ctfNavbarClock'

const { Sider } = Layout
const SubMenu = Menu.SubMenu

class Navbar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      collapsed: false,
      screenWidth: null,
      settingsOpened: false
    }
  }

  componentDidMount () {
    this.updateWindowDimensions()
    window.addEventListener('resize', () => { this.updateWindowDimensions() })
  }

  updateWindowDimensions () {
    if (window.innerWidth < 768) {
      this.handleCollapse(true)
    } else {
      this.handleCollapse(false)
    }
    // this.setState({ screenWidth: window.innerWidth });
  }

  handleCollapse = (collapsed) => {
    this.setState({ collapsed })

    if (this.state.settingsOpened) {
      this.setState({ openedBeforeCollapse: true })
    } else if (this.state.openedBeforeCollapse) {
      this.setState({ settingsOpened: true, openedBeforeCollapse: false })
    }
  }

  setSelected () {
    const path = window.location.pathname
    let returnPath = '0'
    pathNames.forEach((val, i) => {
      if (path === val.path) {
        returnPath = i.toString()
      }
    })

    if (path === '/settings') {
      returnPath = '6'
    } else if (path === '/settings/users') {
      returnPath = '7'
    } else if (path === '/settings/scenarios') {
      returnPath = '8'
    } else if (path === '/settings/machines') {
      returnPath = '9'
    } else if (path === '/settings/quiz') {
      returnPath = '10'
    }
    return returnPath
  }

  render () {
    const { language, auth, stack } = this.props
    if (!language) {
      return (<div />)
    }
    const roleSuperAdmin = (auth === views.sa)
    const roleAdmin = (auth === views.ad)
    const roleUser = (auth === views.us || auth === views.ucr)
    const roleUserRankingOnly = (auth === views.ur)

    return (
      <Sider
        collapsible
        collapsed={this.state.collapsed}
        onCollapse={this.handleCollapse}
        trigger={<div className='bottom-logo'><img alt='Logo light' src={'/images/' + ((config.app_logo_menu && 'customization/' + config.app_logo_menu) || 'logo_light.png')} /></div>}
      >
        {stack && stack.is_ctf && <CtfNavbarClock className={'ctf-clock-navbar' + (this.state.settingsOpened || this.state.collapsed ? ' d-none' : '')} />}

        <Menu
          defaultSelectedKeys={[this.setSelected()]}
          defaultOpenKeys={[parseInt(this.setSelected()) > 6 ? 'sub-settings' : '']}
          mode='inline'
          theme='dark'
          onOpenChange={(x) => this.setState({ settingsOpened: x?.includes('sub-settings') })}
        >
          {
            pathNames.map((val, i) =>
              (((val.authOnly && auth) ||
                 (val.authOnly === false) ||
                 (val.authUserRankingOnly && roleUserRankingOnly) ||
                 (val.authUser && (roleSuperAdmin || roleAdmin || roleUser))) &&
                   (
                     (!val.hideOnCtf && val.ctfType === undefined) || // when hideOnCtf is false and ctfType is not defined
                     (!roleUserRankingOnly && val.showOnCtf && stack && stack.ctf_type === val.ctfType) || // when showOnCtf is true and ctf_type of stack matching ctfType of menu item
                     (stack && !stack.is_ctf && !val.showOnCtf) || // when stack is non-ctf and showOnCtf is false
                     (roleSuperAdmin && val.hideOnCtf === true) // when user is admin or super admin and menu item was marked to hide on ctf stack
                   )
              )
                ? (
                  <Menu.Item key={i.toString()}>
                    <Link to={val.path}>{renderIcon(val.ico)} <span>{messageTypes[language][val.name]}</span></Link>
                  </Menu.Item>
                  )
                : ''
            )
          }
          {(roleSuperAdmin || roleAdmin) ? <Menu.Divider className='admin-divider' key='admin-divider' /> : ''}
          {(roleSuperAdmin || roleAdmin)
            ? (
              <SubMenu
                key='sub-settings' title={
                  roleSuperAdmin
                    ? (
                      <span>
                        <Link to='/settings'>
                          <SettingOutlined /> <span>{messageTypes[language].menu_settings}</span>
                        </Link>
                      </span>
                      )
                    : <span className='option-active'><SettingOutlined /> <span>{messageTypes[language].menu_settings}</span></span>
                }
              >
                {
                  settingPathNames.map((val, i) =>
                    (val.authAdmin && (roleSuperAdmin || roleAdmin)) || (val.authSuperAdmin && roleSuperAdmin)
                      ? (
                        <Menu.Item key={`${i + 7}`}>
                          <Link to={val.path}>{renderIcon(val.ico)} <span>{messageTypes[language][val.name]}</span></Link>
                        </Menu.Item>
                        )
                      : ''
                  )
                }
              </SubMenu>
              )
            : ''}
        </Menu>
      </Sider>
    )
  }
}

export default Navbar
