import React from 'react'
import { messageTypes } from '../../../../actions/messages'
import {
  Form, Select, Input, Button, Divider
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import ResourceFilesFormComponent from '../../../common/filesWithUploadForm'

const { Option } = Select
const { TextArea } = Input

class StyledMapObjectsForm extends ResourceFilesFormComponent {
  formRef = React.createRef()

  componentDidUpdate (prevProps, prevState) {
    const { data, isNewRecord, contentPackVersions } = this.props

    if (data._id !== prevProps.data._id || isNewRecord !== prevProps.isNewRecord) {
      this.setDefaultValues()
    }

    if (data._id !== prevProps.data._id || data === null) {
      this.setState({ fromDisabled: false })
    }

    if (contentPackVersions?.length > prevProps.contentPackVersions?.length) {
      const fromDisabled = contentPackVersions?.find(x => x.name === data.content_pack_version)?.status === 'final'

      this.setState({ fromDisabled })
    }
  }

  prepareFormValues (values) {
    return {
      name: { pl: values.name_pl, en: values.name_en },
      desc: { pl: values.desc_pl, en: values.desc_en },
      map_id: values.map_id,
      stack_type: values.stack_type,
      content_pack_version: values.content_pack_version
    }
  }

  prepareFormExistingValues (data) {
    return {
      name_pl: data.name?.pl,
      name_en: data.name?.en,
      desc_pl: data.desc?.pl,
      desc_en: data.desc?.en,
      map_id: data.map_id,
      stack_type: data.stack_type,
      content_pack_version: data.content_pack_version
    }
  }

  prepareFormDefaultValues () {
    return {
      name_pl: '',
      name_en: '',
      desc_pl: '',
      desc_en: '',
      map_id: '',
      stack_type: '',
      content_pack_version: undefined
    }
  }

  handleStackTypeChange = (stackType) => {
    this.setState({ stackType })

    this.props.getContentPackVersions(stackType)
  }

  addFile = (challengeId, callAfterCreate) => {
    const { addFile } = this.props
    addFile && addFile(challengeId).then(result => callAfterCreate(result))
  }

  removeSingleFile = (challengeId, lang, callAfterCreate) => {
    const { removeSingleFile } = this.props
    removeSingleFile && removeSingleFile(challengeId, lang).then(result => callAfterCreate && callAfterCreate(result))
  }

  removeResource = (challengeId, callAfterCreate) => {
    const { removeResource } = this.props
    removeResource && removeResource(challengeId).then(result => callAfterCreate && callAfterCreate(result))
  }

  adminCopyResourceFileToLang = (id, lang, dstLang) => {
    this.props.adminCopyMapObjectResourceFileToLang(id, lang, dstLang)
  }

  render () {
    const { language, loading, data } = this.props
    const { fromDisabled } = this.state

    return (
      <Form onFinish={this.handleSubmit} layout='horizontal' ref={this.formRef}>

        <Form.Item
          name='name_pl'
          label={messageTypes[language].name_pl}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <Input disabled={fromDisabled} />
        </Form.Item>

        <Form.Item
          name='name_en'
          label={messageTypes[language].name_en}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <Input disabled={fromDisabled} />
        </Form.Item>

        <Form.Item
          name='desc_pl'
          label={messageTypes[language].desc_pl}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <TextArea disabled={fromDisabled} rows={6} />
        </Form.Item>

        <Form.Item
          name='desc_en'
          label={messageTypes[language].desc_en}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <TextArea disabled={fromDisabled} rows={6} />
        </Form.Item>

        <Form.Item
          name='map_id'
          label={messageTypes[language].map_id}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <Input disabled={fromDisabled} />
        </Form.Item>

        <Form.Item
          name='stack_type'
          label={messageTypes[language].stack_type}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <Select onChange={this.handleStackTypeChange} disabled={fromDisabled}>
            {
              this.props.overlordStackTypes.length
                ? this.props.overlordStackTypes.map((stackType, n) =>
                  <Option key={n} value={stackType.name}>{stackType.name}</Option>
                )
                : ''
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='content_pack_version'
          label={messageTypes[language].content_pack_version}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <Select disabled={fromDisabled}>
            {this.props.contentPackVersions && this.props.contentPackVersions.length && this.props.contentPackVersions.filter(x => !fromDisabled ? x.status === 'draft' : true).map((version, i) =>
              <Option key={i} value={version.name}>{version.name}</Option>)}
          </Select>
        </Form.Item>

        {this.fileFieldsRender('map_objects', data.map_object_hd_id, 'mapobjects', fromDisabled)}

        <Form.Item className='form-footer'>
          <Divider />
          <Button htmlType='submit'>{loading ? <LoadingOutlined /> : ''} {messageTypes[language].submit}</Button>
          <Button onClick={() => { this.props.onCancel() }} className='cancel'>{messageTypes[language].cancel}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StyledMapObjectsForm
