import React, { Component } from 'react'
import { connect } from 'react-redux'
import { messageTypes } from '../../actions/messages'

class NoMatch extends Component {
  render () {
    return (
      <h1 className='text-404'>{messageTypes[this.props.language].page_not_found_error}</h1>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language
})

const NoMatchContainer = connect(
  mapStateToProps
)(NoMatch)

export default NoMatchContainer
