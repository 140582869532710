import React, { Component } from 'react'
import { Menu, Dropdown, Button, Tag } from 'antd'
import { DatabaseOutlined, DownOutlined, LoadingOutlined } from '@ant-design/icons'
import { messageTypes } from '../../actions/messages'

class StyledStackSwitchDropdown extends Component {
  render () {
    const { stacks, currentStackId, switching, switchingTo, language, switchStack } = this.props

    const menu = (
      <Menu className='stack-switch'>
        {
          stacks && stacks.length && stacks.map((stack, n) =>
            (
              <Menu.Item
                key={n}
                onClick={() => (!stack.demo || stack.demo === 'partial') && stack._id !== currentStackId && switchStack(stack)}
                className={
                  (stack.demo === 'full' && 'stack-unavailable') ||
                  (stack._id === currentStackId && 'stack-current')
                }
              >
                <span className='stack-name'>{stack.name}
                  {
                    (
                      (stack.demo === 'full' && ' (' + messageTypes[language].stack_unavailable + ')') ||
                      (stack._id === currentStackId && ' (' + messageTypes[language].current + ')')
                    )
                  }
                </span>
                {stack.status && (
                  <Tag className={'ant-tag-red' + (stack.status === 'poweredon' ? ' status-on' : '')}>
                    {messageTypes[language]['stack_status_' + stack.status]}
                  </Tag>)}
              </Menu.Item>)
          )
        }
      </Menu>
    )

    return (
      stacks && stacks.length && stacks.length > 0
        ? (
          <Dropdown overlay={menu} disabled={switching}>
            <Button>
              <DatabaseOutlined /> {switching ? messageTypes[language].switching_stack + ' ' + switchingTo.name : messageTypes[language].switch_stack} {switching ? <LoadingOutlined /> : <DownOutlined />}
            </Button>
          </Dropdown>
          )
        : false
    )
  }
}

export default StyledStackSwitchDropdown
