import React, { Component } from 'react'
import { Modal, Button, Divider } from 'antd'
import { messageTypes } from '../../../actions/messages'
import StyledFilesDownloader from '../../../styledComponents/common/filesDownloader'
import SubmitFlagForm from '../home/submitFlagForm'
import ReactMarkdown from 'react-markdown'
import dateformat from 'dateformat'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { CheckCircleOutlined } from '@ant-design/icons'

class StyledChallengeModal extends Component {
  componentDidUpdate () {
    this._scrollBarRef._container.scrollTop = 0
    this._scrollBarRef.updateScroll()
  }

  render () {
    const {
      element, language, modalVisible, onHandleClose, downloadChallengeFile,
      solves, resources
    } = this.props

    return (
      <Modal
        title={
          <>
            {element.name[language]} ({element.score}) {element.done && <CheckCircleOutlined />} {element.solve_expired && <img src='/images/icons/hourglass_strike.svg' alt='expired-icon' className='challenge-solve-expired-icon' />}
          </>
        }
        visible={modalVisible}
        onCancel={onHandleClose}
        closable={false}
        className='challenge-modal'
        footer={[
          <Button
            key='back'
            onClick={onHandleClose}
          >
            {messageTypes[language].return}
          </Button>
        ]}
      >
        <div className='challenge-modal-body'>
          <PerfectScrollbar ref={(ref) => { this._scrollBarRef = ref }} style={{ padding: '24px' }}>
            <div className='challenge-icon'>
              <span>{element.challenge_category.name[language]}</span> <img src={'/images/icons/' + element.level + 'stars_clean.svg'} alt={element.level} />
            </div>

            <div className='challenge-description'>
              <ReactMarkdown>
                {element.desc && element.desc[language]}
              </ReactMarkdown>
            </div>

            {resources && resources.length > 0 &&
              <div className='challenge-files'>
                <StyledFilesDownloader
                  language={language}
                  element={{ challenge_hd_id: element.challenge_hd_id }}
                  files={resources}
                  customLabel={messageTypes[language].files}
                  downloadFile={downloadChallengeFile}
                  modal
                  useFileId
                />
              </div>}

            <Divider>
              {messageTypes[language].submit_flag}
            </Divider>

            {element.solve_time_limit && !element.solve_expired &&
              <div style={{ marginBottom: '20px' }}>{messageTypes[language].solve_time_will_expire_at}: {element.solve_end_date}</div>}

            {element.solve_expired && <div style={{ marginBottom: '20px' }}>{messageTypes[language].time_to_solve_expired}</div>}

            <SubmitFlagForm
              language={language}
              submitFlag={this.props.submitFlag}
            />

            <Divider>
              {messageTypes[language].solves} ({(solves && solves.length) || 0})
            </Divider>
            <div>
              {
                solves && solves.length > 0 && solves.map((val, i) => (
                  <div key={i}>{val.username} ({dateformat(val.solve_date, 'yyyy-mm-dd HH:MM:ss')})<br /></div>
                ))
              }
              {!solves || solves.length === 0 ? messageTypes[language].no_solves : ''}
            </div>
          </PerfectScrollbar>
        </div>
      </Modal>
    )
  }
}

export default StyledChallengeModal
