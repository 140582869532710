import React from 'react'
import { Button } from 'antd'
import MapObjectModal from './mapObjectModal'
import { messageTypes } from '../../../actions/messages'
import { openNotificationPopup, getCookie, slugify } from '../../../actions/helpers'
import { userStackVpnDownload } from '../../../actions'
const FileDownload = require('js-file-download')

class VpnModal extends MapObjectModal {
  prepareFilename = (element, stack, filename) => {
    return element && stack && element.network_name ? 'hd-' + element.network_name + '-' + slugify(stack.name) + '.ovpn' : 'vpn-hackingdept.ovpn'
  }

  downloadVpn (vpn, stack) {
    userStackVpnDownload(vpn._id, getCookie('_token')).then((response) => {
      const filename = vpn && stack && vpn.network_name ? 'hd-' + vpn.network_name + '-' + slugify(stack.name) + '.ovpn' : 'vpn-hackingdept.ovpn'

      if (response.status === 200) {
        // download file
        FileDownload(response.data, filename)
      } else {
        openNotificationPopup(messageTypes[this.props.language].oops, messageTypes[this.props.language].vpn_download_err, 'frown')
      }
    })
  }

  getFileDownloadElement = (element) => {
    const { language } = this.props

    return (
      !element.disabled &&
        <Button style={{ float: 'left' }} key='download' onClick={() => { this.downloadVpn(element, this.props.stack) }}>{messageTypes[language].download_conf}</Button>
    )
  }

  setMachineCardTitle = (element) => {
    const { language } = this.props
    return element.name && element.name[language]
  }
}

export default VpnModal
