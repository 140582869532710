import React from 'react'
import { messageTypes } from '../../../../actions/messages'
import {
  Form, Input, Button, Divider
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

class StyledContentPackForm extends React.Component {
  formRef = React.createRef()

  handleSubmit = (values) => {
    const { data, isNewRecord, addRecord, cloneVersion } = this.props
    const obj = {}
    obj.name = values.name
    obj.src_version = data.name
    obj.stack_type = this.props.stackType

    if (isNewRecord) {
      addRecord(obj)
    } else { cloneVersion(obj) }
  }

  setDefaultValues () {
    this.formRef.current.setFieldsValue({
      name: ''
    })
  }

  componentDidMount () {
    this.setDefaultValues()
  }

  componentDidUpdate (prevProps, prevState) {
    const { data, isNewRecord } = this.props

    if (data._id !== prevProps.data._id || isNewRecord !== prevProps.isNewRecord) {
      this.setDefaultValues()
    }
  }

  render () {
    const { language, loading } = this.props

    return (
      <Form onFinish={this.handleSubmit} layout='horizontal' ref={this.formRef}>
        <Form.Item
          name='name'
          label={messageTypes[language].name_name}
          rules={[
            { required: true, message: messageTypes[language].field_required },
            {
              pattern: /^[a-zA-Z0-9._-]+$/,
              message: messageTypes[language].field_version_name_invalid
            }
          ]}
          {...formItemLayout}
        >
          <Input />
        </Form.Item>

        <Form.Item className='form-footer'>
          <Divider />
          <Button htmlType='submit' disabled={loading}>{loading ? <LoadingOutlined /> : ''} {messageTypes[language].submit}</Button>
          <Button disabled={loading} onClick={() => { this.props.onCancel() }} className='cancel'>{messageTypes[language].cancel}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StyledContentPackForm
