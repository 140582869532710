import React from 'react'
import { Modal } from 'antd'
import { messageTypes } from '../../../../actions/messages'
import { takeElementWithIdFromArr, openNotificationPopup, getCookie } from '../../../../actions/helpers'
import StyledMachinesForm from '../../../../styledComponents/sections/settings/machines/machinesForm'
import { machinesGetVmwareForStackType, adminGetContentPacksForStackType, stackTypesOverlordGet, adminCopyMachineResourceFileToLang } from '../../../../actions/'

class MachinesModalSettings extends React.Component {
  state = {
    singleData: {},
    singleDataVmware: undefined,
    machines: undefined,
    contentPackVersions: []
  }

  handleCancel = () => {
    const { setDataId, showAddRecordModal } = this.props
    setDataId(null)
    showAddRecordModal(false)
    this.setState({
      contentPackVersions: []
    })
  }

  getContentPackVersions = (stackType) => {
    const { language } = this.props

    adminGetContentPacksForStackType(stackType, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            contentPackVersions: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  adminCopyMachineResourceFileToLang = (id, lang, dstLang) => {
    const { language } = this.props

    adminCopyMachineResourceFileToLang(id, lang, dstLang, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          openNotificationPopup(messageTypes[language].success, json.response[language], 'smile')
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  getStackTypesFromOverlord = () => {
    const { language } = this.props
    stackTypesOverlordGet(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            overlordStackTypes: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }

        this.setState({
          loading: false
        })
      }
    })
  }

  componentDidUpdate (prevProps, prevState) {
    const { dataId, data } = this.props
    if (prevProps.dataId !== dataId) {
      if (dataId === null) {
        this.setState({ singleData: null, singleDataVmware: undefined, machines: undefined })
      } else {
        const singleData = takeElementWithIdFromArr(dataId, data)

        if (singleData) {
          this.getContentPackVersions(singleData.stack_type)
        }

        if (singleData?.stack_type) {
          this.getMachinesForStackTypes(singleData?.machine_name, [singleData?.stack_type])

          this.setState({ singleData })
        } else {
          this.setState({ singleData, machines: [] })
        }
      }
    }
  }

  getMachinesForStackTypes = (machineName, stackTypes) => {
    const promises = []

    for (const stackType of stackTypes) {
      promises.push(this.getMachinesVmwareForStackType(stackType))
    }

    Promise.all(promises).then((values) => {
      const machines = values.flat()
      const singleDataVmware = machines.find(x => x.name.toLowerCase() === machineName)

      this.setState({
        machines,
        singleDataVmware
      })
    })
  }

  componentDidMount () {
    this._isMounted = true

    this.getStackTypesFromOverlord()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  getMachinesVmwareForStackType = (stackType) => {
    const { language } = this.props

    return new Promise(function (resolve, reject) {
      machinesGetVmwareForStackType(getCookie('_token'), stackType).then((json) => {
        if (json.status === 'ok') {
          resolve(json.response)
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
          reject(new Error('cannot get machines for stack type'))
        }
      })
    })
  }

  render () {
    const {
      language, dataId, isNewRecord, loading,
      updateRecord, addRecord, addFile, resources, removeSingleFile, removeResource
    } = this.props
    const { singleData, singleDataVmware, machines } = this.state
    return (
      <div>
        <Modal
          maskClosable={false}
          width={1000}
          visible={!!(dataId || isNewRecord)}
          title={
            (isNewRecord && messageTypes[language].new_machine) ||
            (
              singleData &&
              singleData.name &&
              singleData.name[language])
          }
          onCancel={this.handleCancel}
          footer={null}
        >
          <StyledMachinesForm
            machines={machines}
            isNewRecord={isNewRecord}
            data={singleData || {}}
            singleDataVmware={singleDataVmware}
            language={language}
            onCancel={this.handleCancel}
            updateRecord={updateRecord}
            addRecord={addRecord}
            loading={loading}
            addFile={addFile}
            removeSingleFile={removeSingleFile}
            removeResource={removeResource}
            resources={resources}
            getMachinesForStackTypes={this.getMachinesForStackTypes}
            contentPackVersions={this.state.contentPackVersions}
            getContentPackVersions={this.getContentPackVersions}
            overlordStackTypes={this.state.overlordStackTypes}
            adminCopyMachineResourceFileToLang={this.adminCopyMachineResourceFileToLang}
          />
        </Modal>
      </div>
    )
  }
}

export default MachinesModalSettings
