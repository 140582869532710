import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card } from 'antd'
import { setChallengeModalVisible } from '../../../store/actions'
import { CheckCircleOutlined } from '@ant-design/icons'
import { messageTypes } from '../../../actions/messages'

class ChallengeCard extends Component {
  modalPopup = (challenge) => {
    this.props.setChallengeModalVisible(true)
    this.props.setChallenge(challenge)
  }

  render () {
    const { language, element } = this.props

    return (
      <div>
        <Card
          className={'hoverable challenge' + (element.done ? ' challenge-done' : '') + (element.solve_expired ? ' challenge-solve-expired' : '')}
          onClick={() => this.modalPopup(element)}
        >
          {element.done && <CheckCircleOutlined className='challenge-done-icon' />}
          {element.solve_expired && <img src='/images/icons/hourglass_strike.svg' alt='expired-icon' className='challenge-solve-expired-icon' />}

          <div className='challenge-icon'>
            <img src={'/images/icons/' + element.level + 'stars_clean.svg'} alt={element.level} />
          </div>

          <div className='challenge-name-container'>
            <div className='challenge-name'>
              <p>{element.name[language]}</p>
              <p>{element.score} {messageTypes[language].points}</p>
            </div>
          </div>

          <div className='challenge-solved-count'>{messageTypes[language].solves2}: {element.solves}</div>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = {
  setChallengeModalVisible
}

const ChallengeCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeCard)

export default ChallengeCardContainer
