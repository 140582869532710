import React, { Component } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

class TagsDisplayer extends Component {
  componentDidMount () {
    this._scrollBarRef.updateScroll()
  }

  render () {
    const { tags, modal } = this.props
    return (
      <PerfectScrollbar ref={(ref) => { this._scrollBarRef = ref }} className={modal ? 'scroll-tags-modal' : ''}>
        <div className={`tags${modal ? ' tags-modal' : ''}`}>
          {
            tags.map((val, i) => '#' + val + '  ')
          }
        </div>
      </PerfectScrollbar>
    )
  }
}

export default TagsDisplayer
