import React, { Component } from 'react'
import LoaderImage from './loaderImage'
// import Loader from 'react-loader-spinner';

class StyledLoader extends Component {
  render () {
    return (
      <div className={this.props.fadeOut ? 'fade-out' : (this.props.fadeIn ? 'fade-in' : '')}>
        <LoaderImage />
      </div>
    )
  }
}

export default StyledLoader
