import React, { Component } from 'react'
import { Layout } from 'antd'
import config from '../../config/'
const { Footer } = Layout

class Ftr extends Component {
  render () {
    return (
      <Footer style={{ fontWeight: 'bold', textAlign: 'center' }}>
        {config.app_footer?.split('\n').map((line, i) => <React.Fragment key={i}>{line}<br /></React.Fragment>) || 'HackingDept ©2023'}
      </Footer>
    )
  }
}

export default Ftr
