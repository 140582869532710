import React, { Component } from 'react'

class StyledDownloadButtons extends Component {
  render () {
    return (
      <div />
    )
  }
}

export default StyledDownloadButtons
