import React, { Component } from 'react'
import { Modal, Button } from 'antd'
import { messageTypes } from '../../../actions/messages'

class StyledMapNodeModal extends Component {
  render () {
    const { element, language, visible, handleClose } = this.props

    return (
      <Modal
        title={element.label}
        zIndex={1000}
        visible={visible}
        closable={false}
        className='map-modal'
        footer={[
          <Button key='return' type='danger' onClick={() => handleClose()}>{messageTypes[language].close}</Button>
        ]}
      >
        {element && element.description
          ? (
            <p>{element.description}</p>
            )
          : ''}
        {element && element.flags
          ? (
            <>
              {element.flags.map((val, i) => (
                <div key={i} className={val.done ? 'success' : ''}>{val.name} ({val.current_value})</div>
              ))}
              <div>{messageTypes[language].done}: {element.flags_done} / {element.flags.length}</div>
            </>
            )
          : ''}
      </Modal>
    )
  }
}

export default StyledMapNodeModal
