import React from 'react'
import { Modal } from 'antd'
import { messageTypes } from '../../../../actions/messages'
import { takeElementWithIdFromArr } from '../../../../actions/helpers'
import StyledChallengeForm from '../../../../styledComponents/sections/settings/contentPack/form'

class ContentPackModal extends React.Component {
  state = {
    singleData: {}
  }

  handleCancel = () => {
    const { setDataId, showAddRecordModal } = this.props
    setDataId(null)
    showAddRecordModal(false)
  }

  componentDidUpdate (prevProps, prevState) {
    const { dataId, data, stackType } = this.props
    const contentPack = data?.find(x => x.stack_type === stackType)

    if (prevProps.dataId !== dataId) {
      const singleData = takeElementWithIdFromArr(dataId, contentPack?.installed_versions)
      this.setState({ singleData })
    }
  }

  render () {
    const {
      language, dataId, isNewRecord, loading, cloneVersion, addRecord
    } = this.props
    const { singleData } = this.state

    return (
      <div>
        <Modal
          maskClosable={false}
          width={1000}
          visible={!!(dataId || isNewRecord)}
          title={messageTypes[language].new_version}
          onCancel={this.handleCancel}
          footer={null}
        >
          <StyledChallengeForm
            isNewRecord={isNewRecord}
            dataId={dataId}
            data={singleData || {}}
            language={language}
            onCancel={this.handleCancel}
            cloneVersion={cloneVersion}
            addRecord={addRecord}
            loading={loading}
            stackType={this.props.stackType}
          />
        </Modal>
      </div>
    )
  }
}

export default ContentPackModal
