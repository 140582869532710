import React, { Component } from 'react'
import { connect } from 'react-redux'
import StyledScenariosTableSettings from '../../../../styledComponents/sections/settings/scenarios/scenariosTable'
import AddRecordButton from '../../../../styledComponents/common/addRecordButton'
import ScenariosModalSettings from './scenariosModal'
import {
  openNotificationPopup,
  getCookie,
  deleteElementWithIdFromArr,
  confirm
} from '../../../../actions/helpers'

import {
  machinesGetVmware,
  scenariosGetBuilder,
  scenariosCreateBuilder,
  scenariosUpdateBuilder,
  scenariosDeleteBuilder,
  adminCreateScenarioResource,
  adminGetScenarioResources,
  adminDeleteScenarioResourceFile,
  adminDeleteScenarioResource,
  adminGetContentPacks
} from '../../../../actions/'
import { CheckOutlined } from '@ant-design/icons'

import { messageTypes } from '../../../../actions/messages'

class ScenariosSettings extends Component {
  state = {
    machines: [],
    scenarios: [],
    resources: [],
    data: [],
    dataId: null,
    loading: false,
    isNewRecord: false,
    contentPacks: []
  }

  componentDidMount () {
    this._isMounted = true
    this.getContentPacks()
    this.getAllData()
  }

  getScenarioResources = (id) => {
    const { language } = this.props
    const _this = this

    return new Promise(function (resolve, reject) {
      adminGetScenarioResources(id, getCookie('_token')).then((json) => {
        if (_this._isMounted) {
          if (json.status === 'ok') {
            _this.setState({
              resources: json.response
            }, () => resolve())
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
            reject(new Error('get of challenge resources failed'))
          }
        }
      })
    })
  }

  getContentPacks = () => {
    const { language } = this.props

    adminGetContentPacks(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            contentPacks: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  setDataId = (dataId) => {
    if (dataId !== null) {
      this.getScenarioResources(dataId).then(() => {
        this.setState({ dataId })
      })
    } else {
      this.setState({ dataId })
    }
  }

  showAddRecordModal = (flag) => {
    if (flag) {
      this.addRecord({ name: { pl: 'Draft', en: 'Draft' } })
    }
  }

  getAllData = () => {
    const { language } = this.props
    const _this = this

    return new Promise(function (resolve, reject) {
      scenariosGetBuilder(getCookie('_token')).then((json) => {
        if (_this._isMounted) {
          if (json.status === 'ok') {
            _this.setState({
              data: json.response
            }, () => resolve())
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
            reject(new Error('get of scenarios failed'))
          }
        }
      })
      machinesGetVmware(getCookie('_token')).then((json) => {
        if (_this._isMounted) {
          if (json.status === 'ok') {
            _this.setState({
              machines: json.response
            })
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
          }
        }
      })
    })
  }

  deleteRecord = (id) => {
    const { language } = this.props

    confirm('delete_scenario_question', language, () => {
      scenariosDeleteBuilder(id, getCookie('_token')).then((json) => {
        if (this._isMounted) {
          if (json.status === 'ok') {
            this.setState({ data: deleteElementWithIdFromArr(id, this.state.data) })
            openNotificationPopup(
              messageTypes[language].success,
              json.response[language],
              'smile'
            )
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[this.props.language].oops, json.response[language], 'frown')
          }
        }
      })
    })
  }

  updateRecord = (id, data) => {
    const { language } = this.props
    this.setState({ loading: true })
    scenariosUpdateBuilder(id, data, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        this.setState({
          loading: false,
          dataId: null
        })
        if (json.status === 'ok') {
          openNotificationPopup(
            messageTypes[language].success,
            json.response[language],
            'smile'
          )
          this.getAllData()
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  addRecord = (data) => {
    const { language } = this.props
    this.setState({ loading: true })
    scenariosCreateBuilder(data, getCookie('_token')).then((json) => {
      this.setState({ loading: false })
      if (json.status === 'ok') {
        this.getAllData().then(() => this.setDataId(json.response._id))
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
      }
    })
  }

  addFile = (id) => {
    const { language } = this.props
    const _this = this
    this.setState({ loading: true })

    return new Promise(function (resolve, reject) {
      adminCreateScenarioResource(id, getCookie('_token')).then((json) => {
        _this.setState({ loading: false })
        if (json.status === 'ok') {
          resolve(json.response)
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
          reject(new Error('create of challenge failed'))
        }
      })
    })
  }

  removeSingleFile = (id, lang, callAfterCreate) => {
    const { language } = this.props

    return new Promise(function (resolve, reject) {
      adminDeleteScenarioResourceFile(id, lang, getCookie('_token')).then((json) => {
        if (json.status === 'ok') {
          openNotificationPopup(messageTypes[language].success, json.response[language], 'smile')
          resolve(json.response)
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
          reject(new Error('delete of challenge resources file failed'))
        }
      })
    })
  }

  removeResource = (id, callAfterCreate) => {
    const { language } = this.props

    return new Promise(function (resolve, reject) {
      adminDeleteScenarioResource(id, getCookie('_token')).then((json) => {
        if (json.status === 'ok') {
          openNotificationPopup(messageTypes[language].success, json.response[language], 'smile')
          resolve(json.response)
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
          reject(new Error('delete of scenario resource failed'))
        }
      })
    })
  }

  returnWithKey (data, lang) {
    const retArr = []
    data.forEach((val, i) => {
      const obj = {}
      obj.key = i
      obj._id = val._id
      obj.name = val.name && val.name[lang]
      obj.desc = val.desc && val.desc[lang]
      obj.desc_long = val.desc_long && val.desc_long[lang]
      obj.difficulty = val.difficulty
      obj.active_icon = val.active ? <CheckOutlined /> : ''
      obj.tags = val.tags
      obj.files = val.files
      obj.active = val.active
      obj.machines = val.machines
      obj.order = val.order
      obj.s_type = messageTypes[lang]['scenario_type_' + val.s_type]
      // obj.stack_types = val.stack_types?.join(', ')
      obj.stack_type = val.stack_type
      obj.content_pack_version = val.content_pack_version
      retArr.push(obj)
    })
    return retArr
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { data, dataId, isNewRecord, machines } = this.state
    const { language } = this.props
    return (
      <div>
        <AddRecordButton
          language={language}
          showAddRecordModal={this.showAddRecordModal}
          title={messageTypes[language].new_scenario}
        />
        <StyledScenariosTableSettings
          language={language}
          data={this.returnWithKey(data, language)}
          setDataId={this.setDataId}
          deleteRecord={this.deleteRecord}
          showAddRecordModal={this.showAddRecordModal}
          contentPacks={this.state.contentPacks}
        />
        <ScenariosModalSettings
          language={language}
          machines={machines}
          data={data}
          dataId={dataId}
          isNewRecord={isNewRecord}
          setDataId={this.setDataId}
          loading={this.state.loading}
          updateRecord={this.updateRecord}
          showAddRecordModal={this.showAddRecordModal}
          addRecord={this.addRecord}
          addFile={this.addFile}
          removeSingleFile={this.removeSingleFile}
          removeResource={this.removeResource}
          resources={this.state.resources}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language
})

const ScenariosSettingsContainer = connect(
  mapStateToProps
)(ScenariosSettings)

export default ScenariosSettingsContainer
