import React, { Component } from 'react'
import StyledRegister from '../../../styledComponents/sections/register/'
import { connect } from 'react-redux'

class Register extends Component {
  render () {
    return (
      <div>
        <StyledRegister
          language={this.props.language}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language
})

const RegisterContainer = connect(
  mapStateToProps
)(Register)

export default RegisterContainer
