import React from 'react'
import Terminal from './Terminal'
import { getTicket } from '../../../actions/'
import { getCookie, openNotificationPopup } from '../../../actions/helpers'
import { messageTypes } from '../../../actions/messages'
import { Modal, Button } from 'antd'
import { handleFullscreen, handleCtrlAltDel, openConsoleNewWindow } from './terminalHelpers.js'

export default class ConsoleEmulator extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      ticketData: null
    }
    this.consoleEmulatorRef = React.createRef()
  }

  getTicket (machineId) {
    const { language } = this.props
    if (machineId) {
      getTicket(machineId, getCookie('_token')).then((json) => {
        if (this._isMounted) {
          if (json.status === 'ok') {
            this.setState({ ticketData: json.response })
          } else if (json.status === 'err') {
            this.setState({ ticketData: null })
            openNotificationPopup(
              messageTypes[language].oops,
              messageTypes[language].machine_console_err,
              'frown',
              json.response[language] || json.response,
              language
            )
          }
        }
      })
    }
  }

  onHandleCancel = () => {
    this.props.onHandleConsole(false, false)
    this.setState({ ticketData: null })
  }

  componentDidMount () {
    this._isMounted = true
    const { machineId, insideMap } = this.props

    // get ticket on mount only when used in map
    if (insideMap) {
      this.getTicket(machineId)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { machineId, insideMap } = this.props

    // get ticket on component update only when not used in map
    if (machineId !== prevProps.machineId && !insideMap) {
      this.getTicket(machineId)
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  openConsoleNewWindow (machineId) {
    openConsoleNewWindow(machineId)
    this.onHandleCancel()
  }

  paste (consoleEmulatorRef) {
    navigator.clipboard.readText().then((res) => {
      consoleEmulatorRef.current.contentWindow.sendClipboard(res)
      consoleEmulatorRef.current.contentWindow.document.getElementById('mainCanvas').focus()
    })
  }

  render () {
    const { ticketData } = this.state
    const { language, machineId, machine } = this.props

    return (
      ticketData
        ? (
          <Modal
            className='esxi-terminal'
            title={machine !== undefined && machine.name[language] !== undefined ? machine.name[language] : 'Terminal'}
            visible={this.props.consoleOpen}
            onCancel={() => this.onHandleCancel()}
            footer={[
              <Button className='console-modal-functional-button' key='ctrl' onClick={() => { handleCtrlAltDel(this.consoleEmulatorRef) }}>{messageTypes[language].ctrl_alt_del}</Button>,
              <Button className='console-modal-functional-button' key='fullscreen' onClick={() => { handleFullscreen(this.consoleEmulatorRef) }}>{messageTypes[language].fullscreen}</Button>,
              navigator.userAgent.toLowerCase().indexOf('firefox') === -1 && <Button className='console-modal-functional-button' key='paste' onClick={() => { this.paste(this.consoleEmulatorRef) }}>{messageTypes[language].paste_from_clipboard}</Button>,
              <Button key='new_window' onClick={() => { this.openConsoleNewWindow(machineId) }}>{messageTypes[language].new_window}</Button>,
              <Button key='back' type='danger' onClick={() => { this.onHandleCancel() }}>{messageTypes[language].return}</Button>
            ]}
          >
            <Terminal
              consoleEmulatorRef={this.consoleEmulatorRef}
              ticketData={ticketData}
              language={language}
              handleCloseParentModal={this.onHandleCancel}
            />
          </Modal>)
        : ''
    )
  }
}
