import React from 'react'
import { setCtfState } from '../../store/actions'
import { connect } from 'react-redux'
import StyledCtfNavbarClock from '../../styledComponents/common/ctfNavbarClock'
import HDComponent from '../../components/common/hdComponent'

class CtfNavbarClock extends HDComponent {
  state = {
    showEndClock: false,
    ctfEndDate: undefined
  }

  componentDidUpdate (prevProps, prevState) {
    const { stack, ctfState } = this.props

    if (stack?.is_ctf && stack.ctf_time_limit) {
      if (prevProps.ctfState !== ctfState && ctfState === 'FINISHED') {
        this.setState({ showEndClock: false })
      }

      if (prevProps.ctfState !== ctfState && ctfState === 'BATTLE') {
        this.setState({ showEndClock: true, ctfEndDate: new Date(stack.ctf_end_date) })
      }
    }
  }

  endClockFinished = () => {
    this.props.setCtfState('FINISHED')
  }

  render () {
    return (
      <StyledCtfNavbarClock
        ctfEndDate={this.state.ctfEndDate}
        showEndClock={this.state.showEndClock}
        handleEndClockFinished={this.endClockFinished}
        language={this.props.language}
        className={this.props.className}
      />
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language,
  stack: state.hdStore.stack,
  ctfState: state.hdStore.ctfState
})

const mapDispatchToProps = {
  setCtfState
}

const CtfNavbarClockContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CtfNavbarClock)

export default CtfNavbarClockContainer
