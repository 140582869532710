import React from 'react'
import config from '../../config/'

class HDComponent extends React.Component {
  constructor (props) {
    super(props)
    this.languages = config.languages
  }

  determineLanguage = (language) => {
    return language !== undefined && language !== null ? language : (config.default_lang !== undefined ? config.default_lang : this.languages[0])
  }
}

export default HDComponent
