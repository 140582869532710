import React, { Component } from 'react'
import { connect } from 'react-redux'
import StyledMapEditor from '../../../../styledComponents/sections/settings/maps/editor'
import {
  openNotificationPopup,
  getCookie
} from '../../../../actions/helpers'
import {
  mapElementsGet,
  updateMapElements,
  adminGetFlagsForStackType,
  adminGetChallengesForStackType,
  adminGetContentPacksForStackType,
  adminGetScenariosForStackType
} from '../../../../actions/'

import { messageTypes } from '../../../../actions/messages'

class MapEditorSettings extends Component {
  state = {
    data: null,
    map: null,
    stackType: null,
    contentPackVersion: null,
    updated: 0,
    flags: [],
    challenges: [],
    scenarios: [],
    loading: true,
    contentPacks: [],
    editDisabled: true
  }

  componentDidMount () {
    const { stack_type: stackType, content_pack_version: contentPackVersion } = this.props.match.params
    this._isMounted = true

    this.getFlags(stackType)
    this.getChallenges(stackType)
    this.getScenarios(stackType, contentPackVersion)
    this.setState({ stackType, contentPackVersion })
    this.getContentPacks(stackType, () => this.loadMap(stackType, contentPackVersion))
  }

  getContentPacks = (stackType, callAfter) => {
    const { language } = this.props

    adminGetContentPacksForStackType(stackType, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          const editDisabled = json.response?.find(x => x.stack_type === this.state.stackType && x.name === this.state.contentPackVersion)?.status === 'final'
          this.setState({
            contentPacks: json.response,
            editDisabled
          }, callAfter)
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  setData = (data) => {
    this.setState({ data })
  }

  loadMap = (stackType, contentPackVersion) => {
    this.setState({
      map: { nodesData: [], edgesData: [] },
      loading: true
    })

    mapElementsGet(stackType, contentPackVersion, getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        if (this._isMounted) {
          this.setState({
            map: { nodesData: json.response.nodes, edgesData: json.response.edges, iconsData: json.response.icons },
            loading: false
          })
        }
      } else if (json.status === 'err') {
        openNotificationPopup(
          messageTypes[this.props.language].oops,
          json.response[this.props.language],
          'frown'
        )
      }
    })
  }

  getFlags = (stackType) => {
    adminGetFlagsForStackType(stackType, getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        if (this._isMounted) {
          this.setState({
            flags: json.response
          })
        }
      } else if (json.status === 'err') {
        openNotificationPopup(
          messageTypes[this.props.language].oops,
          json.response[this.props.language],
          'frown'
        )
      }
    })
  }

  getChallenges = (stackType) => {
    adminGetChallengesForStackType(stackType, getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        if (this._isMounted) {
          this.setState({
            challenges: json.response
          })
        }
      } else if (json.status === 'err') {
        openNotificationPopup(
          messageTypes[this.props.language].oops,
          json.response[this.props.language],
          'frown'
        )
      }
    })
  }

  getScenarios = (stackType, contentPackVersion) => {
    adminGetScenariosForStackType(stackType, contentPackVersion, getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        if (this._isMounted) {
          this.setState({
            scenarios: json.response
          })
        }
      } else if (json.status === 'err') {
        openNotificationPopup(
          messageTypes[this.props.language].oops,
          json.response[this.props.language],
          'frown'
        )
      }
    })
  }

  updateMapElements = () => {
    const { language } = this.props

    this.setState({ loading: true })

    updateMapElements(this.state.stackType, this.state.map, this.state.contentPackVersion, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.loadMap(this.state.stackType, this.state.contentPackVersion)

          openNotificationPopup(
            messageTypes[language].success,
            json.response[language],
            'smile'
          )
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[this.props.language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  updateMapData = (map) => {
    this.setState({ map })
  }

  updateElement = (id, formData) => {
    const { data } = this.state

    const entity = data?.entity
    entity.label = formData.label
    entity.group = formData.group
    entity.id = formData.id
    entity.x = formData.x
    entity.y = formData.y
    entity.height = formData.height
    entity.width = formData.width
    entity.roundness = formData.roundness
    entity.type = formData.type
    entity.title = formData.title
    entity.subtitle = formData.subtitle
    entity.subtitle2 = formData.subtitle2
    entity.position = formData.position
    entity.ip = formData.ip
    entity.ip2 = formData.ip2
    entity.network = formData.network
    entity.network2 = formData.network2
    entity.ip_style = formData.ip_style
    entity.ip2_style = formData.ip2_style
    entity.ip_public = formData.ip_public
    entity.network_public = formData.network_public
    entity.domain = formData.domain
    entity.dns = formData.dns
    entity.dns_public = formData.dns_public
    entity.sublabel = formData.sublabel
    entity.flags = formData.flags
    entity.attributes = formData.attributes
    entity.color = formData.color
    entity.rorder = formData.rorder
    entity.challenge = formData.challenge && this.state.challenges.find(x => x._id === formData.challenge)
    entity.scenario = formData.scenario && this.state.scenarios.find(x => x._id === formData.scenario)
    entity.visibility_flags = null

    this.incUpdated()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  incUpdated = () => {
    this.setState({ updated: this.state.updated + 1 })
  }

  render () {
    const { data } = this.state
    const { language } = this.props

    return (
      <div>
        <StyledMapEditor
          language={language}
          updated={this.state.updated}
          loading={this.state.loading}
          incUpdated={this.incUpdated}
          map={this.state.map}
          flags={this.state.flags}
          challenges={this.state.challenges}
          scenarios={this.state.scenarios}
          setData={this.setData}
          updateMapData={this.updateMapData}
          updateElement={this.updateElement}
          handleUpdateMapElements={this.updateMapElements}
          editDisabled={this.state.editDisabled}
          data={data}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language
})

const MapEditorSettingsContainer = connect(
  mapStateToProps
)(MapEditorSettings)

export default MapEditorSettingsContainer
