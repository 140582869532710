import React, { Component } from 'react'
import { connect } from 'react-redux'
import StyledMapIconsTableSettings from '../../../../styledComponents/sections/settings/mapIcons/table'
import AddRecordButton from '../../../../styledComponents/common/addRecordButton'
import MapIconModalSettings from './modal'
import {
  openNotificationPopup,
  getCookie,
  deleteElementWithIdFromArr,
  confirm
} from '../../../../actions/helpers'
import { PageHeader } from 'antd'

import {
  adminGetMapIcons,
  mapIconsCreateBuilder,
  mapIconsUpdateBuilder,
  mapIconsDeleteBuilder,
  adminCreateMapIconResource,
  adminGetMapIconResources,
  adminDeleteMapIconResourceFile,
  adminDeleteMapIconResource,
  adminGetContentPacks
} from '../../../../actions/'

import { messageTypes } from '../../../../actions/messages'

const crypto = require('crypto')

class MapIconsSettings extends Component {
  state = {
    mapIcons: [],
    resources: [],
    data: [],
    dataId: null,
    loading: false,
    isNewRecord: false,
    contentPacks: [],
    editDisabled: true
  }

  redirect = (route) => {
    this.props.history.push(route)
  }

  componentDidMount () {
    const { stack_type: stackType, content_pack_version: contentPackVersion } = this.props.match.params

    this._isMounted = true
    this.getAllData(stackType, contentPackVersion)
    this.getContentPacks()
    this.setState({ stackType, contentPackVersion })
  }

  getContentPacks = () => {
    const { language } = this.props

    adminGetContentPacks(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          const editDisabled = json.response?.find(x => x.stack_type === this.state.stackType)?.installed_versions?.find(x => x.name === this.state.contentPackVersion)?.status === 'final'

          this.setState({
            contentPacks: json.response,
            editDisabled
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  getMapIconResources = (id) => {
    const { language } = this.props
    const _this = this

    return new Promise(function (resolve, reject) {
      adminGetMapIconResources(id, getCookie('_token')).then((json) => {
        if (_this._isMounted) {
          if (json.status === 'ok') {
            _this.setState({
              resources: json.response
            }, () => resolve())
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
            reject(new Error('get of challenge resources failed'))
          }
        }
      })
    })
  }

  setDataId = (dataId) => {
    if (dataId !== null) {
      this.getMapIconResources(dataId).then(() => {
        this.setState({ dataId })
      })
    } else {
      this.setState({ dataId })
    }
  }

  showAddRecordModal = (flag) => {
    if (flag) {
      this.addRecord({ name: 'draft_' + crypto.randomBytes(4).toString('hex'), shape: 'image', size: 32, stack_type: this.state.stackType, content_pack_version: this.state.contentPackVersion })
    }
  }

  getAllData = (stackType, contentTypeVersion) => {
    const { language } = this.props
    const _this = this

    return new Promise(function (resolve, reject) {
      adminGetMapIcons(stackType, contentTypeVersion, getCookie('_token')).then((json) => {
        if (_this._isMounted) {
          if (json.status === 'ok') {
            _this.setState({
              data: json.response
            }, () => resolve())
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
            reject(new Error('get of map objects failed'))
          }
        }
      })
    })
  }

  deleteRecord = (id) => {
    const { language } = this.props

    confirm('delete_map_object_question', language, () => {
      mapIconsDeleteBuilder(id, getCookie('_token')).then((json) => {
        if (this._isMounted) {
          if (json.status === 'ok') {
            this.setState({ data: deleteElementWithIdFromArr(id, this.state.data) })
            openNotificationPopup(
              messageTypes[language].success,
              json.response[language],
              'smile'
            )
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[this.props.language].oops, json.response[language], 'frown')
          }
        }
      })
    })
  }

  updateRecord = (id, data) => {
    const { language } = this.props
    this.setState({ loading: true })
    mapIconsUpdateBuilder(id, data, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        this.setState({
          loading: false,
          data: []
        })
        if (json.status === 'ok') {
          openNotificationPopup(
            messageTypes[language].success,
            json.response[language],
            'smile'
          )
          this.getAllData(this.state.stackType, this.state.contentPackVersion)
          this.setState({
            dataId: null
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  addRecord = (data) => {
    const { language } = this.props
    this.setState({ loading: true })
    mapIconsCreateBuilder(data, this.state.stackType, this.state.contentPackVersion, getCookie('_token')).then((json) => {
      this.setState({ loading: false })
      if (json.status === 'ok') {
        this.getAllData(this.state.stackType, this.state.contentPackVersion).then(() => this.setDataId(json.response._id))
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
      }
    })
  }

  addFile = (id) => {
    const { language } = this.props
    const _this = this
    this.setState({ loading: true })

    return new Promise(function (resolve, reject) {
      adminCreateMapIconResource(id, getCookie('_token')).then((json) => {
        _this.setState({ loading: false })
        if (json.status === 'ok') {
          resolve(json.response)
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
          reject(new Error('create of challenge failed'))
        }
      })
    })
  }

  removeSingleFile = (id, lang, callAfterCreate) => {
    const { language } = this.props

    return new Promise(function (resolve, reject) {
      adminDeleteMapIconResourceFile(id, lang, getCookie('_token')).then((json) => {
        if (json.status === 'ok') {
          openNotificationPopup(messageTypes[language].success, json.response[language], 'smile')
          resolve(json.response)
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
          reject(new Error('delete of challenge resources file failed'))
        }
      })
    })
  }

  removeResource = (id, callAfterCreate) => {
    const { language } = this.props
    const _this = this

    return new Promise(function (resolve, reject) {
      adminDeleteMapIconResource(id, getCookie('_token')).then((json) => {
        if (json.status === 'ok') {
          _this.getMapIconResources(_this.state.dataId)
          openNotificationPopup(messageTypes[language].success, json.response[language], 'smile')
          resolve(json.response)
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
          reject(new Error('delete of map object resource failed'))
        }
      })
    })
  }

  returnWithKey (data, lang) {
    const retArr = []
    data.forEach((val, i) => {
      const obj = {}
      obj.key = i
      obj._id = val._id
      obj.name = val.name
      obj.size = val.size
      obj.map_id = val.map_id
      obj.stack_type = val.stack_type
      obj.content_pack_version = val.content_pack_version
      obj.resources = val.resources
      retArr.push(obj)
    })
    return retArr
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { data, dataId, isNewRecord, stackType, contentPackVersion, editDisabled } = this.state
    const { language } = this.props

    return (
      <div>
        <PageHeader
          title={'Ikony dla typu stacka ' + stackType + ' (' + contentPackVersion + ')'}
          onBack={() => this.redirect('/settings/maps')}
          extra={
            <AddRecordButton
              language={language}
              showAddRecordModal={this.showAddRecordModal}
              title={messageTypes[language].new_map_icon}
              disabled={editDisabled}
            />
          }
        />
        <StyledMapIconsTableSettings
          language={language}
          data={this.returnWithKey(data, language)}
          setDataId={this.setDataId}
          deleteRecord={this.deleteRecord}
          showAddRecordModal={this.showAddRecordModal}
          editDisabled={editDisabled}
        />
        <MapIconModalSettings
          language={language}
          data={data}
          dataId={dataId}
          isNewRecord={isNewRecord}
          setDataId={this.setDataId}
          loading={this.state.loading}
          updateRecord={this.updateRecord}
          showAddRecordModal={this.showAddRecordModal}
          addRecord={this.addRecord}
          addFile={this.addFile}
          removeSingleFile={this.removeSingleFile}
          removeResource={this.removeResource}
          resources={this.state.resources}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language
})

const MapIconsSettingsContainer = connect(
  mapStateToProps
)(MapIconsSettings)

export default MapIconsSettingsContainer
