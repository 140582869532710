import React, { Component } from 'react'
import { Row, Col, Divider } from 'antd'
import FontAwesomeIcon from 'react-fontawesome'
import { messageTypes } from '../../../actions/messages.js'
import { machineFileDownload } from '../../../actions'
import MachineModal from '../../../components/sections/home/machineModal'

class StyledMachineInfo extends Component {
  render () {
    const { name, status } = this.props

    return (
      <Row className='net-visualiser'>
        <Col span={24}>
          <FontAwesomeIcon className={`server-ico status-${status}`} name='server' size='4x' onClick={() => this.props.toggleMachineModal(true, this.props.element)} />
          <h3 className='m-name'>{name}</h3>
        </Col>
      </Row>
    )
  }
}

class StyledNetVisualiser extends Component {
  state = {
    machineElement: null,
    allowMachineStateStatus: {}
  }

  toggleMachineModal = (flag, machine) => {
    this.props.setMachineModalVisible(flag)
    this.setState({ machineElement: machine })
  }

  setAllowMachineStateStatus = (vmUuid) => {
    const allowMachineStateStatus = this.state.allowMachineStateStatus
    allowMachineStateStatus[vmUuid] = true
    this.setState({ allowMachineStateStatus })
  }

  clearAllowMachineStateStatus = (vmUuid) => {
    const allowMachineStateStatus = this.state.allowMachineStateStatus

    if (allowMachineStateStatus[vmUuid] === true) {
      delete allowMachineStateStatus[vmUuid]

      this.setState({ allowMachineStateStatus })
    }
  }

  render () {
    const { net, language, stack } = this.props

    return (
      <div>
        <MachineModal
          language={this.props.language}
          visible={this.props.machineModalVisible}
          element={this.state.machineElement}
          machineElement={this.state.machineElement}
          toggleModal={this.toggleMachineModal}
          setAllowStateStatus={this.setAllowMachineStateStatus}
          allowStateStatus={this.state.allowMachineStateStatus}
          clearAllowMachineStateStatus={this.clearAllowMachineStateStatus}
          downloadFile={machineFileDownload}
          refreshData={this.props.refreshData}
          stack={stack}
        />
        <Divider>{messageTypes[language].scenario_machines_status}</Divider>
        <Row gutter={8}>
          {
            net && net.length && net.map((val, i) =>
              <Col key={i} span={24} sm={8}>
                <StyledMachineInfo
                  id={val.id}
                  name={val.name[language]}
                  status={val.resources.status}
                  language={language}
                  ip={val.resources.ip}
                  element={val}
                  toggleMachineModal={this.toggleMachineModal}
                />
              </Col>
            )
          }
        </Row>
      </div>
    )
  }
}

export default StyledNetVisualiser
