import React, { Component } from 'react'
import { Modal } from 'antd'
import { messageTypes } from '../../actions/messages'
import { connect } from 'react-redux'
import ReactMarkdown from 'react-markdown'

class QuickGuideModal extends Component {
  state = {
    loading: false
  }

  handleCancel = () => {
    this.props.handleQuickGuideVisibility(false)
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { language, visible, stack } = this.props

    return (
      <div>
        <Modal
          visible={visible}
          title={messageTypes[language].quick_guide}
          onCancel={this.handleCancel}
          footer={null}
          className='quick-guide-modal'
        >
          <ReactMarkdown>{stack && stack.desc && stack.desc[language]}</ReactMarkdown>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  stack: state.hdStore.stack
})
const WrappedQuickGuideModalContainer = connect(
  mapStateToProps
)(QuickGuideModal)

export default WrappedQuickGuideModalContainer
