import React, { Component } from 'react'
import StyledResetPasswordRequest from '../../../styledComponents/sections/reset-password/requestReset'
import { connect } from 'react-redux'

class ResetPasswordRequest extends Component {
  render () {
    return (
      <div>
        <StyledResetPasswordRequest
          language={this.props.language}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language
})

const ResetPasswordRequestContainer = connect(
  mapStateToProps
)(ResetPasswordRequest)

export default ResetPasswordRequestContainer
