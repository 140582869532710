import React, { Component } from 'react'
import { connect } from 'react-redux'
import StyledMapObjectsTableSettings from '../../../../styledComponents/sections/settings/mapObjects/mapObjectsTable'
import AddRecordButton from '../../../../styledComponents/common/addRecordButton'
import MapObjectModalSettings from './mapObjectModal'
import {
  openNotificationPopup,
  getCookie,
  deleteElementWithIdFromArr,
  confirm
} from '../../../../actions/helpers'

import {
  mapObjectsGetBuilder,
  mapObjectsCreateBuilder,
  mapObjectsUpdateBuilder,
  mapObjectsDeleteBuilder,
  adminCreateMapObjectResource,
  adminGetMapObjectResources,
  adminDeleteMapObjectResourceFile,
  adminDeleteMapObjectResource,
  adminGetContentPacks
} from '../../../../actions/'

import { messageTypes } from '../../../../actions/messages'

const crypto = require('crypto')

class MapObjectsSettings extends Component {
  state = {
    mapObjects: [],
    resources: [],
    data: [],
    dataId: null,
    loading: false,
    isNewRecord: false,
    contentPacks: []
  }

  componentDidMount () {
    this._isMounted = true
    this.getAllData()
    this.getContentPacks()
  }

  getContentPacks = () => {
    const { language } = this.props

    adminGetContentPacks(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            contentPacks: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  getMapObjectResources = (id) => {
    const { language } = this.props
    const _this = this

    return new Promise(function (resolve, reject) {
      adminGetMapObjectResources(id, getCookie('_token')).then((json) => {
        if (_this._isMounted) {
          if (json.status === 'ok') {
            _this.setState({
              resources: json.response
            }, () => resolve())
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
            reject(new Error('get of challenge resources failed'))
          }
        }
      })
    })
  }

  setDataId = (dataId) => {
    if (dataId !== null) {
      this.getMapObjectResources(dataId).then(() => {
        this.setState({ dataId })
      })
    } else {
      this.setState({ dataId })
    }
  }

  showAddRecordModal = (flag) => {
    if (flag) {
      this.addRecord({ name: { pl: 'Draft', en: 'Draft' }, map_id: crypto.randomBytes(4).toString('hex') })
    }
  }

  getAllData = () => {
    const { language } = this.props
    const _this = this

    return new Promise(function (resolve, reject) {
      mapObjectsGetBuilder(getCookie('_token')).then((json) => {
        if (_this._isMounted) {
          if (json.status === 'ok') {
            _this.setState({
              data: json.response
            }, () => resolve())
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
            reject(new Error('get of map objects failed'))
          }
        }
      })
    })
  }

  deleteRecord = (id) => {
    const { language } = this.props

    confirm('delete_map_object_question', language, () => {
      mapObjectsDeleteBuilder(id, getCookie('_token')).then((json) => {
        if (this._isMounted) {
          if (json.status === 'ok') {
            this.setState({ data: deleteElementWithIdFromArr(id, this.state.data) })
            openNotificationPopup(
              messageTypes[language].success,
              json.response[language],
              'smile'
            )
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[this.props.language].oops, json.response[language], 'frown')
          }
        }
      })
    })
  }

  updateRecord = (id, data) => {
    const { language } = this.props
    this.setState({ loading: true })
    mapObjectsUpdateBuilder(id, data, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        this.setState({
          loading: false
        })
        if (json.status === 'ok') {
          openNotificationPopup(
            messageTypes[language].success,
            json.response[language],
            'smile'
          )
          this.getAllData()
          this.setState({
            dataId: null
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  addRecord = (data) => {
    const { language } = this.props
    this.setState({ loading: true })
    mapObjectsCreateBuilder(data, getCookie('_token')).then((json) => {
      this.setState({ loading: false })
      if (json.status === 'ok') {
        this.getAllData().then(() => this.setDataId(json.response._id))
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
      }
    })
  }

  addFile = (id) => {
    const { language } = this.props
    const _this = this
    this.setState({ loading: true })

    return new Promise(function (resolve, reject) {
      adminCreateMapObjectResource(id, getCookie('_token')).then((json) => {
        _this.setState({ loading: false })
        if (json.status === 'ok') {
          resolve(json.response)
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
          reject(new Error('create of challenge failed'))
        }
      })
    })
  }

  removeSingleFile = (id, lang, callAfterCreate) => {
    const { language } = this.props

    return new Promise(function (resolve, reject) {
      adminDeleteMapObjectResourceFile(id, lang, getCookie('_token')).then((json) => {
        if (json.status === 'ok') {
          openNotificationPopup(messageTypes[language].success, json.response[language], 'smile')
          resolve(json.response)
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
          reject(new Error('delete of challenge resources file failed'))
        }
      })
    })
  }

  removeResource = (id, callAfterCreate) => {
    const { language } = this.props

    return new Promise(function (resolve, reject) {
      adminDeleteMapObjectResource(id, getCookie('_token')).then((json) => {
        if (json.status === 'ok') {
          openNotificationPopup(messageTypes[language].success, json.response[language], 'smile')
          resolve(json.response)
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
          reject(new Error('delete of map object resource failed'))
        }
      })
    })
  }

  returnWithKey (data, lang) {
    const retArr = []
    data.forEach((val, i) => {
      const obj = {}
      obj.key = i
      obj._id = val._id
      obj.name = val.name[lang]
      obj.map_id = val.map_id
      obj.stack_type = val.stack_type
      obj.content_pack_version = val.content_pack_version
      retArr.push(obj)
    })
    return retArr
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { data, dataId, isNewRecord } = this.state
    const { language } = this.props
    return (
      <div>
        <AddRecordButton
          language={language}
          showAddRecordModal={this.showAddRecordModal}
          title={messageTypes[language].new_map_object}
        />
        <StyledMapObjectsTableSettings
          language={language}
          data={this.returnWithKey(data, language)}
          setDataId={this.setDataId}
          deleteRecord={this.deleteRecord}
          showAddRecordModal={this.showAddRecordModal}
          contentPacks={this.state.contentPacks}
        />
        <MapObjectModalSettings
          language={language}
          data={data}
          dataId={dataId}
          isNewRecord={isNewRecord}
          setDataId={this.setDataId}
          loading={this.state.loading}
          updateRecord={this.updateRecord}
          showAddRecordModal={this.showAddRecordModal}
          addRecord={this.addRecord}
          addFile={this.addFile}
          removeSingleFile={this.removeSingleFile}
          removeResource={this.removeResource}
          resources={this.state.resources}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language
})

const MapObjectsSettingsContainer = connect(
  mapStateToProps
)(MapObjectsSettings)

export default MapObjectsSettingsContainer
