import React, { Component } from 'react'
import { connect } from 'react-redux'
import QuestionsListCard from './questionsListCard'
import { setQuizId } from '../../../store/actions'
import { quizzesGet, quizQuestionsGet } from '../../../actions'
import { namesMatch, getCookie, openNotificationPopup, confirm, takeElementWithIdFromArr } from '../../../actions/helpers'
import { messageTypes } from '../../../actions/messages'
import StyledLoader from '../../../styledComponents/common/loader'
import StyledQuiz from '../../../styledComponents/sections/quiz/'

class Quiz extends Component {
  state = {
    quiz: [],
    matchQuiz: [],
    quizList: null
  };

  componentDidMount () {
    this._isMounted = true
    this.getAllQuizzes()
  }

  componentDidUpdate (prevProps, prevState) {
    const { quizId, language, searchPassphrase, stack } = this.props
    const { matchQuiz, quiz } = this.state

    // when switching stack and are loaded quizzes, clear (hide) quizzes list
    if (stack === 'switching' && quiz.length) {
      this.setState({ quiz: [], matchQuiz: [] })
    }

    // when stack switched, load quizzes again
    if (prevProps.stack === 'switching' && typeof stack === 'object') {
      this.getAllQuizzes()
    }

    if (quizId && !(prevProps.quizId === quizId)) {
      const quiz = takeElementWithIdFromArr(quizId, matchQuiz)
      this.startQuiz(quizId, quiz.started_at, quiz.solved_at)
    }

    if (searchPassphrase !== prevProps.searchPassphrase || (searchPassphrase && prevState.quiz?.length === 0 && quiz?.length > 0)) {
      if (!searchPassphrase) {
        this.setState({ matchQuiz: quiz })
      } else {
        const matchQuiz = []
        const pattern = (searchPassphrase || '')
        quiz.forEach((val, i) => {
          if (
            (val.scenario?.name && namesMatch(pattern, val.scenario?.name[language])) ||
            (val.desc && namesMatch(pattern, val.desc[language])) ||
            (val.display_name && namesMatch(pattern, val.display_name[language]))
          ) {
            matchQuiz.push(val)
          }
        })
        if (this._isMounted) {
          this.setState({ matchQuiz })
        }
      }
    }
  }

  getAllQuizzes = () => {
    const { language } = this.props
    quizzesGet(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            quiz: json.response,
            matchQuiz: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  startQuiz = (id, progress, solved) => {
    const { language } = this.props
    const anchor = (progress || solved) ? null : 'quiz_start_question'
    confirm(anchor, language, () => {
      const token = getCookie('_token')
      quizQuestionsGet(id, token).then((json) => {
        if (this._isMounted) {
          if (json.status === 'ok') {
            this.setState({ quizList: json.response })
          } else if (json.status === 'err') {
            openNotificationPopup(
              messageTypes[language].oops,
              json.response[language],
              'frown'
            )
          }
        }
      })
    }, () => {
      this.props.setQuizId(null)
    })
  }

  onHandleListQuit = () => {
    this.props.setQuizId(null)
    this.setState({
      quizList: null,
      quiz: []
    })
    this.getAllQuizzes()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  // onHandleQuizSelect should be defined somewhere?

  render () {
    const { language, quizId, stack } = this.props
    const { matchQuiz, quizList, quiz } = this.state
    return (
      <div>
        <StyledQuiz quiz={matchQuiz} />
        {quizList
          ? (
            <QuestionsListCard
              quizList={quizList}
              quizId={quizId}
              handleListQuit={this.onHandleListQuit}
              handleQuizSelect={this.onHandleQuizSelect}
              language={language}
            />
            )
          : ''}
        <StyledLoader fadeOut={quiz.length && stack !== 'switching' && true} fadeIn={!quiz.length || stack === 'switching'} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  searchPassphrase: state.hdStore.searchPassphrase,
  language: state.hdStore.language,
  quizId: state.hdStore.quizId,
  stack: state.hdStore.stack
})

const mapDispatchToProps = {
  setQuizId
}

const QuizContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Quiz)

export default QuizContainer
