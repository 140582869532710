import React, { Component } from 'react'
import Map from './map'
import { connect } from 'react-redux'

class Home extends Component {
  render () {
    return (
      <div>
        <Map />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language
})

const HomeContainer = connect(
  mapStateToProps
)(Home)

export default HomeContainer
