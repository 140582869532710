import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setScenarioModalVisible, setMachineModalVisible } from '../../../store/actions'
import StyledDescModal from '../../../styledComponents/sections/scenarios/descModal'
import { scenarioBlock, scenarioUnblock, scenarioExtendBlock, scenarioFileDownload } from '../../../actions'
import { openNotificationPopup, getCookie } from '../../../actions/helpers'
import { messageTypes } from '../../../actions/messages'
const FileDownload = require('js-file-download')

class DescModal extends Component {
  state = {
    blockLoading: false
  }

  handleClose = (e) => {
    this.props.setScenarioModalVisible(false)
  }

  handleBlockScenario = (days) => {
    const { scenarioElement, getAllScenarios } = this.props

    this.setState({ blockLoading: true })

    scenarioBlock(scenarioElement._id, days, getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        getAllScenarios().then(() => {
          openNotificationPopup(messageTypes[this.props.language].success, json.response[this.props.language], 'smile')
          this.setState({ blockLoading: false })
        })
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[this.props.language].oops, json.response[this.props.language], 'frown')
        this.setState({ blockLoading: false })
      }
    })
  }

  handleUnblockScenario = () => {
    const { scenarioElement, getAllScenarios } = this.props

    this.setState({ blockLoading: true })

    scenarioUnblock(scenarioElement._id, getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        getAllScenarios().then(() => {
          openNotificationPopup(messageTypes[this.props.language].success, json.response[this.props.language], 'smile')
          this.setState({ blockLoading: false })
        })
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[this.props.language].oops, json.response[this.props.language], 'frown')
        this.setState({ blockLoading: false })
      }
    })
  }

  handleExtendBlockScenario = (days) => {
    const { scenarioElement, getAllScenarios } = this.props

    this.setState({ blockLoading: true })

    scenarioExtendBlock(scenarioElement._id, days, getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        getAllScenarios().then(() => {
          openNotificationPopup(messageTypes[this.props.language].success, json.response[this.props.language], 'smile')
          this.setState({ blockLoading: false })
        })
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[this.props.language].oops, json.response[this.props.language], 'frown')
        this.setState({ blockLoading: false })
      }
    })
  }

  downloadScenarioFile (element, filename, language, file) {
    scenarioFileDownload(element.scenario_hd_id, filename, language, getCookie('_token')).then((response) => {
      // download file if get was successfull
      if (response.status === 200) {
        FileDownload(response.data, file.label[language] + '.' + file.file_type)
      } else {
        openNotificationPopup(messageTypes[language].oops, messageTypes.universal.error, 'frown')
      }
    })
  }

  render () {
    const { scenarioElement, language, scenarioModalVisible, stack } = this.props

    return (
      (scenarioElement)
        ? (
          <StyledDescModal
            element={scenarioElement}
            language={language}
            modalVisible={scenarioModalVisible}
            onHandleClose={this.handleClose}
            onHandleBlockScenario={this.handleBlockScenario}
            onHandleUnblockScenario={this.handleUnblockScenario}
            onHandleExtendBlockScenario={this.handleExtendBlockScenario}
            downloadScenarioFile={this.downloadScenarioFile}
            blocked={!!scenarioElement?.blocked_by}
            blockLoading={this.state.blockLoading}
            machineModalVisible={this.props.machineModalVisible}
            setMachineModalVisible={this.props.setMachineModalVisible}
            stack={stack}
            refreshData={this.props.refreshData}
          />)
        : ''
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language,
  scenarioElement: state.hdStore.scenarioElement,
  scenarioModalVisible: state.hdStore.scenarioModalVisible,
  stack: state.hdStore.stack,
  machineModalVisible: state.hdStore.machineModalVisible
})

const mapDispatchToProps = {
  setScenarioModalVisible,
  setMachineModalVisible
}

const DescModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DescModal)

export default DescModalContainer
