
export function handleFullscreen (consoleEmulatorRef) {
  consoleEmulatorRef.current.requestFullscreen()
  consoleEmulatorRef.current.contentWindow.document.getElementById('mainCanvas').focus()
}

export function openConsoleNewWindow (machineId) {
  window.open('/machines/console/' + machineId)
}

export function handleCtrlAltDel (consoleEmulatorRef) {
  consoleEmulatorRef.current.contentWindow.document.getElementById('comm-ctrl-alt-del').click()
}
