import React from 'react'
import { Modal } from 'antd'
import { messageTypes } from '../../../../actions/messages'
import { takeElementWithIdFromArr, getCookie, openNotificationPopup } from '../../../../actions/helpers'
import StyledMapObjectsForm from '../../../../styledComponents/sections/settings/mapObjects/mapObjectForm'
import { stackTypesOverlordGet, adminGetContentPacksForStackType, adminCopyMapObjectResourceFileToLang } from '../../../../actions/'

class MapObjectModalSettings extends React.Component {
  state = {
    singleData: {},
    contentPackVersions: [],
    overlordStackTypes: []
  }

  handleCancel = () => {
    const { setDataId, showAddRecordModal } = this.props
    setDataId(null)
    showAddRecordModal(false)
  }

  getStackTypesFromOverlord = () => {
    const { language } = this.props
    stackTypesOverlordGet(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            overlordStackTypes: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }

        this.setState({
          loading: false
        })
      }
    })
  }

  adminCopyMapObjectResourceFileToLang = (id, lang, dstLang) => {
    const { language } = this.props

    adminCopyMapObjectResourceFileToLang(id, lang, dstLang, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          openNotificationPopup(messageTypes[language].success, json.response[language], 'smile')
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  componentDidMount () {
    this._isMounted = true
    this.getStackTypesFromOverlord()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  componentDidUpdate (prevProps, prevState) {
    const { dataId, data } = this.props
    if (prevProps.dataId !== dataId) {
      const singleData = takeElementWithIdFromArr(dataId, data)
      this.setState({ singleData })

      if (singleData) {
        this.getContentPackVersions(singleData.stack_type)
      }
    }
  }

  getContentPackVersions = (stackType) => {
    const { language } = this.props

    adminGetContentPacksForStackType(stackType, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            contentPackVersions: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  render () {
    const {
      language, dataId, isNewRecord, loading,
      updateRecord, addRecord, addFile, resources, removeSingleFile, removeResource
    } = this.props
    const { singleData } = this.state
    return (
      <div>
        <Modal
          maskClosable={false}
          width={1000}
          visible={!!(dataId || isNewRecord)}
          title={
            (isNewRecord && messageTypes[language].new_map_object) ||
            (
              singleData &&
              singleData.name &&
              singleData.name[language])
          }
          onCancel={this.handleCancel}
          footer={null}
        >
          <StyledMapObjectsForm
            isNewRecord={isNewRecord}
            data={singleData || {}}
            language={language}
            onCancel={this.handleCancel}
            updateRecord={updateRecord}
            addRecord={addRecord}
            loading={loading}
            addFile={addFile}
            removeSingleFile={removeSingleFile}
            removeResource={removeResource}
            resources={resources}
            contentPackVersions={this.state.contentPackVersions}
            getContentPackVersions={this.getContentPackVersions}
            overlordStackTypes={this.state.overlordStackTypes}
            adminCopyMapObjectResourceFileToLang={this.adminCopyMapObjectResourceFileToLang}
          />
        </Modal>
      </div>
    )
  }
}

export default MapObjectModalSettings
