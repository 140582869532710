import React, { Component } from 'react'
import FontAwesomeIcon from 'react-fontawesome'
import { messageTypes } from '../../../actions/messages'
import { Menu, Dropdown, Tooltip } from 'antd'
import { openConsoleNewWindow } from '../../../components/sections/machines/terminalHelpers.js'

class StyledConsoleDropdown extends Component {
  render () {
    const { console, status, stackActionStatus, language } = this.props

    const menu = (
      <Menu className='console-dropdown-menu'>
        <Menu.Item key='1' onClick={() => this.props.onHandleClick(this.props.id, true)}>
          <FontAwesomeIcon name='window-maximize' />
          <span className='console-dropdown-link'>{messageTypes[language].popup}</span>
        </Menu.Item>
        <Menu.Item key='2' onClick={() => openConsoleNewWindow(this.props.id)}>
          <FontAwesomeIcon name='external-link' />
          <span className='console-dropdown-link'>{messageTypes[language].new_window}</span>
        </Menu.Item>
      </Menu>
    )

    const consoleDisabled = !console || status !== 'poweredOn' || stackActionStatus !== undefined

    return (
      <Tooltip placement='topRight' title={consoleDisabled ? messageTypes[language].console_is_disabled : ''}>
        <Dropdown
          overlay={menu}
          placement='topRight'
          disabled={consoleDisabled}
          className={consoleDisabled ? 'vm-action-disabled' : ''}
        >
          <div>
            <FontAwesomeIcon
              name='terminal'
              pulse={this.props.rebooting}
            />{messageTypes[language].console}
          </div>
        </Dropdown>
      </Tooltip>
    )
  }
}

export default StyledConsoleDropdown
