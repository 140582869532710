import React, { Component } from 'react'
import { pingConsoleSession } from '../../../actions/'
import { messageTypes } from '../../../actions/messages'
import { getCookie } from '../../../actions/helpers'
import Swal from 'sweetalert2'

class Terminal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      sessionPingJob: null
    }
  }

  handleIframeLoad (consoleEmulatorRef) {
    consoleEmulatorRef.current.contentWindow.document.getElementById('mainCanvas').focus()
  }

  pingConsoleSession = (sessId) => {
    const { language } = this.props

    pingConsoleSession(sessId, getCookie('_token')).then((json) => {
      const _this = this

      if (this._isMounted) {
        if (json === 'unauthorized') {
          clearTimeout(this.state.sessionPingJob)
          this.props.consoleEmulatorRef.current.contentWindow.wmks.destroy()
          this.props.handleCloseParentModal && _this.props.handleCloseParentModal(false, false)
          Swal.fire({
            text: messageTypes[language].console_session_expired
          })
        } else if (json.status === 'err') {
          clearTimeout(this.state.sessionPingJob)
          this.props.consoleEmulatorRef.current.contentWindow.wmks.destroy()
          this.props.handleCloseParentModal && _this.props.handleCloseParentModal(false, false)
        } else {
          const sessionPingJob = setTimeout(function () { _this.pingConsoleSession(sessId) }, 10000)
          this.setState({ sessionPingJob })
        }
      }
    })
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentDidUpdate (prevProps, prevState) {
    const { ticketData } = this.props

    // get ticket on component update only when not used in map
    if (prevProps.ticketData && ticketData === null) {
      clearTimeout(this.state.sessionPingJob)
      this.props.consoleEmulatorRef.current.contentWindow.wmks.destroy()
    }
  }

  componentWillUnmount () {
    const { sessionPingJob } = this.state
    this._isMounted = false

    if (sessionPingJob) {
      clearTimeout(sessionPingJob)
    }
  }

  render () {
    const { ticketData, consoleEmulatorRef } = this.props
    const consoleUri = `/web_console/index.html?type=${ticketData.type}&ticket=${ticketData.ticket}&host=${ticketData.host}&port=${ticketData.port}&cfgFile=${encodeURIComponent(ticketData.cfgFile)}&thumbprint=${ticketData.thumbprint}&sessionId=${ticketData.sessionId}&vmId=${ticketData.vmId}&version=${ticketData.version}&serverGuid=${ticketData.serverGuid}`

    if (!this.state.sessionPingJob && ticketData && ticketData.sessionId) {
      this.pingConsoleSession(ticketData.sessionId)
    }

    return (
      <iframe
        title='ESXI console'
        ref={consoleEmulatorRef}
        id='console-emulator'
        width='100%'
        height='100%'
        src={consoleUri}
        onLoad={() => this.handleIframeLoad(consoleEmulatorRef)}
        sandbox='allow-same-origin allow-scripts'
      />
    )
  }
}

export default Terminal
