import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  setLanguage, setSearchPassphrase, setAuth,
  setUser, setStackActionStatus, setStack, setCurrentStackMachines,
  setUserStacks, setStackVpns
} from '../../store/actions'
import StyledHeader from '../../styledComponents/common/header'
import SingleUserProfileModal from '../sections/profile/'
import {
  confirm, openNotificationPopup,
  getCookie, deleteCookie
} from '../../actions/helpers'
import { machinesStackAction, signout, certificateCaDownload, copyToClipboard, userStackGet, userStackVpnsGet, stackAction } from '../../actions/'
import { messageTypes } from '../../actions/messages'
import { withRouter } from 'react-router-dom'
import QuickGuideModal from './quickGuideModal'
import InstructionModal from './instructionModal'
import StackChangedPrompt from './stackChangedPrompt'
import { views } from '../../config/'

const FileDownload = require('js-file-download')

class Header extends Component {
  state = {
    profilePopupVisible: false,
    quickGuidePopupVisible: false,
    visible: false,
    machinesPowerOnAll: false,
    machinesPowerOffAll: false,
    instructionPopupVisible: false
  }

  selectLang = (language) => {
    window.localStorage.setItem('language', language)
    this.props.setLanguage(language)
  }

  onHandleSearch = (e) => {
    const searchPassphrase = e.target.value
    this.props.setSearchPassphrase(searchPassphrase)
  }

  onHandlePowerOnAllMachines = () => {
    const { language, setStackActionStatus } = this.props

    confirm('machine_power_on_all_question', language, () => {
      this.setState({ machinesPowerOnAll: true })
      setStackActionStatus('turning_on')

      machinesStackAction(getCookie('_token'), 'power-on').then((json) => {
        if (json.status === 'ok') {
          this.setState({ machinesPowerOnAll: false })
          setStackActionStatus('poweredOn')

          openNotificationPopup(messageTypes[language].success, messageTypes[language].machine_power_on_all_ok, 'smile', json.response[language] || json.response, language)
        } else if (json.status === 'err') {
          this.setState({ machinesPowerOnAll: false })
          openNotificationPopup(messageTypes[language].oops, messageTypes[language].machine_power_on_all_err, 'frown', json.response[language] || json.response, language)
        }
      })
    })
  }

  onHandlePowerOffAllMachines = () => {
    const { language, setStackActionStatus } = this.props

    confirm('machine_power_off_all_question', language, () => {
      this.setState({ machinesPowerOffAll: true })
      setStackActionStatus('turning_off')

      machinesStackAction(getCookie('_token'), 'power-off').then((json) => {
        if (json.status === 'ok') {
          this.setState({ machinesPowerOffAll: false })
          setStackActionStatus('poweredOff')

          openNotificationPopup(messageTypes[language].success, messageTypes[language].machine_power_off_all_ok, 'smile', json.response[language] || json.response, language)
        } else if (json.status === 'err') {
          this.setState({ machinesPowerOffAll: false })
          openNotificationPopup(messageTypes[language].oops, messageTypes[language].machine_power_off_all_err, 'frown', json.response[language] || json.response, language)
        }
      })
    })
  }

  showProfilePopup = (visible) => {
    this.setState({ profilePopupVisible: visible })
  }

  showQuickGuidePopup = (visible) => {
    this.setState({ quickGuidePopupVisible: visible })
  }

  showInstructionPopup = (visible) => {
    this.setState({ instructionPopupVisible: visible })
  }

  logout = () => {
    const { language } = this.props
    signout(getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        openNotificationPopup(messageTypes[language].success, json.response[language], 'smile')
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
      }
    })
    const { setAuth, setUser, setStack, setCurrentStackMachines } = this.props
    setAuth(null)
    setUser(null)
    setStack(null)
    setUserStacks(null)
    setCurrentStackMachines(null)
    deleteCookie('_token')
    deleteCookie('_view')
    window.localStorage.removeItem('user')
    // window.localStorage.removeItem('current_stack_type')
    this.props.history.push('/')
  }

  downloadCACertificate (type, language) {
    certificateCaDownload(type, getCookie('_token')).then((response) => {
      // download file if get was successfull
      if (response.status === 200) {
        const filename = type + '-ca.crt'
        FileDownload(response.data, filename)
      } else {
        openNotificationPopup(messageTypes[language].oops, messageTypes.universal.error, 'frown')
      }
    })
  }

  getCurrentStack = () => {
    const { setStack, setUserStacks, setStackActionStatus } = this.props

    userStackGet(getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        setStack(json.response.stack)
        setUserStacks(json.response.stacks)

        if (['reverting', 'poweringon', 'suspending', 'failed', 'waiting-for-revert', 'waiting-for-poweron', 'waiting-for-suspend'].includes(json.response.stack.status)) {
          setStackActionStatus(json.response.stack.status)
        } else {
          setStackActionStatus(undefined)
        }
      } else if (json.status === 'err') {
        openNotificationPopup(
          messageTypes[this.props.language].oops,
          json.response[this.props.language],
          'frown'
        )
      }
    })
  }

  getUserStackVpns = () => {
    userStackVpnsGet(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.props.setStackVpns(json.response)
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[this.props.language].oops, json.response[this.props.language], 'frown')
        }
      }
    })
  }

  executeStackAction = (id, action) => {
    const { language, setStackActionStatus } = this.props
    confirm('stack_' + action + '_question', language, () => {
      if (action === 'poweron') {
        setStackActionStatus('waiting-for-poweron')
      } else if (action === 'suspend') {
        setStackActionStatus('waiting-for-suspend')
      } else if (action === 'revert' || action === 'revert-with-poweron') {
        setStackActionStatus('waiting-for-revert')
      }

      stackAction(id, action, getCookie('_token')).then((json) => {
        if (this._isMounted) {
          if (json.status === 'ok') {
            openNotificationPopup(
              messageTypes[language].success,
              json.response[language],
              'smile'
            )
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[this.props.language].oops, json.response[language], 'frown')
          }
        }
      })
    })
  }

  componentDidUpdate (prevProps, prevState) {
    // when stack switched, load stack vpns again
    if (prevProps.stack === 'switching' && typeof this.props.stack === 'object') {
      this.getCurrentStack()
      this.getUserStackVpns()
    }

    // detect route change and set via websocket to check vm statuses
    if (prevProps.uiPathname !== this.props.uiPathname) {
      if (this.props.uiPathname === '/' || this.props.uiPathname === '/machines') {
        this.props.useBackendVmsCheck(true)
      } else {
        this.props.useBackendVmsCheck(false)
      }
    }

    if (prevProps.ctfState !== this.props.ctfState && this.props.ctfState === 'BATTLE') {
      this.getCurrentStack()
      this.getUserStackVpns()
    }
  }

  componentDidMount () {
    this._isMounted = true

    this.getCurrentStack()
    this.getUserStackVpns()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { reduxLanguage, language, uiPathname, auth, user, setUser, stack, stackVpns, userStacks, setStack, stackActionStatus } = this.props
    const { profilePopupVisible, quickGuidePopupVisible, machinesPowerOnAll, machinesPowerOffAll, instructionPopupVisible } = this.state

    // const substack = stack && stack.substacks && stack.substacks.length && stack.substacks.find(x => x.name === currentStackType)
    // const substackTitle = currentStackType !== 'switching' && (substack ? substack.title : stack && stack.title)

    return (
      <>
        <StyledHeader
          auth={auth}
          uiPathname={uiPathname}
          user={user}
          handlePowerOnAllMachines={this.onHandlePowerOnAllMachines}
          handlePowerOffAllMachines={this.onHandlePowerOffAllMachines}
          stackActionStatus={stackActionStatus}
          machinesPowerOnAll={machinesPowerOnAll}
          machinesPowerOffAll={machinesPowerOffAll}
          handleInputChange={this.onHandleSearch}
          language={reduxLanguage || language}
          handleClick={this.selectLang}
          handleCADownload={this.downloadCACertificate}
          handleProfileVisibility={this.showProfilePopup}
          handleQuickGuideVisibility={this.showQuickGuidePopup}
          handleInstructionVisibility={this.showInstructionPopup}
          handleLogout={this.logout}
          copyToClipboard={copyToClipboard}
          stack={stack}
          status={stackActionStatus !== undefined ? stackActionStatus : stack?.status}
          stackVpns={stackVpns}
          userStacks={userStacks}
          executeStackAction={this.executeStackAction}
          isUserRankingOnly={auth === views.ur}
        />
        <StackChangedPrompt
          language={reduxLanguage || language}
          setStack={setStack}
          refreshData={() => { this.getCurrentStack(); this.getUserStackVpns() }}
        />
        <SingleUserProfileModal
          language={reduxLanguage || language}
          visible={profilePopupVisible}
          handleProfileVisibility={this.showProfilePopup}
          data={user}
          setUser={setUser}
        />
        <QuickGuideModal
          language={reduxLanguage || language}
          visible={quickGuidePopupVisible}
          handleQuickGuideVisibility={this.showQuickGuidePopup}
        />
        <InstructionModal
          language={reduxLanguage || language}
          visible={instructionPopupVisible}
          handleInstructionVisibility={this.showInstructionPopup}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.hdStore.auth,
  language: state.hdStore.language,
  searchPassphrase: state.hdStore.searchPassphrase,
  uiPathname: state.hdStore.uiPathname,
  user: state.hdStore.user,
  stackActionStatus: state.hdStore.stackActionStatus,
  stack: state.hdStore.stack,
  stackVpns: state.hdStore.stackVpns,
  userStacks: state.hdStore.user_stacks,
  useBackendVmsCheck: state.hdStore.useBackendVmsCheck,
  ctfState: state.hdStore.ctfState
})

const mapDispatchToProps = {
  setLanguage,
  setSearchPassphrase,
  setStackActionStatus,
  setAuth,
  setUser,
  setStack,
  setCurrentStackMachines,
  setUserStacks,
  setStackVpns
}

const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)

export default withRouter(HeaderContainer)
