import React, { Component } from 'react'
import { connect } from 'react-redux'
import { scenariosGet, scenarioFileDownload } from '../../../actions'
import { setScenarioElement } from '../../../store/actions'
import { namesMatch, tagsMatchOr, openNotificationPopup, getCookie } from '../../../actions/helpers'
import StyledScenarios from '../../../styledComponents/sections/scenarios/'
import StyledLoader from '../../../styledComponents/common/loader'
import DescModal from './descModal'
import { messageTypes } from '../../../actions/messages'
import settings from '../../../config/'

const FileDownload = require('js-file-download')

class Scenarios extends Component {
  constructor () {
    super()
    this.state = {
      scenarios: [],
      matchScenarios: []
    }
  }

  getAllScenarios = () => {
    const _this = this
    return new Promise(function (resolve, reject) {
      scenariosGet(getCookie('_token')).then((json) => {
        if (_this._isMounted) {
          if (json.status === 'ok') {
            const scenarios = json.response
            _this.setState({
              scenarios,
              matchScenarios: scenarios
            })

            // if scenarioElement is set it means that modal is open
            // so scenarioElement also should be updated
            if (_this.props.scenarioElement) {
              const updatedElement = scenarios.find(x => String(x._id) === String(_this.props.scenarioElement._id))
              _this.props.setScenarioElement(updatedElement)
            }

            resolve()
          } else if (json.status === 'err') {
            // reject(new Error('getAllScenariosPromise error'))
            openNotificationPopup(messageTypes[_this.props.language].oops, json.response[_this.props.language], 'frown')
          }
        }
      })
    })
  }

  componentDidMount () {
    this._isMounted = true
    if (settings.ajax_refresh_rate) {
      this.getAllScenarios()
      setInterval(() => {
        this.getAllScenarios()
      }, settings.ajax_refresh_rate * 1000)
    } else {
      this.getAllScenarios()
    }
  }

  componentDidUpdate (prevProps, prevState) {
    // when switching stack and are loaded scenarios, clear (hide) scenarios list
    if (this.props.stack === 'switching' && this.state.scenarios.length) {
      this.setState({ scenarios: [], matchScenarios: [] })
    }

    // when stack switched, load scenarios again
    if (prevProps.stack === 'switching' && typeof this.props.stack === 'object') {
      this.getAllScenarios()
    }

    // On searching
    if (this.props.searchPassphrase !== prevProps.searchPassphrase) {
      if (!this.props.searchPassphrase) {
        this.setState({ matchScenarios: this.state.scenarios })
      } else {
        const { language } = this.props
        const matchScenarios = []
        const pattern = (this.props.searchPassphrase || '')

        this.state.scenarios.forEach((val, i) => {
          if ((val.name && namesMatch(pattern, val.name[language])) ||
          (val.desc && namesMatch(pattern, val.desc[language])) ||
          (val.tags && tagsMatchOr(pattern, val.tags))) {
            matchScenarios.push(val)
          }
        })
        this.setState({ matchScenarios })
      }
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  downloadScenarioFile (element, filename, language, file) {
    scenarioFileDownload(element.scenario_hd_id, filename, language, getCookie('_token')).then((response) => {
      // download file if get was successfull
      if (response.status === 200) {
        FileDownload(response.data, file.label[language] + '.' + file.file_type)
      } else {
        openNotificationPopup(messageTypes[language].oops, messageTypes.universal.error, 'frown')
      }
    })
  }

  render () {
    return (
      <div>
        <DescModal
          getAllScenarios={this.getAllScenarios}
        />
        <StyledScenarios
          scenarios={this.state.matchScenarios}
          downloadScenarioFile={this.downloadScenarioFile}
        />
        <StyledLoader fadeOut={this.state.scenarios.length && true} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  searchPassphrase: state.hdStore.searchPassphrase,
  language: state.hdStore.language,
  stack: state.hdStore.stack,
  scenarioElement: state.hdStore.scenarioElement
})

const mapDispatchToProps = {
  setScenarioElement
}

const ScenariosContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Scenarios)

export default ScenariosContainer
