import React, { Component } from 'react'
import { Menu, Dropdown, Button } from 'antd'
import { SolutionOutlined, SafetyCertificateOutlined, DownOutlined } from '@ant-design/icons'
import { messageTypes } from '../../actions/messages'

class CertificateDropdown extends Component {
  render () {
    const { handleCADownload, language, certificates } = this.props

    const menu = (
      <Menu>
        {
          certificates && certificates.length && certificates.map((cert, n) =>
            (
              <Menu.Item key={n} onClick={() => handleCADownload(cert, language)}>
                <span>
                  <SolutionOutlined /> {cert.charAt(0).toUpperCase() + cert.slice(1)}
                </span>
              </Menu.Item>)
          )
        }
      </Menu>
    )

    return (
      <Dropdown overlay={menu}>
        <Button>
          <SafetyCertificateOutlined /> {messageTypes[this.props.language].certificates} <DownOutlined />
        </Button>
      </Dropdown>
    )
  }
}

export default CertificateDropdown
