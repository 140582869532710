import React, { Component } from 'react'
import { connect } from 'react-redux'
import StyledSessionsTable from '../../../../styledComponents/sections/settings/sessions/sessionsTable'
import { openNotificationPopup, getCookie } from '../../../../actions/helpers'
import { sessionsGet } from '../../../../actions/'
import { messageTypes } from '../../../../actions/messages'
import settings from '../../../../config/'

class SessionSettings extends Component {
  state = {
    data: []
  }

  componentDidMount () {
    this._isMounted = true
    this.getAllData()
  }

  getAllData = async () => {
    const { language } = this.props
    const json = await sessionsGet(getCookie('_token'))
    if (!this._isMounted) return

    if (json.status === 'ok') {
      this.setState({
        data: json.response
          .map(session => {
            const role = session?.role ?? -1
            const roleName = settings.roles.find(x => x.role === role) || ''
            return {
              ...session,
              role: messageTypes[language]['role_' + roleName] || role.toString(),
              iat: (new Date(session.iat * 1000)).toISOString().substring(0, 19).replace('T', ' '),
              exp: (new Date(session.exp * 1000)).toISOString().substring(0, 19).replace('T', ' ')
            }
          })
      })
    } else if (json.status === 'err') {
      openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { data } = this.state
    const { language } = this.props
    return (
      <StyledSessionsTable
        language={language}
        data={data}
      />
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language
})

const SessionSettingsContainer = connect(
  mapStateToProps
)(SessionSettings)

export default SessionSettingsContainer
