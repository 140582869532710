import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'antd'
import StyledLogsTableSettings from '../../../../styledComponents/sections/settings/logging/logsTable'
import FilterButtons from './filterButtons'
import LogsModal from './logsModal'
import { LoadingOutlined } from '@ant-design/icons'
import {
  getCookie,
  deleteElementWithIdFromArr,
  openNotificationPopup,
  namesMatch,
  confirm
} from '../../../../actions/helpers'
import { messageTypes } from '../../../../actions/messages'
import { logsGetBuilder, logDeleteBuilder } from '../../../../actions/'
import dateformat from 'dateformat'

class MachinesSettings extends Component {
  state = {
    modalVisible: false,
    verbosity: 1,
    singleLog: null,
    logs: [],
    loading: false
  }

  componentDidMount () {
    this._isMounted = true
    this.getAllData()
  }

  getAllData = () => {
    const { language } = this.props
    this.setState({ loading: true })
    logsGetBuilder(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            logs: json.response,
            loading: false
          })
        } else if (json.status === 'err') {
          openNotificationPopup(
            messageTypes[language].oops,
            json.response[language],
            'frown'
          )

          this.setState({
            loading: false
          })
        }
      }
    })
  }

  toggleLogModal = (modalVisible, singleLog) => {
    this.setState({ modalVisible, singleLog })
  }

  deleteRecord = (id) => {
    const { language } = this.props

    confirm('delete_log_question', language, async () => {
      let json
      try {
        json = await logDeleteBuilder(id, getCookie('_token'))
      } catch (err) {
        openNotificationPopup(messageTypes[language].oops, err.toString(), 'frown')
        return
      }
      if (!this._isMounted) return

      if (json.status === 'ok') {
        this.setState({ logs: deleteElementWithIdFromArr(id, this.state.logs) })
        openNotificationPopup(
          messageTypes[language].success,
          json.response[language],
          'smile'
        )
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
      }
    })
  }

  resetFilters = () => {

  }

  changeVerbosity = (e) => {
    this.setState({ verbosity: e.target.value })
  }

  returnWithKey (data) {
    const { verbosity } = this.state
    const resp = data && data.reduce(function (filtered, val) {
      if (val.verbosity <= verbosity) {
        try {
          val.short_description = val.description.split(':')[0].substr(0, 30)
        } catch (e) {
          val.short_description = ''
        }
        val.user = (val.user_id && val.user_id.name) || ''
        val.key = val._id
        try {
          val.created_at = val.created_at !== undefined ? dateformat(val.created_at, 'yyyy-mm-dd HH:MM:ss') : ''
        } catch (e) {
          val.created_at = ''
        }

        filtered.push(val)
      }
      return filtered
    }, [])

    return resp
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { logs, modalVisible, singleLog, verbosity } = this.state
    const { language } = this.props

    let matchLogs = logs
    if (this.props.searchPassphrase) {
      matchLogs = logs.filter(val => val.description && namesMatch(this.props.searchPassphrase || '', val.description))
    }

    return (
      <div className={`settings-table ${logs && logs.length ? 'fade-in' : ''}`}>
        <Button onClick={() => this.getAllData()} className='pull-right'>
          {this.state.loading ? <LoadingOutlined /> : ''}{messageTypes[language].refresh}
        </Button>
        <StyledLogsTableSettings
          language={language}
          logs={this.returnWithKey(matchLogs)}
          deleteRecord={this.deleteRecord}
          toggleLogModal={this.toggleLogModal}
        />
        <FilterButtons
          language={language}
          verbosity={verbosity}
          handleVerbosity={this.changeVerbosity}
          handleFilters={this.resetFilters}
        />
        {singleLog !== null
          ? (
            <LogsModal
              language={language}
              singleLog={singleLog}
              modalVisible={modalVisible}
              toggleLogModal={this.toggleLogModal}
            />
            )
          : ''}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language,
  searchPassphrase: state.hdStore.searchPassphrase
})

const MachinesSettingsContainer = connect(
  mapStateToProps
)(MachinesSettings)

export default MachinesSettingsContainer
