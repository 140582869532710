import React, { Component } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './routes'
import config from './config/'

// styles for nice scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'
// import antd styles
import 'antd/dist/antd.css'
// here are modifications of pure antd styles
import './assets/scss/antd-theme-custom.scss'
import './assets/scss/style.scss'
import './assets/scss/loginStyle.scss'

class App extends Component {
  componentDidMount () {
    document.title = config.app_title || 'HackingDept'
    document.querySelector("link[rel*='icon']").href = (config.app_favicon && 'images/customization/' + config.app_favicon) || 'favicon.png'
  }

  render () {
    return (
      <div className='App'>
        <Router>
          <Routes />
        </Router>
      </div>
    )
  }
}

export default App
