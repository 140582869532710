import React from 'react'
import { messageTypes } from '../../../../actions/messages'
import {
  Form, Select, Button, Divider
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const { Option } = Select

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

class StyledInstallVersionForm extends React.Component {
  formRef = React.createRef()

  handleSubmit = (values) => {
    const { installVersion } = this.props
    const obj = {}
    obj.name = values.name

    installVersion(obj)
  }

  setDefaultValues () {
    this.formRef.current.setFieldsValue({
      name: undefined
    })
  }

  componentDidMount () {
    this.setDefaultValues()
  }

  componentDidUpdate (prevProps, prevState) {
    const { data, isNewRecord } = this.props

    if (data._id !== prevProps.data._id || isNewRecord !== prevProps.isNewRecord) {
      this.setDefaultValues()
    }
  }

  render () {
    const { language, loading, data } = this.props

    return (
      <Form onFinish={this.handleSubmit} layout='horizontal' ref={this.formRef}>
        <Form.Item
          name='name'
          label={messageTypes[language].version}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <Select>
            {
              data.available_versions?.length
                ? data.available_versions.map((versionName, n) =>
                  <Option key={n} value={versionName}>{versionName}</Option>
                )
                : ''
            }
          </Select>
        </Form.Item>

        <Form.Item className='form-footer'>
          <Divider />
          <Button htmlType='submit'>{loading ? <LoadingOutlined /> : ''} {messageTypes[language].submit}</Button>
          <Button onClick={() => { this.props.onCancel() }} className='cancel'>{messageTypes[language].cancel}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StyledInstallVersionForm
