import React, { Component } from 'react'
import { Layout } from 'antd'
const { Content } = Layout

class StyledBody extends Component {
  render () {
    const className = this.props.uiPathname?.replace('/', '_')

    return (
      <Content className={(className && 'component' + className) || ''} style={{ margin: '24px 16px 0', overflow: 'initial' }}>
        <div className='body-padding'>
          {this.props.children}
        </div>
      </Content>
    )
  }
}

export default StyledBody
