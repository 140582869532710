export const setLanguage = language => ({
  type: 'SET_LANGUAGE',
  language
})

export const setSearchPassphrase = searchPassphrase => ({
  type: 'SET_SEARCH_PASSPHRASE',
  searchPassphrase
})

export const setScenarioElement = scenarioElement => ({
  type: 'SET_SCENARIO_ELEMENT',
  scenarioElement
})

export const setScenarioModalVisible = scenarioModalVisible => ({
  type: 'SET_SCENARIO_MODAL_VISIBLE',
  scenarioModalVisible
})

export const setMachineModalVisible = machineModalVisible => ({
  type: 'SET_MACHINE_MODAL_VISIBLE',
  machineModalVisible
})

export const setVpnModalVisible = vpnModalVisible => ({
  type: 'SET_VPN_MODAL_VISIBLE',
  vpnModalVisible
})

export const setMapLegendModalVisible = mapLegendModalVisible => ({
  type: 'SET_MAP_LEGEND_MODAL_VISIBLE',
  mapLegendModalVisible
})

export const setMapObjectModalVisible = mapObjectModalVisible => ({
  type: 'SET_MAP_OBJECT_MODAL_VISIBLE',
  mapObjectModalVisible
})

export const setMapNodeModalVisible = mapNodeModalVisible => ({
  type: 'SET_MAP_NODE_MODAL_VISIBLE',
  mapNodeModalVisible
})

export const setMapChallengeNodeModalVisible = mapChallengeNodeModalVisible => ({
  type: 'SET_MAP_CHALLENGE_NODE_MODAL_VISIBLE',
  mapChallengeNodeModalVisible
})

export const setDoneModalVisible = doneModalVisible => ({
  type: 'SET_DONE_MODAL_VISIBLE',
  doneModalVisible
})

export const setChallengeModalVisible = challengeModalVisible => ({
  type: 'SET_CHALLENGE_MODAL_VISIBLE',
  challengeModalVisible
})

export const setStackActionStatus = stackActionStatus => ({
  type: 'SET_STACK_ACTION_STATUS',
  stackActionStatus
})

export const setConsoleOpened = consoleOpened => ({
  type: 'SET_CONSOLE_OPENED',
  consoleOpened
})

export const setQuizId = quizId => ({
  type: 'SET_QUIZ_ID',
  quizId
})

export const setUiPathname = uiPathname => ({
  type: 'SET_UI_PATHNAME',
  uiPathname
})

export const setAuth = auth => ({
  type: 'SET_AUTH',
  auth
})

export const setUser = user => ({
  type: 'SET_USER',
  user
})

export const setStackVpns = stackVpns => ({
  type: 'SET_STACK_VPNS',
  stackVpns
})

export const setStack = stack => ({
  type: 'SET_STACK',
  stack
})

export const setUserStacks = userStacks => ({
  type: 'SET_USER_STACKS',
  userStacks
})

export const setCurrentStackType = currentStackType => ({
  type: 'SET_CURRENT_STACK_TYPE',
  currentStackType
})

export const setCurrentStackMachines = currentStackMachines => ({
  type: 'SET_CURRENT_STACK_MACHINES',
  currentStackMachines
})

export const setSocketId = socketId => ({
  type: 'SET_SOCKET_ID',
  socketId
})

export const setUseBackendVmsCheck = useBackendVmsCheck => ({
  type: 'SET_USE_BACKEND_VMS_CHECK',
  useBackendVmsCheck
})

export const setChangedVms = changedVms => ({
  type: 'SET_CHANGED_VMS',
  changedVms
})

export const setCtfState = ctfState => ({
  type: 'SET_CTF_STATE',
  ctfState
})

export const updateRanking = updateRankingTime => ({
  type: 'UPDATE_RANKING',
  updateRankingTime
})

// export const updateRanking2 = updateRankingTime2 => ({
//   type: 'UPDATE_RANKING2',
//   updateRankingTime2
// })
//
// export const addUpdateRanking2 = newItem => ({
//   type: 'ADD_UPDATE_RANKING2',
//   newItem
// })
//
// export const setFlagCapture = flagCapture => ({
//   type: 'SET_FLAG_CAPTURE',
//   flagCapture
// })
