import { combineReducers } from 'redux'

const hdStore = (state = { /* updateRankingTime2: [] */ }, action) => {
  switch (action.type) {
    case 'SET_LANGUAGE':
      return {
        ...state,
        language: action.language
      }
    case 'SET_STACK_ACTION_STATUS':
      return {
        ...state,
        stackActionStatus: action.stackActionStatus
      }
    case 'SET_SEARCH_PASSPHRASE':
      return {
        ...state,
        searchPassphrase: action.searchPassphrase
      }
    case 'SET_SCENARIO_ELEMENT':
      return {
        ...state,
        scenarioElement: action.scenarioElement
      }
    case 'SET_SCENARIO_MODAL_VISIBLE':
      return {
        ...state,
        scenarioModalVisible: action.scenarioModalVisible
      }
    case 'SET_MACHINE_MODAL_VISIBLE':
      return {
        ...state,
        machineModalVisible: action.machineModalVisible
      }
    case 'SET_VPN_MODAL_VISIBLE':
      return {
        ...state,
        vpnModalVisible: action.vpnModalVisible
      }
    case 'SET_MAP_LEGEND_MODAL_VISIBLE':
      return {
        ...state,
        mapLegendModalVisible: action.mapLegendModalVisible
      }
    case 'SET_MAP_OBJECT_MODAL_VISIBLE':
      return {
        ...state,
        mapObjectModalVisible: action.mapObjectModalVisible
      }
    case 'SET_MAP_NODE_MODAL_VISIBLE':
      return {
        ...state,
        mapNodeModalVisible: action.mapNodeModalVisible
      }
    case 'SET_MAP_CHALLENGE_NODE_MODAL_VISIBLE':
      return {
        ...state,
        mapChallengeNodeModalVisible: action.mapChallengeNodeModalVisible
      }
    case 'SET_DONE_MODAL_VISIBLE':
      return {
        ...state,
        doneModalVisible: action.doneModalVisible
      }
    case 'SET_CHALLENGE_MODAL_VISIBLE':
      return {
        ...state,
        challengeModalVisible: action.challengeModalVisible
      }
    case 'SET_CONSOLE_OPENED':
      return {
        ...state,
        consoleOpened: action.consoleOpened
      }
    case 'SET_UI_PATHNAME':
      return {
        ...state,
        uiPathname: action.uiPathname
      }
    case 'SET_QUIZ_ID':
      return {
        ...state,
        quizId: action.quizId
      }
    case 'SET_AUTH':
      return {
        ...state,
        auth: action.auth
      }
    case 'SET_USER':
      return {
        ...state,
        user: action.user
      }
    case 'SET_STACK':
      return {
        ...state,
        stack: action.stack
      }
    case 'SET_USER_STACKS':
      return {
        ...state,
        user_stacks: action.userStacks
      }
    case 'SET_CURRENT_STACK_TYPE':
      return {
        ...state,
        current_stack_type: action.currentStackType
      }
    case 'SET_CURRENT_STACK_MACHINES':
      return {
        ...state,
        current_stack_machines: action.currentStackMachines
      }
    case 'SET_STACK_VPNS':
      return {
        ...state,
        stackVpns: action.stackVpns
      }
    case 'SET_SOCKET_ID':
      return {
        ...state,
        socketId: action.socketId
      }
    case 'SET_USE_BACKEND_VMS_CHECK':
      return {
        ...state,
        useBackendVmsCheck: action.useBackendVmsCheck
      }
    case 'SET_CHANGED_VMS':
      return {
        ...state,
        changedVms: action.changedVms
      }
    case 'SET_CTF_STATE':
      return {
        ...state,
        ctfState: action.ctfState
      }
    case 'UPDATE_RANKING':
      return {
        ...state,
        updateRankingTime: action.updateRankingTime
      }
    // case 'UPDATE_RANKING2':
    //   return {
    //     ...state,
    //     updateRankingTime2: action.updateRankingTime2
    //   }
    // case 'ADD_UPDATE_RANKING2':
    // {
    //   console.log('QWQWQW', action)
    //   return {
    //     ...state,
    //     updateRankingTime2: [...state.updateRankingTime2, action.newItem]
    //   }
    //   }
    // case 'SET_FLAG_CAPTURE':
    //   return {
    //     ...state,
    //     flagCapture: action.flagCapture
    //   }
    default:
      return state
  }
}

const reducers = combineReducers({
  hdStore
})

export default reducers
