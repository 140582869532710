import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Card } from 'antd'
import { setQuizId } from '../../../store/actions'

import StyledCardBody from '../../../styledComponents/sections/quiz/cardBody'
import { messageTypes } from '../../../actions/messages'

class QuizCard extends Component {
  componentDidMount () {
    this._isMounted = true
  }

  modalPopup = (quizId) => {
    this.props.setQuizId(quizId)
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { language, element } = this.props

    const name = (element.display_name && element.display_name[language]) || element.scenario.name[language] || messageTypes[language].scenario_no_name
    const score = element.score

    return (
      <div className={element.solved_at ? 'solved-border' : (element.started_at ? 'progress-border' : 'standard-border')}>
        <Card title={
          <div>
            <span style={{ float: 'left' }} className='header-title'>{name}</span>
            <span style={{ float: 'right' }}>{score}</span>
          </div>
        }
        >
          <StyledCardBody
            handleClick={this.modalPopup}
            language={language}
            element={element}
          />
        </Card>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language,
  quizId: state.hdStore.quizId
})

const mapDispatchToProps = {
  setQuizId
}

const QuizCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuizCard)

export default QuizCardContainer
