import React from 'react'
import Highlighter from 'react-highlight-words'
import { Table, Input, Button, Divider } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { messageTypes } from '../../../../actions/messages'

class StyledContentPacksTableSettings extends React.Component {
  state = {
    columns: []
  }

  settings = {
    bordered: false,
    loading: false,
    pagination: { position: 'bottom' },
    size: 'default',
    showHeader: true
  }

  getColumnSearchProps = (dataIndex, language) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => { this.searchInput = node }}
          placeholder={messageTypes[language]['table_search_' + dataIndex]}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          {messageTypes[language].search}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          {messageTypes[language].clear}
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined className={filtered ? 'active' : ''} />,
    onFilter: (value, record) => record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    )
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  setColumns (language) {
    this.setState({
      columns: [
        {
          title: messageTypes[language].stack_type,
          dataIndex: 'stack_type',
          key: 'stack_type',
          ...this.getColumnSearchProps('stack_type', language),
          sorter: (a, b) => { return a.stack_type.localeCompare(b.stack_type) }
        },
        {
          title: messageTypes[language].actions,
          key: 'action',
          render: (text, record) => (
            <span>
              {record.available_versions?.length > 0 &&
                <>
                  <a href='#show-details' className='show-btn' onClick={() => { this.props.showInstallVersionModal(record.stack_type, record.stack_type) }}>{messageTypes[language].version_install}</a>
                </>}
              {(record.installed_versions === undefined || record.installed_versions.length === 0) &&
                (record.available_versions === undefined || record.available_versions.length === 0) &&
                  <>
                    {record.available_versions?.length > 0 && <Divider type='vertical' />}
                    <a href='#show-details' className='show-btn' onClick={() => { this.props.showAddRecordModal(true, record.stack_type) }}>{messageTypes[language].add_version}</a>
                  </>}
            </span>
          )
        }
      ]
    })
  }

  componentDidMount () {
    const { language } = this.props
    this._isMounted = true
    this.setColumns(language)
  }

  componentDidUpdate (prevProps, prevState) {
    const { language } = this.props
    if (this._isMounted && language !== prevProps.language) {
      this.setColumns(language)
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { columns } = this.state
    const { data, language } = this.props

    const columnsRow = [
      {
        title: messageTypes[language].name_name,
        dataIndex: 'name',
        key: 'name',
        ...this.getColumnSearchProps('name', language),
        sorter: (a, b) => { return a.name.localeCompare(b.name) },
        render: (text, record) => (
          record.status === 'draft' ? text + '*' : text
        )
      },
      {
        title: messageTypes[language].actions,
        key: 'action',
        render: (text, record) => (
          <span>
            {record.status === 'draft' &&
              <>
                <a href='#show-details' className='show-btn' onClick={() => { this.props.markAsFinal(record._id) }}>{messageTypes[language].mark_as_final}</a>
              </>}
            {record.status === 'final' &&
              <>
                <a href='#show-details' className='show-btn' onClick={() => { this.props.setDataId(record._id, record.stack_type) }}>{messageTypes[language].clone_and_edit}</a>
                <Divider type='vertical' />
                <a href='#delete-scenario' className='delete-btn' onClick={() => { this.props.uninstallVersion(record.name, record.stack_type) }}>{messageTypes[language].uninstall}</a>
              </>}
          </span>
        )
      }
    ]

    return (
      <Table
        className={`settings-table ${data && data.length ? 'fade-in' : ''}`}
        {...this.settings}
        columns={columns}
        dataSource={data}
        expandable={{
          expandedRowRender: record =>
            <Table
              className={`settings-table ${data && data.length ? 'fade-in' : ''}`}
              {...this.settings}
              columns={columnsRow}
              dataSource={record.installed_versions}
              locale={{
                triggerDesc: messageTypes[language].table_sort_desc,
                triggerAsc: messageTypes[language].table_sort_asc,
                cancelSort: messageTypes[language].table_sort_cancel
              }}
            />,
          rowExpandable: record => record.installed_versions && record.installed_versions.length
        }}
        locale={{
          triggerDesc: messageTypes[language].table_sort_desc,
          triggerAsc: messageTypes[language].table_sort_asc,
          cancelSort: messageTypes[language].table_sort_cancel
        }}
      />
    )
  }
}

export default StyledContentPacksTableSettings
