import React from 'react'
import { messageTypes } from '../../../../actions/messages'
import {
  Form, InputNumber, Button, Divider
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

class StyledStackTypeConfForm extends React.Component {
  formRef = React.createRef()

  handleSubmit = (values) => {
    const { updateRecord, stackType } = this.props
    const obj = {}
    obj.powered_vms_limit = values.powered_vms_limit

    updateRecord(stackType, obj)
  }

  setDefaultValues () {
    this.formRef.current.setFieldsValue({
      powered_vms_limit: this.props.data.powered_vms_limit
    })
  }

  componentDidMount () {
    this.setDefaultValues()
  }

  componentDidUpdate (prevProps, prevState) {
    const { data } = this.props

    if (data !== prevProps.data) {
      this.setDefaultValues()
    }
  }

  render () {
    const { language, loading } = this.props

    return (
      <Form onFinish={this.handleSubmit} layout='horizontal' ref={this.formRef}>
        <Form.Item
          name='powered_vms_limit'
          label={messageTypes[language].name_name}
          rules={[
            { required: true, message: messageTypes[language].field_required }
          ]}
          {...formItemLayout}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item className='form-footer'>
          <Divider />
          <Button htmlType='submit'>{loading ? <LoadingOutlined /> : ''} {messageTypes[language].submit}</Button>
          <Button onClick={() => { this.props.onCancel() }} className='cancel'>{messageTypes[language].cancel}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StyledStackTypeConfForm
