import React from 'react'
import HDComponent from '../../../components/common/hdComponent'
import { Row, Col } from 'antd'
import ResetPasswordRequestForm from './requestResetForm'
import config from '../../../config'

class StyledResetPasswordRequestForm extends HDComponent {
  render () {
    const { language } = this.props

    return (
      <Row>
        <Col lg={{ span: 8 }} md={{ span: 0 }} />
        <Col lg={{ span: 8 }} md={{ span: 24 }}>
          <div className='reset-password-logo'>
            <img src={'/images/' + ((config.app_logo && 'customization/' + config.app_logo) || 'logo_light_small.png')} alt='Logo' />
          </div>
          <ResetPasswordRequestForm
            language={this.determineLanguage(language)}
          />
        </Col>
        <Col lg={{ span: 8 }} md={{ span: 0 }} />
      </Row>
    )
  }
}

export default StyledResetPasswordRequestForm
