import React, { Component } from 'react'
import { Row, Col, Divider } from 'antd'
import StyledProgressBar from './progressBar'
import { messageTypes } from '../../actions/messages.js'

class StyledResourcesVisualiser extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {
    this.calculateResources()
  }

  componentDidUpdate (prevProps) {
    if (this.props !== prevProps) {
      this.calculateResources()
    }
  }

  calculateResources = () => {
    const { cpu, mem, storage } = this.props.resources
    const cpuUsed = cpu.used
    const cpuMax = cpu.max
    const memUsed = mem.used
    const memMax = mem.max
    const storageUsed = storage.used
    const storageMax = storage.max

    this.setState({
      cpuUsed,
      cpuMax,
      memUsed,
      memMax,
      storageUsed,
      storageMax
    })
  }

  render () {
    const { type, language } = this.props
    const {
      cpuUsed,
      cpuMax,
      memUsed,
      memMax,
      storageUsed,
      storageMax
    } = this.state

    return (
      <div className='resources-visualiser'>
        {type === 'circle' ? <Divider>{messageTypes[language].scenario_usage}</Divider> : ''}
        <Row gutter={8}>
          <Col span={24} sm={8}>
            <StyledProgressBar name='CPU' usage={cpuUsed || 0} max={cpuMax || 0} type={type} unit='Hz' />
          </Col>
          <Col span={24} sm={8}>
            <StyledProgressBar name='MEM' usage={memUsed || 0} max={memMax || 0} type={type} unit='B' />
          </Col>
          <Col span={24} sm={8}>
            <StyledProgressBar name='STORAGE' usage={storageUsed || 0} max={storageMax || 0} type={type} unit='B' />
          </Col>
        </Row>
      </div>
    )
  }
}

export default StyledResourcesVisualiser
