import React, { Component } from 'react'
import ScenariosCard from '../../../components/sections/scenarios/scenariosCard'
import { Row, Col } from 'antd'

class StyledScenarios extends Component {
  render () {
    const { scenarios, downloadScenarioFile } = this.props
    return (
      <div className={`scenarios ${scenarios.length ? 'fade-in' : ''}`}>
        <Row gutter={8}>
          {
            scenarios.length
              ? scenarios.map((element, n) =>
                <Col
                  key={n}
                  style={{ marginBottom: 8 }}
                  xxl={8} xl={12} lg={12} md={24} sm={24} xs={24}
                >
                  <div className={'scenario-type-' + element.s_type}>
                    <ScenariosCard
                      element={element}
                      downloadScenarioFile={downloadScenarioFile}
                    />
                  </div>
                </Col>)
              : ''
          }
        </Row>
      </div>
    )
  }
}

export default StyledScenarios
