import React from 'react'
import { setCtfState } from '../../store/actions'
import { connect } from 'react-redux'
import StyledCtfHeaderClock from '../../styledComponents/common/ctfHeaderClock'
import HDComponent from '../../components/common/hdComponent'

class CtfHeaderClock extends HDComponent {
  state = {
    showBeforeStartClock: false,
    ctfStartDate: undefined,
    ctfEnded: undefined,
    ctfStarted: false,
    showEndClock: false
  }

  componentDidMount (prevProps, prevState) {
    const { stack, ctfState } = this.props

    if (ctfState === undefined) {
      this.initialPrepare()
    } else if (ctfState === 'BEFORE') {
      this.setState({ showBeforeStartClock: true, ctfStartDate: new Date(stack.ctf_start_date) })
    } else if (ctfState === 'FINISHED') {
      this.setState({ ctfEnded: true })
    }
  }

  initialPrepare = () => {
    const { stack, setCtfState } = this.props

    const currentDate = new Date()
    const start = new Date(stack.ctf_start_date)
    const end = new Date(stack.ctf_end_date)

    if (currentDate < start) {
      setCtfState('BEFORE')
    } else if (currentDate > start && currentDate < end) {
      setCtfState('BATTLE')
    } else {
      setCtfState('FINISHED')
    }
  }

  componentWillUnmount () {
    this.props.setCtfState(undefined)
  }

  componentDidUpdate (prevProps, prevState) {
    const { stack, ctfState } = this.props

    if (stack?.is_ctf && stack.ctf_time_limit && stack.ctf_start_date && stack.ctf_end_date) {
      if (ctfState === undefined) {
        this.initialPrepare()
      } else if (this.props.ctfState !== prevProps.ctfState && this.props.ctfState === 'FINISHED') {
        this.setState({ showEndClock: false, ctfEnded: true, ctfStarted: false })
      } else if (this.props.ctfState !== prevProps.ctfState && this.props.ctfState === 'BEFORE') {
        this.setState({ showBeforeStartClock: true, ctfStartDate: new Date(stack.ctf_start_date) })
      }
    }
  }

  beforeStartClockFinished = (x) => {
    this.setState({ showBeforeStartClock: false, ctfStarted: true })
    this.props.setCtfState('BATTLE')

    setTimeout(() => {
      this.setState({ ctfStarted: false })
    }, 60000)
  }

  render () {
    return (
      <StyledCtfHeaderClock
        showBeforeStartClock={this.state.showBeforeStartClock}
        ctfStartDate={this.state.ctfStartDate}
        ctfStarted={this.state.ctfStarted}
        ctfEnded={this.state.ctfEnded}
        handleBeforeStartClockFinished={this.beforeStartClockFinished}
        language={this.props.language}
        className={this.props.className}
      />
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language,
  stack: state.hdStore.stack,
  ctfState: state.hdStore.ctfState
})

const mapDispatchToProps = {
  setCtfState
}

const CtfHeaderClockContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CtfHeaderClock)

export default CtfHeaderClockContainer
