import React, { Component } from 'react'
import { connect } from 'react-redux'
import StyledSettings from '../../../styledComponents/sections/settings'

class Settings extends Component {
  render () {
    return (
      <StyledSettings language={this.props.language} />
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language
})

const SettingsContainer = connect(
  mapStateToProps
)(Settings)

export default SettingsContainer
