import React from 'react'
import { Modal } from 'antd'
import { messageTypes } from '../../../../actions/messages'
import {
  takeElementWithIdFromArr,
  openNotificationPopup,
  getCookie
} from '../../../../actions/helpers'
import StyledQuizForm from '../../../../styledComponents/sections/settings/quiz/quizForm'
import {
  adminGetContentPacks
} from '../../../../actions/'

class QuizModalSettings extends React.Component {
  state = {
    singleData: {},
    contentPacks: []
  }

  handleCancel = () => {
    const { setDataId, showAddRecordModal } = this.props
    setDataId(null)
    showAddRecordModal(false)
    this.setState({
      contentPacks: []
    })
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  componentDidUpdate (prevProps, prevState) {
    const { dataId, data, isNewRecord } = this.props

    if (prevProps.isNewRecord !== isNewRecord && isNewRecord) {
      this.getContentPacks()
    }

    if (prevProps.dataId !== dataId) {
      const singleData = takeElementWithIdFromArr(dataId, data)
      this.setState({ singleData })
      if (singleData) {
        this.getContentPacks()
      }
    }
  }

  getContentPacks = () => {
    const { language } = this.props

    adminGetContentPacks(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            contentPacks: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  render () {
    const {
      language, dataId, isNewRecord, loading,
      updateRecord, addRecord, scenarios
    } = this.props
    const { singleData } = this.state
    return (
      <div>
        <Modal
          visible={!!(dataId || isNewRecord)}
          title={
            (isNewRecord && messageTypes[language].new_quiz) ||
            (
              singleData &&
              singleData.scenario &&
              singleData.scenario.name &&
              singleData.scenario.name[language])
          }
          onCancel={this.handleCancel}
          footer={null}
          width={800}
        >
          <StyledQuizForm
            scenarios={scenarios}
            isNewRecord={isNewRecord}
            data={singleData || {}}
            language={language}
            onCancel={this.handleCancel}
            updateRecord={updateRecord}
            addRecord={addRecord}
            loading={loading}
            contentPacks={this.state.contentPacks}
          />
        </Modal>
      </div>
    )
  }
}

export default QuizModalSettings
