import React, { Component } from 'react'
import { Modal, Button, Menu, Dropdown } from 'antd'
import { messageTypes } from '../../../actions/messages'
import StyledDownloadButtons from './downloadButtons'
// import StyledResourcesVisualiser from '../../common/resourcesVisualiser';
import NetVisualiser from './netVisualiser'
import StyledStarsDisplay from './starsDisplay'
import TagsDisplayer from './tagsDisplayer'
import StyledFilesDownloader from '../../../styledComponents/common/filesDownloader'
import StyledTagsDisplay from '../../../styledComponents/common/tagsDisplay'
import { LoadingOutlined } from '@ant-design/icons'
import settings from '../../../config/'
import PerfectScrollbar from 'react-perfect-scrollbar'

class StyledDescModal extends Component {
  componentDidMount () {
    this._scrollBarRef && this._scrollBarRef.updateScroll()
  }

  render () {
    const {
      element, language, modalVisible, onHandleClose, downloadScenarioFile,
      onHandleBlockScenario, onHandleUnblockScenario, blocked, onHandleExtendBlockScenario
    } = this.props
    const { difficulty, tags, files, resources, s_type: sType } = this.props.element

    const name = element.name[language] || messageTypes[language].scenario_no_name
    const descLong = element.desc_long[language] || messageTypes[language].scenario_no_desc_long
    const brDescLong = descLong.split('\n')

    const menuBlock = (
      <Menu>
        <Menu.Item key='1' onClick={() => onHandleBlockScenario(2)}>
          {messageTypes[language].book_2_day}
        </Menu.Item>
        <Menu.Item key='2' onClick={() => onHandleBlockScenario(3)}>
          {messageTypes[language].book_3_day}
        </Menu.Item>
      </Menu>
    )

    const menuExtendBlock = (
      <Menu>
        <Menu.Item key='1' onClick={() => onHandleExtendBlockScenario(1)}>
          {messageTypes[language].extend_1_day}
        </Menu.Item>
        <Menu.Item key='2' onClick={() => onHandleExtendBlockScenario(2)}>
          {messageTypes[language].extend_2_day}
        </Menu.Item>
        <Menu.Item key='3' onClick={() => onHandleExtendBlockScenario(3)}>
          {messageTypes[language].extend_3_day}
        </Menu.Item>
      </Menu>
    )

    return (
      <Modal
        zIndex={sType === 'presentation' ? 1001 : 1000}
        title={
          <div>
            <span className='header-title'>{name}</span>
            {sType === 'presentation'
              ? (name.length <= 16 ? <StyledTagsDisplay tags={[messageTypes[language]['scenario_type_' + sType]]} /> : '')
              : <StyledStarsDisplay difficulty={difficulty} />}
          </div>
        }
        visible={modalVisible}
        onCancel={onHandleClose}
        closable={false}
        footer={[
          <StyledFilesDownloader
            key={1}
            language={language}
            element={element}
            downloadFile={downloadScenarioFile}
            modal
            files={files}
            useFileId
          />,
          (settings.scenarios_blocking_enabled && element.blockable && this.props.stack.users_count > 1 &&
            [(blocked && element.author && (
              <Dropdown.Button key='unblock' type='primary' overlay={menuExtendBlock} onClick={() => onHandleUnblockScenario(1)} className='scenario-block-dropdown'>
                {this.props.blockLoading ? <LoadingOutlined /> : ''}{messageTypes[language].reservation_cancel}
              </Dropdown.Button>)) || (!blocked &&
            (
              <Dropdown.Button key='block' type='primary' overlay={menuBlock} onClick={() => onHandleBlockScenario(1)} className='scenario-block-dropdown'>
                {this.props.blockLoading ? <LoadingOutlined /> : ''}{messageTypes[language].book_1_day}
              </Dropdown.Button>))]) || [],
          <Button
            key='back'
            onClick={onHandleClose}
          >{messageTypes[language].return}
          </Button>
        ]}
      >
        <PerfectScrollbar style={{ textAlign: 'left' }} ref={(ref) => { this._scrollBarRef = ref }} className='scroll-scenarios-modal'>
          {blocked && <p className='item-blocked'>{messageTypes[language].reserved_to} {element.blocked_to} {messageTypes[language].reserved_by} {element.blocked_by?.name}</p>}
          {
            brDescLong.map((val, i) => (
              <div key={i}>{val} <br /></div>
            ))
          }
        </PerfectScrollbar>

        <TagsDisplayer tags={tags} modal language={language} />
        {files ? <StyledDownloadButtons files={files} /> : ''}

        {/* resources ? <StyledResourcesVisualiser
          language={language}
          resources={resources}
          type="circle"/> : '' */}
        {resources && resources.net && resources.net.length
          ? <NetVisualiser
              stack={this.props.stack}
              language={language}
              net={resources.net}
              machineModalVisible={this.props.machineModalVisible}
              setMachineModalVisible={this.props.setMachineModalVisible}
              refreshData={this.props.refreshData}
            />
          : ''}
      </Modal>
    )
  }
}

export default StyledDescModal
