import React, { Component } from 'react'
import { Tag } from 'antd'
class StyledTagsDisplay extends Component {
  render () {
    const { tags } = this.props
    return (
      <div className='tags-displayer'>
        {
          tags && Array.isArray(tags)
            ? tags.map((val, i) => (
              <Tag key={i} color='green'>{val}</Tag>
            ))
            : ''
        }
      </div>
    )
  }
}

export default StyledTagsDisplay
