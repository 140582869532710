import React, { Component } from 'react'
import { Modal, Row, Col, Divider } from 'antd'
import { messageTypes } from '../../../../actions/messages'

class LogsModal extends Component {
  handleCancel = () => {
    const { toggleLogModal } = this.props
    toggleLogModal(false, null)
  }

  render () {
    const { language, singleLog, modalVisible } = this.props
    const brDesc = singleLog.description.split('\n')

    return (
      <div>
        <Modal
          maskClosable={false}
          width={1000}
          visible={modalVisible}
          title={messageTypes[language].log_details}
          onCancel={this.handleCancel}
          footer={null}
        >
          <>
            <Row gutter={16}>
              <Col className='gutter-row' span={6}>
                <div>{messageTypes[language].user}</div>
              </Col>
              <Col className='gutter-row' span={18}>
                <div>{singleLog.user || (singleLog.username && <i>{singleLog.username} ({messageTypes[language].deleted})</i>)}</div>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className='gutter-row' span={6}>
                <div>{messageTypes[language].category}</div>
              </Col>
              <Col className='gutter-row' span={18}>
                <div className={'log-' + singleLog.category}>{singleLog.category}</div>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className='gutter-row' span={6}>
                <div>{messageTypes[language].component}</div>
              </Col>
              <Col className='gutter-row' span={18}>
                <div>{singleLog.component}</div>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className='gutter-row' span={6}>
                <div>{messageTypes[language].event_date}</div>
              </Col>
              <Col className='gutter-row' span={18}>
                <div>{singleLog.created_at}</div>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className='gutter-row' span={6}>
                <div>{messageTypes[language].ip_addr}</div>
              </Col>
              <Col className='gutter-row' span={18}>
                <div>{singleLog.ip_addr}</div>
              </Col>
            </Row>
            <Divider>
              {messageTypes[language].description}
            </Divider>
            <Row gutter={16}>
              <Col className='gutter-row' span={24}>
                <div>
                  {
                    brDesc.map((val, i) => (
                      <div key={i}>{val} <br /></div>
                    ))
                  }
                </div>
              </Col>
            </Row>
          </>
        </Modal>
      </div>
    )
  }
}

export default LogsModal
