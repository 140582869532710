import React, { Component } from 'react'
import StyledFooter from '../../styledComponents/common/footer'

class Footer extends Component {
  render () {
    return (
      <StyledFooter />
    )
  }
}

export default Footer
