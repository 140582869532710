import React, { Component } from 'react'
import FontAwesomeIcon from 'react-fontawesome'

class StyledStarsDisplay extends Component {
  renderStars (difficulty) {
    const stars = []
    for (let i = 0; i < difficulty; i++) {
      stars.push(<FontAwesomeIcon className={`bolts difficulty-${difficulty}`} key={i} name='star' />)
    }
    return stars
  }

  render () {
    const { difficulty } = this.props
    return (
      <div className='difficulty-display'>
        {this.renderStars(difficulty)}
      </div>
    )
  }
}

export default StyledStarsDisplay
