import React, { Component } from 'react'
import { connect } from 'react-redux'
import StyledContentPacksTable from '../../../../styledComponents/sections/settings/contentPack/table'
import {
  openNotificationPopup,
  getCookie,
  confirm
} from '../../../../actions/helpers'
import {
  adminGetContentPacks,
  adminCreateContentPackVersion,
  adminInstallContentPackVersion,
  adminUninstallContentPackVersion,
  adminCloneContentPackVersion,
  adminMarkAsFinalContentPackVersion
} from '../../../../actions/'
import { messageTypes } from '../../../../actions/messages'
import ContentPackModal from './modal'
import InstallVersionModal from './installVersionModal'

class ContentPack extends Component {
  state = {
    data: [],
    stackType: undefined
  }

  componentDidMount () {
    this._isMounted = true
    this.getAllData()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  markAsFinal = (id) => {
    const { language } = this.props
    this.setState({ loading: true })
    adminMarkAsFinalContentPackVersion(id, getCookie('_token')).then((json) => {
      this.setState({ loading: false })
      if (json.status === 'ok') {
        this.getAllData()
        this.setState({
          dataId: null
        })
        openNotificationPopup(
          messageTypes[language].success,
          json.response[language],
          'smile'
        )
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
      }
    })
  }

  cloneVersion = (data) => {
    const { language } = this.props
    this.setState({ loading: true })
    adminCloneContentPackVersion(data, getCookie('_token')).then((json) => {
      this.setState({ loading: false })
      if (json.status === 'ok') {
        this.getAllData()
        this.setState({
          dataId: null
        })
        openNotificationPopup(
          messageTypes[language].success,
          json.response[language],
          'smile'
        )
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
      }
    })
  }

  addRecord = (data) => {
    const { language } = this.props
    this.setState({ loading: true })
    adminCreateContentPackVersion(data, getCookie('_token')).then((json) => {
      this.setState({ loading: false })
      if (json.status === 'ok') {
        this.getAllData()
        this.showAddRecordModal(null)
        openNotificationPopup(
          messageTypes[language].success,
          json.response[language],
          'smile'
        )
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
      }
    })
  }

  installVersion = (data) => {
    const { language } = this.props
    this.setState({ loading: true })
    adminInstallContentPackVersion({ ...data, stack_type: this.state.stackType }, getCookie('_token')).then((json) => {
      this.setState({ loading: false })
      if (json.status === 'ok') {
        this.getAllData()
        this.showInstallVersionModal(null)
        openNotificationPopup(
          messageTypes[language].success,
          json.response[language],
          'smile'
        )
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
      }
    })
  }

  uninstallVersion = (name, stackType) => {
    const { language } = this.props

    confirm('uninstall_content_pack_version', language, () => {
      adminUninstallContentPackVersion({ name, stack_type: stackType }, getCookie('_token')).then((json) => {
        this.setState({ loading: false })
        if (json.status === 'ok') {
          this.getAllData()
          openNotificationPopup(
            messageTypes[language].success,
            json.response[language],
            'smile'
          )
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      })
    })
  }

  getAllData = () => {
    const { language } = this.props

    adminGetContentPacks(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            data: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  setDataId = (dataId, stackType) => { this.setState({ dataId, stackType }) }
  showAddRecordModal = (isNewRecord, stackType) => { this.setState({ isNewRecord, stackType }) }
  showInstallVersionModal = (installVersionModal, stackType) => { this.setState({ installVersionModal, stackType }) }

  returnWithKey (data, lang) {
    const retArr = []
    data.forEach((val, i) => {
      const obj = {}
      obj.key = i
      obj.stack_type = val.stack_type
      obj.installed_versions = val.installed_versions
      obj.available_versions = val.available_versions

      retArr.push(obj)
    })
    return retArr
  }

  render () {
    const { data, dataId, stackType, isNewRecord, installVersionModal } = this.state
    const { language } = this.props

    return (
      <>
        <ContentPackModal
          language={language}
          data={data}
          dataId={dataId}
          stackType={stackType}
          setDataId={this.setDataId}
          isNewRecord={isNewRecord}
          loading={this.state.loading}
          addRecord={this.addRecord}
          cloneVersion={this.cloneVersion}
          showAddRecordModal={this.showAddRecordModal}
        />
        <InstallVersionModal
          language={language}
          data={data}
          dataId={installVersionModal}
          stackType={stackType}
          setDataId={this.setDataId}
          loading={this.state.loading}
          addRecord={this.installVersion}
          showInstallVersionModal={this.showInstallVersionModal}
        />
        <StyledContentPacksTable
          language={language}
          data={this.returnWithKey(data, language)}
          setDataId={this.setDataId}
          showAddRecordModal={this.showAddRecordModal}
          showInstallVersionModal={this.showInstallVersionModal}
          uninstallVersion={this.uninstallVersion}
          markAsFinal={this.markAsFinal}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language
})

const ContentPackContainer = connect(
  mapStateToProps
)(ContentPack)

export default ContentPackContainer
