import React, { Component } from 'react'
import { Menu, Dropdown/*, Icon */ } from 'antd'
import FontAwesomeIcon from 'react-fontawesome'
import { messageTypes } from '../../actions/messages'
import { Link } from 'react-router-dom'

class StyledFilesDownloader extends Component {
  constructor (props) {
    super(props)
    this.icons = {
      pdf: 'file-pdf-o',
      bin: 'file-code-o',
      zip: 'file-zip-o',
      youtube: 'youtube-play'
    }
  }

  render () {
    const { language, downloadFile, element, files, modal, customLabel } = this.props

    const menu = (
      <Menu>
        {
          files && files.length && files.map((val, i) =>
            <Menu.Item key={i} onClick={() => val.path && val.path[language] && val.path[language] === 'true' && val.type === 'file' ? downloadFile(element, (!this.props.useFileId && val.path && val.path[language]) || val._id, language, val) : null}>
              {val.type === 'youtube' || val.type === 'url'
                ? (
                  <a
                    target={val.url && val.url[language] ? '_blank' : ''}
                    rel='noopener noreferrer'
                    className='files-downloader-yt'
                    href={val.url && val.url[language] ? (val.url[language]) : '#'}
                  >
                    <FontAwesomeIcon name={(val.type === 'youtube' && 'youtube-play') || 'external-link'} />&nbsp;&nbsp;{val.url && val.url[language] ? val.label && val.label[language] : messageTypes[language].no_file}
                  </a>
                  )
                : (
                  <span>
                    {!val.path || val.path[language] === 'true' || val.path[language] === 'false'
                      ? (
                        <><FontAwesomeIcon name={this.icons[val.file_type] || 'file-o'} />&nbsp;&nbsp;{val.path && val.path[language] === 'true' ? val.label && val.label[language] : messageTypes[language].no_file}</>
                        )
                      : (
                        <Link to={{ pathname: '/storage/' + val._id + '-' + (val.path && val.path[language]) }} target='_blank'>
                          <FontAwesomeIcon name={this.icons[val.file_type] || 'file-o'} />&nbsp;&nbsp;{val.path && val.path[language] ? val.label && val.label[language] : messageTypes[language].no_file}
                        </Link>
                        )}
                  </span>
                  )}
              {/* <a class="scenario-file-viewer" href={val.path && val.path[language] ? '/scenarios/file-viewer/' + scenarioId + '/' + (val.path && val.path[language]) : null } target="_blank" rel="noopener noreferrer">
            <Icon type="export" />
            </a> */}
            </Menu.Item>
          )
        }
      </Menu>
    )

    return (
      (element.demo && !modal && <p className='premium-version-only'>{messageTypes[language].premium_version_only}</p>) ||
      ((files && files.length &&
        (
          <Dropdown overlay={menu} className='files-downloader'>
            <a className='ant-dropdown-link' href='#download' onClick={(e) => { e.preventDefault(); return false }}>
              <FontAwesomeIcon name='download' />&nbsp;&nbsp;{customLabel || messageTypes[language].materials}
            </a>
          </Dropdown>
        )) || ''
      )
    )
  }
}

export default StyledFilesDownloader
