import React, { Component } from 'react'
import config from '../../config/'
import { ReactSVG } from 'react-svg'

class LoaderImage extends Component {
  render () {
    return (
      <div className='loader-svg fade-in-2'>
        <ReactSVG src={'/images/' + ((config.app_loader && 'customization/' + config.app_loader) || 'loader.svg')} />
      </div>
    )
  }
}

export default LoaderImage
