import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setChallengeModalVisible } from '../../../store/actions'
import StyledChallengeModal from '../../../styledComponents/sections/challenges/modal'
import { mapSubmitChallengeFlag, getChallengeSolves, challengeFileDownload, getChallengeResources } from '../../../actions'
import { openNotificationPopup, getCookie } from '../../../actions/helpers'
import { messageTypes } from '../../../actions/messages'

const FileDownload = require('js-file-download')

class ChallengeModal extends Component {
  state = {
    solves: [],
    resources: []
  }

  handleClose = (e) => {
    if (this.props.toggleModal) {
      this.props.toggleModal(false, null)
    } else {
      this.props.setChallengeModalVisible(false)
    }
  }

  getChallengeSolves (element) {
    getChallengeSolves(element._id, getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        this.setState({ solves: json.response })
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[this.props.language].oops, json.response[this.props.language], 'frown')
      }
    })
  }

  getChallengeResources (element) {
    getChallengeResources(element.challenge_hd_id, getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        this.setState({ resources: json.response })
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[this.props.language].oops, json.response[this.props.language], 'frown')
      }
    })
  }

  componentDidUpdate (prevProps, prevState) {
    const { element } = this.props

    if (prevProps.element?._id !== element?._id && element) {
      this.getChallengeSolves(element)
      this.getChallengeResources(element)
    }
  }

  submitFlag = (flag) => {
    mapSubmitChallengeFlag(flag, getCookie('_token'), this.props.element._id).then((json) => {
      if (json.status === 'ok') {
        this.getChallengeSolves(this.props.element)
        this.props.refreshData()

        openNotificationPopup(messageTypes[this.props.language].success, json.response[this.props.language], 'smile')
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[this.props.language].oops, json.response[this.props.language], 'frown')
      }
    })
  }

  downloadChallengeFile (element, filename, language, file) {
    challengeFileDownload(element.challenge_hd_id, filename, language, getCookie('_token')).then((response) => {
      // download file if get was successfull
      if (response.status === 200) {
        FileDownload(response.data, file.label[language] + '.' + file.file_type)
      } else {
        openNotificationPopup(messageTypes[language].oops, messageTypes.universal.error, 'frown')
      }
    })
  }

  render () {
    const { element, language, challengeModalVisible } = this.props

    return (
      (element)
        ? (
          <StyledChallengeModal
            element={element}
            language={language}
            modalVisible={challengeModalVisible}
            onHandleClose={this.handleClose}
            submitFlag={this.submitFlag}
            solves={this.state.solves}
            resources={this.state.resources}
            downloadChallengeFile={this.downloadChallengeFile}
          />)
        : ''
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language,
  challengeModalVisible: state.hdStore.challengeModalVisible
})

const mapDispatchToProps = {
  setChallengeModalVisible
}

const ChallengeModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeModal)

export default ChallengeModalContainer
