import React, { Component } from 'react'
import QuizCard from '../../../components/sections/quiz/quizCard'
import { Row, Col } from 'antd'

class StyledQuiz extends Component {
  render () {
    const { quiz } = this.props
    return (
      <div className={`quiz ${quiz.length ? 'fade-in' : ''}`}>
        <Row gutter={8}>
          {
            quiz.length
              ? quiz.map((element, n) =>
                <Col
                  key={n}
                  style={{ marginBottom: 8 }}
                  xxl={6} xl={8} lg={12} md={24} sm={24} xs={24}
                >
                  <QuizCard element={element} />
                </Col>)
              : ''
          }
        </Row>
      </div>
    )
  }
}

export default StyledQuiz
