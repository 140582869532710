import React from 'react'
import Highlighter from 'react-highlight-words'
import { Table, Input, Button, Divider } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { messageTypes } from '../../../../actions/messages'

class StyledLogsTableSettings extends React.Component {
  state = {
    columns: []
  }

  settings = {
    bordered: false,
    loading: false,
    pagination: { position: 'bottom' },
    size: 'default',
    showHeader: true
  }

  // ###########################################
  getColumnSearchProps = (dataIndex, language) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => { this.searchInput = node }}
          placeholder={messageTypes[language]['table_search_' + dataIndex]}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          {messageTypes[language].search}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          {messageTypes[language].clear}
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined className={filtered ? 'active' : ''} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    )
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }
  // ###########################################

  setColumns (language) {
    this.setState({
      columns: [{
        title: messageTypes[language].user,
        dataIndex: 'user',
        key: 'user',
        sorter: (a, b) => { return a.user.localeCompare(b.user) },
        ...this.getColumnSearchProps('user', language),
        render: (text, record) => (
          record.user || (record.username && <i>{record.username}</i>)
        )
      }, {
        title: messageTypes[language].category,
        dataIndex: 'category',
        key: 'category',
        filters: [
          { text: 'User', value: 'user' },
          { text: 'Scenario', value: 'scenario' },
          { text: 'Machine', value: 'machine' },
          { text: 'Quiz', value: 'quiz' },
          { text: 'Error', value: 'error' }
        ],
        sorter: (a, b) => { return a.category.localeCompare(b.category) },
        onFilter: (value, record) => record.category.indexOf(value) === 0,
        render: (text, record) => (
          <span className={'log-' + record.category}>
            {text}
          </span>
        )
      }, {
        title: messageTypes[language].component,
        dataIndex: 'component',
        key: 'component',
        sorter: (a, b) => { return a.component.localeCompare(b.component) },
        ...this.getColumnSearchProps('component', language)
      }, {
        title: messageTypes[language].event_date,
        dataIndex: 'created_at',
        key: 'created_at',
        defaultSortOrder: 'descend',
        sorter: (a, b) => { return a.created_at.localeCompare(b.created_at) },
        ...this.getColumnSearchProps('created_at', language)
      }, {
        title: messageTypes[language].ip_addr,
        dataIndex: 'ip_addr',
        key: 'ip_addr',
        sorter: (a, b) => { return a.created_at.localeCompare(b.ip_addr) },
        ...this.getColumnSearchProps('ip_addr', language)
      }, {
        title: messageTypes[language].description,
        dataIndex: 'short_description',
        key: 'short_description',
        sorter: (a, b) => { return a.description.localeCompare(b.description) },
        ...this.getColumnSearchProps('short_description', language)
      }, {
        title: messageTypes[language].actions,
        key: 'action',
        render: (text, record) => (
          <span>
            <a href='#show-log' className='show-btn' onClick={() => { this.props.toggleLogModal(true, record) }}>{messageTypes[language].show}</a>
            <Divider type='vertical' />
            <a href='#delete-log' className='delete-btn' onClick={() => { this.props.deleteRecord(record._id) }}>{messageTypes[language].delete}</a>
          </span>
        )
      }]
    })
  }
  //
  // handleTableChange = (pagination, filters, sorter) => {
  //   const pager = { ...this.state.pagination };
  //   pager.current = pagination.current;
  //   this.setState({
  //     pagination: pager,
  //   });
  //   // this.fetch({
  //   //   results: pagination.pageSize,
  //   //   page: pagination.current,
  //   //   sortField: sorter.field,
  //   //   sortOrder: sorter.order,
  //   //   ...filters,
  //   // });
  // };

  componentDidMount () {
    const { language } = this.props
    this._isMounted = true
    this.setColumns(language)
  }

  componentDidUpdate (prevProps, prevState) {
    const { language } = this.props
    if (this._isMounted && language !== prevProps.language) {
      this.setColumns(language)
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { columns } = this.state
    const { logs, language } = this.props
    const isMounted = this._isMounted

    return (
      (isMounted === true
        ? (
          <Table
            {...this.settings}
            columns={columns}
            dataSource={logs}
            onChange={this.handleTableChange}
            pagination={{ locale: { items_per_page: messageTypes[language].table_pagination_page } }}
            locale={{
              triggerDesc: messageTypes[language].table_sort_desc,
              triggerAsc: messageTypes[language].table_sort_asc,
              cancelSort: messageTypes[language].table_sort_cancel
            }}
          />
          )
        : '')
    )
  }
}

export default StyledLogsTableSettings
