import React, { Component } from 'react'
import { connect } from 'react-redux'
import StyledStackTypeConfTable from '../../../../styledComponents/sections/settings/stackTypeConf/table'
import {
  openNotificationPopup,
  getCookie
} from '../../../../actions/helpers'
import {
  adminUpdateStackTypeConf,
  adminGetStackTypesConf
} from '../../../../actions/'
import { messageTypes } from '../../../../actions/messages'
import StackTypeConfModal from './modal'

class StyledStackTypeConf extends Component {
  state = {
    data: [],
    stackType: undefined
  }

  componentDidMount () {
    this._isMounted = true
    this.getAllData()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  updateRecord = (type, data) => {
    const { language } = this.props
    this.setState({ loading: true })
    adminUpdateStackTypeConf(type, data, getCookie('_token')).then((json) => {
      this.setState({ loading: false })
      if (json.status === 'ok') {
        this.getAllData()
        this.showConfModal(null)
        openNotificationPopup(
          messageTypes[language].success,
          json.response[language],
          'smile'
        )
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
      }
    })
  }

  getAllData = () => {
    const { language } = this.props

    adminGetStackTypesConf(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            data: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  showConfModal = (confModal, stackType) => { this.setState({ confModal, stackType }) }

  returnWithKey (data, lang) {
    const retArr = []
    data.forEach((val, i) => {
      const obj = {}
      obj.key = i
      obj.stack_type = val.type

      retArr.push(obj)
    })
    return retArr
  }

  render () {
    const { data, stackType, confModal } = this.state
    const { language } = this.props

    return (
      <>
        <StackTypeConfModal
          language={language}
          data={data}
          dataId={confModal}
          stackType={stackType}
          loading={this.state.loading}
          updateRecord={this.updateRecord}
          showConfModal={this.showConfModal}
        />
        <StyledStackTypeConfTable
          language={language}
          data={this.returnWithKey(data, language)}
          showConfModal={this.showConfModal}
          uninstallVersion={this.uninstallVersion}
          markAsFinal={this.markAsFinal}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language
})

const StyledStackTypeConfContainer = connect(
  mapStateToProps
)(StyledStackTypeConf)

export default StyledStackTypeConfContainer
