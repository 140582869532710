import React, { Component } from 'react'
import { Modal, Button } from 'antd'
import { messageTypes } from '../../../actions/messages'

class StyledDoneModal extends Component {
  render () {
    const { language, visible, handleClose, doneAllFlags } = this.props

    return (
      <Modal
        zIndex={1000}
        visible={visible}
        centered
        className='done-modal'
        footer={[
          <Button key='return' type='danger' onClick={() => handleClose()}>{messageTypes[language].close}</Button>
        ]}
      >
        <p class='congrats'>
          {messageTypes[language].congrats}<br />
          {messageTypes[language].congrats2}
        </p>
        <p class='congrats2'>{doneAllFlags ? messageTypes[language].all_flags_done : messageTypes[language].solve_more}</p>
        <img
          src='/images/done.png'
          alt='cup'
        />
      </Modal>
    )
  }
}

export default StyledDoneModal
