import React, { Component } from 'react'
import { Modal } from 'antd'
import { messageTypes } from '../../../actions/messages'
import { singleUserUpdate } from '../../../actions/'
import { openNotificationPopup, getCookie } from '../../../actions/helpers'
import SingleUserForm from './userForm'

class SingleUserProfileModal extends Component {
  state = {
    loading: false
  }

  handleCancel = () => {
    this.props.handleProfileVisibility(false)
  }

  componentDidMount () {
    this._isMounted = true
  }

  updateRecord = (data) => {
    const { language, setUser } = this.props
    this.setState({ loading: true })
    singleUserUpdate(data, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        this.setState({ loading: false })
        if (json.status === 'ok') {
          const user = { name: data.name, email: data.email }
          // save new user info to store and localStorage
          setUser(user)
          window.localStorage.setItem('user', JSON.stringify(user))

          openNotificationPopup(
            messageTypes[language].success,
            json.response[language],
            'smile'
          )
          this.props.handleProfileVisibility(false)
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { language, visible, data } = this.props
    const { loading } = this.state
    return (
      <div>
        <Modal
          visible={visible}
          title={messageTypes[language].edit_profile}
          onCancel={this.handleCancel}
          footer={null}
        >
          <SingleUserForm
            visible={visible}
            data={data}
            language={language}
            onCancel={this.handleCancel}
            updateRecord={this.updateRecord}
            loading={loading}
          />
        </Modal>
      </div>
    )
  }
}

export default SingleUserProfileModal
