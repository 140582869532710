import React from 'react'
import { Modal } from 'antd'
import { messageTypes } from '../../../../actions/messages'
import StyledInstallVersionForm from '../../../../styledComponents/sections/settings/contentPack/installVersionForm'

class ChallengeModalSettings extends React.Component {
  state = {
    singleData: {}
  }

  handleCancel = () => {
    const { setDataId, showInstallVersionModal } = this.props
    setDataId(null)
    showInstallVersionModal(false)
  }

  componentDidUpdate (prevProps, prevState) {
    const { dataId, data, stackType } = this.props
    const contentPack = data?.find(x => x.stack_type === stackType)

    if (prevProps.dataId !== dataId) {
      const singleData = contentPack
      this.setState({ singleData })
    }
  }

  render () {
    const {
      language, loading, dataId, addRecord
    } = this.props
    const { singleData } = this.state

    return (
      <div>
        <Modal
          maskClosable={false}
          width={1000}
          visible={!!dataId}
          title={messageTypes[language].install_version}
          onCancel={this.handleCancel}
          footer={null}
        >
          <StyledInstallVersionForm
            data={singleData || {}}
            language={language}
            onCancel={this.handleCancel}
            installVersion={addRecord}
            loading={loading}
            stackType={this.props.stackType}
          />
        </Modal>
      </div>
    )
  }
}

export default ChallengeModalSettings
