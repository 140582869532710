import React from 'react'
import { Statistic } from 'antd'
import { messageTypes } from '../../actions/messages'

const { Countdown } = Statistic

class CtfNavbarClock extends React.Component {
  render () {
    const { className, language } = this.props

    return (
      <div className={'ctf-clocks' + (' ' + className || '')}>
        {this.props.showEndClock &&
          <>
            <span>{messageTypes[language].ctf_end_in}</span>
            <Countdown value={this.props.ctfEndDate} onFinish={this.props.handleEndClockFinished} />
          </>}
      </div>
    )
  }
}

export default CtfNavbarClock
